.b-table {
	display: table;
	width: 100%;
	@include fontGillSans;

	&--no-top {
		box-shadow: none;
	}

	&--fixed {
		table-layout: fixed;
	}

	&--knowledge-center {

		.b-table__col {
			padding-right: 12px;
		}
	}

	&__row {
		display: table-row;

		&:nth-of-type(even) {
			background-color: #f2f2f2;
		}
	}

	&__head {
		display: table-row;

		.b-table__col .head-text {
			font-size: 16px;
			font-weight: 500;
			color: #7a7979;
			line-height: 20px;
			letter-spacing: -0.5px;
			-webkit-font-smoothing: auto;

			@media (max-width: 991px) {
				font-size: 14px;
			}
		}
	}

	&__col {
		display: table-cell;
		vertical-align: top;
		padding: 20px 20px 17px 20px;

		@media (max-width: 991px) {
			padding:10px 10px 8px 10px;
		}

		&--right {
			text-align: right;
			white-space: nowrap;
		}

		.text {
			@include fontGillSans;
			letter-spacing: -0.5px;
			color: #221f1f;
			line-height: 20px;
			font-size: 18px;
			font-weight: 400;

			@media (max-width: 991px) {
				font-size: 14px;
				line-height: 16px;
			}
		}

		.text--hint {
			margin-top: 8px;
		}

		.measure {
			padding-left: 4px;
			color: #7a7979;
			font-family: 'HelveticaNeueCyr';
			white-space: nowrap;
		}
	}
}