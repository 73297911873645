.is-m-icon {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	width: 196px;
	position: relative;
	cursor: pointer;
	&.is-open, &.is-active {
		.is-m-icon__img-on-hover {
			z-index: 2;
			opacity: 1
		}
	}
	&.is-active {
		.is-m-icon__text {
			font-size: 0;
			&::after {
				content: attr(data-alt-text);
				display: block;
				font-size: 16px;
				position: absolute;
				top:0;
				left:55px;
			}
		}
	}
	
	&__img {
		position: absolute;
		top:0;
		left: 0;
		width: 40px;
		height: 40px;
		display: block;
		opacity: 1;
		transition: opacity .2s ease-in-out;
		z-index: 1;
		flex-basis:40px;
	}
	&__img-on-hover {
		position: absolute;
		top:0;
		left: 0;
		width: 40px;
		height: 40px;
		display: inline-block;
		opacity: 0;
		z-index: 0;
		transition: opacity .2s ease-in-out;
	}

	&__text {
		text-align: left;
		display: block;
		color: #221f1f;
		@include fontGillSans;
		font-size: 16px;
		line-height: 20px;
		padding-left: 55px;
		flex-basis: 0;
		flex: 1;
		transition: color .3s ease;
		position: relative;
	}
	&:hover {
		.is-m-icon__img-on-hover {
			z-index: 2;
			opacity: 1
		}
	}
}

// mods

.is-m-icon--align-top {
	align-items: flex-start;
}






