.is-page-about {

  &__description-block {
    padding-top: 70px;
    @include is-grid(2, 50);

		.b-section__title {
			margin-top: 0;
		}
		@media (max-width: 991px) {
			.is-grid__item {
				width:100%;
			}
		}
	}

  .is-qoute {
    margin-bottom: 70px;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
  }

  &__locations {
    @include is-grid(5, 40);
    padding-top: 40px;
    padding-bottom: 70px;
    .snippet3__outer {
      margin: 0;
    }
    .is-grid__item {
      margin-bottom: 20px;
    }
    .btn-wrap {
      margin-top: 30px;
    }
  }


  &__features {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
    padding-bottom: 20px;

    .is-feature {
      width: 23%;
      margin-bottom: 40px;
    }
    @media (max-width: 768px) {
      justify-content: center;
      .is-feature {
        width:calc(100% / 3);
      }
      .is-feature__text {
        padding:0 10px;
      }
    }
    @media (max-width: 577px) {
      .is-feature {
        width:calc(100% / 2);
      }
    }
    @media (max-width: 480px) {
      .is-feature {
      margin-bottom: 30px;
      }

      .is-feature__text {
        padding:0 5px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__in-figures {
    padding-top: 50px;
    padding-bottom: 40px;

    .is-feature__outer {
      margin-bottom: 30px;
    }
  }

  &__nums {
    padding-top: 20px;
  }

  &__text {
    margin: 30px 0;

    p {
      margin: 0;
    }
    p + p {
      margin-top: 20px;
    }
  }

	&__quote {
		padding-top: 50px;
		padding-bottom: 70px;
		@media (max-width: 768px) {
			padding-bottom: 50px;
		}
	}

	&__zones {
		@media (max-width: 1470px) {
			.container {
				width:100%;
				padding:0;
			}
			.is-page-about__locations .is-grid {
				justify-content: center;
				margin-left: 0;
				margin-right: 0;
			}
			.is-page-about__locations .is-grid__item {
				width:auto;
			}
		}
	}
  &__slider {
    margin: 40px 0 30px;
  }

  &__quote {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

// @media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
// only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
// only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
// only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
// only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
// only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
// only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
//   .is-page-about {
//     &__locations {
//       padding-bottom: 35px;
//       @include is-grid(2, 40);
//       .snippet3__outer {
//         width: 100%;
//       }
//       .snippet3__img-wrap {
//         width: 100%;
//       }
//     }
//     &__features {
//       .is-feature {
//         width: 50%;
//       }
//     }
//     &__in-figures {
//       padding-top: 30px;
//       padding-bottom: 20px;
//     }

//   }
// }
