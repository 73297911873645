.is-qoute {
	
	&__img {
		display: block;
		max-width: 100%;
		margin: 0 auto 20px;
	}

	&__author {
		@include fontGillSans;
		color: #221f1f;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.5px;
		text-align: center;
		margin:18px 0 5px;
	}

	&__text {
		display: block;
		margin:0 auto;
		max-width: 260px;
		@include fontGillSans;
		text-align: center;
		color:rgba(#221f1f, .6);
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.5px;
	}

	&__button {
		text-align: center;
		margin: 40px 0 70px;
	}
}