.is-tooltip {
	position: absolute;
	z-index: 10;
	transition: opacity .3s ease,visibility .3s ease,top .4s cubic-bezier(0.04, 0.01, 0.01, 0.96);

	&--bottomCenter {
		left: 50%;
		transform:translateX(-50%);

		.is-tooltip__outer::before{
		    content: "";
		    display: inline-block;
		    position: absolute;
		    bottom:100%;
		    left:50%;
		    transform:translateX(-50%);
		    width: 0;
		    height: 0;
		    border-style: solid;
		    border-width: 0 3px 5px 3px;
		    border-color: transparent transparent #ffffff transparent;
		    z-index: 2;

		}

		.is-tooltip__outer::after{
		    content: "";
		    display: inline-block;
		    position: absolute;
		    bottom:100%;
		    left:50%;
		    transform:translateX(-50%);
		    width: 0;
		    height: 0;
		    border-style: solid;
		    border-width: 0 4px 6px 4px;
		    border-color: transparent transparent #ebebeb transparent;
			z-index: 1;
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			display: block;
			left:18px;
			right:18px;
			height: 68px;
			border-radius: 3px;
			bottom:0px;
			box-shadow: 0px 10px 31px 3px rgba(0,0,0,0.15);
		}

	}
	
	&__outer {
		position: relative;
		border: 1px solid #ebebeb;
		min-width: 250px;
	}

	&__inner {
		background-color: #fff;
		text-align: center;
		padding: 20px 25px;
	}

	&__text {
		@include fontGillSans;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		color: rgba(#221f1f, .6);
	}
}