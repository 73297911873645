.is-form {
	
	&__group {
		display: block;
		margin-bottom: 25px;
	}

	&__lable {
		display: block;
		@include fontGillSans;
		color: #221f1f;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.5px;
		margin:0 0 10px 0;
	}

	&__link {
		color: #0095ba;
	}

	&__submit-btn {
		margin-top: 30px;
		margin-bottom: 0;
		text-align: left;

		&--center {
			text-align: center;
		}
	}
}