.events {
	
	&__inner {
		display: flex;
		flex-flow:row nowrap;

		@media (max-width: 767px) {
			flex-flow:column nowrap;
			align-items: center;
		}
	}

	&__l-side {
		flex-basis: 0;
	    -webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	    flex-grow: 1;
	    max-width: 100%;
        position: relative;
    	width: 100%;
    	flex:1;

    	@media (max-width: 767px) {
			margin-bottom: 25px;
    	}
	}

	&__r-side {
		flex-basis: 0;
	    -webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	    flex-grow: 1;
	    max-width: 100%;
	    position: relative;
    	width: 100%;
	}

	&__list-wrap {
		padding-top: 20px;
		margin-right: em(205);
		position: relative;

		&::after {
			content:'';
			display: block;
			position: absolute;
			z-index: 1;
			height:30px;
			width:100%;
			left:0;
			right:0;
			bottom:0;
			background-image: linear-gradient(to bottom, rgba(0, 95, 173, 0), rgb(0, 95, 173) 100%);
			opacity: 1;
			transition: all 550ms cubic-bezier(.45,1,.32,1);
		}

		@media (max-width: 1469px) {
			margin-right: 25px;
		}

		@media (max-width: 991px) {
			padding-top: 0;
			padding-left: 35px;
			margin-right: 0;
		}

		@media (max-width: 767px) {
			padding-left: 5px;
		}
	}

	&__list {
		width:auto;
		height:470px;
		overflow:auto;
		position: relative;
	}

	&__dayoff-notify {
		font-size: 18px;
		text-align: center;
		color: #ffffff;
	}
}