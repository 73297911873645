.phone {
	display: table-cell;
	vertical-align: middle;
	font-size: 13px;
	font-family: 'Gill Sans';
	font-weight: 600;
	letter-spacing: -0.5px;
	cursor:pointer;

	@-moz-document url-prefix() {
		font-weight: 500;
	}

	&:hover {
		color:#0060ad;
	}
}