.is-local-preloader {
	position: relative;
	&__preloader {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;
		background-color: #ffffff;
		background-image: url('../img/page_preloader.svg');
		background-repeat: no-repeat;
		background-size: 60px 60px;
		background-position: center top 100px;
		transition: opacity .3s ease-in-out;
		opacity: 0;
		visibility: hidden;
		z-index: -100;
	}
	&.is-active {
		.is-local-preloader__preloader {
			transition: none;
			z-index: 100;
			visibility: visible;
			opacity: 1;
		}
	}
}