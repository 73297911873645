.section-preloader {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		z-index: -100;
		opacity:0;
		visibility: hidden;
		display: block;
		top:0; left:0; right:0; bottom:0;
		background-color: rgba(#ffffff, .8);
		background-image: url('../img/page_preloader.svg');
		background-repeat: no-repeat;
		background-size: 60px 60px;
		background-position: center top 200px;
		transition: opacity .4s, visibility .4s ease-in-out;
		will-change: opacity;
	}

	&.state-process {
		min-height: 100vh;
		&::before {
			z-index: 100;
			opacity: 1;
			visibility: visible;
		}
		.js_nested-block {
			opacity:0;
			visibility: hidden;
		}
	}
}