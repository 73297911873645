.schedule-event {
	display: block;
	position: relative;
	&:nth-child(odd) {
		background-color: #f2f2f2;
	}

	&__head {
		display: block;
		min-height:145px;
	}
	
	&__inner {
		display: flex;
		flex-flow: row nowrap;
		padding: 20px 40px 20px 20px;
		@media (max-width: 767px) {
			padding: 10px 10px 20px;
			flex-flow: column nowrap;
		}
	}

	&__col {
		display: flex;
		flex-flow: column nowrap;
		text-align: left;
	}

	&__thumb {
		width: 60px;
		height: 60px;
		margin-right: 20px;
		img {
			display: block;
			width: auto;
			max-width: 100%;
		}
		@media (max-width: 600px) {
			width: 40px;
			height: 40px;
		}
	}

	&__title {
		margin: 5px 0;
		@include fontGillSans;
		color: #221f1f;
		font-size: 18px;
		font-weight: 700;
		line-height: 20px;
		@media (max-width: 600px) {
			font-size: 16px;
		}
	}

	&__desc {
		display: block;
		margin: 5px 0;
		@include fontGillSans;
		color: #7a7979;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
	}

	&__link {
		display: block;
		margin-top: 10px;
		height: 31px;
		@media (max-width: 600px) {
			.is-link {
				font-size: 13px;
				line-height: 1;
				&::after {
					top: 6px;
				}
			}
		}
	}
}