.text {
	@include fontGillSans;
	font-size: 18px;
	
	&--blue {
		color: #005eb0;
	}

	&--small {
		font-size: 11px;
	}

	&--block {
		display: block;
	}
}