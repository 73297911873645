.prices-grid {
	position: relative;

	&::before {
		content:'';
		display: table;
	}

	&::after {
		content:'';
		display: table;
		clear: both;
	}
	
	&__head-table {
		width:100%;
		margin-top: 9px;
		
		.col-8 {
			width: 80%;
			float: left;
			@media (max-width: 768px) {
				width:100%;
			}
		}

		.col-2 {
			width:20%;
			float:left;
			@media (max-width: 768px) {
				width:100%;
			}
		}
	}

	.inner {
		padding-left: 30px;

		@media (max-width: 991px) {
			padding-left: 15px;
		}
	}

	.side-title {
		@include fontGillSans;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 600;
		line-height: 20px;
		color: #221f1f;
		margin-bottom: 10px;
		margin-top: 20px;
	}

	.side-text {
		font-family: 'HelveticaNeueCyr';
		font-size: 11px;
		line-height: 15px;
		font-weight: 400;
		color: #7a7979;

		p {
			margin:0 0 12px 0;
		}

		b, strong {
			font-weight: 700;	
		}
	}
}