.is-news-item {
	@include fontGillSans;
	
	&__img {
		display: block;
		margin-bottom: 20px;
		height:230px;
		width: 100%;
		object-fit:cover;
		object-position:center;
	}

	&__p-date {
		display: block;
		color: #acbecb;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.4px;
		margin-top: 15px;
	}
}