.is-checkbox {
	position: relative;
	-webkit-tap-highlight-color: transparent;
	touch-action: manipulation;
	-ms-touch-action:manipulation;
	cursor:pointer;

	&--err {
		.is-checkbox__box {
			background: #f22435;
		}
	}

	&--checked {
		.is-checkbox__box {
			background: #0060ad;
			&::after {
				border-bottom: 2px solid #0060ad;
		    	border-right: 2px solid #0060ad;
				visibility: visible;
		    	opacity: 1;
		    	top:7px;
		    	transition: opacity .1s, position .2s ease;
			}
		}
	}

	&--f-layout {
		display: flex;
		.is-checkbox__text {
			flex:1;
		}
	}
	
	&__box {
		line-height: 14px;
	    top: 2px;
	    width: 16px;
	    height: 16px;
	    margin-right: 12px;
	    position: relative;
    	display: inline-block;
    	border-radius: 3px;
    	background: rgba(#7a7979, .5);
    	transition: all .3s ease;

    	&::before {
		    position: absolute;
		    top: 2px;
		    right: 2px;
		    bottom: 2px;
		    left: 2px;
		    content: '';
		    border-radius: 2px;
		    background: #fff;
		    transition: all .3s ease;
    	}

    	&::after {
    		width: 6px;
    		height: 9px;
    		transform: translateY(-5px) rotate(43deg);
    		position: absolute;
		    top: 3px;
		    left: 5px;
		    content: '';
		    display: block;
		    border-bottom: 2px solid #221f1f;
		    border-right: 2px solid #221f1f;
		    background-size: 100%;
		    visibility: hidden;
		    opacity: 0;
		    transition: opacity .3s, position .3s ease;
    	}
	}

	&__control {
		position: absolute;
	    z-index: -1;
	    margin: 0;
	    opacity: 0;
	}

	&__text {
		@include fontGillSans;
		color: #7a7979;
		font-size: 16px;
		line-height: 20px;
		display:inline-block;
	}

	&:hover {
		.is-checkbox__box {
			background: #0060ad;
		}
	}
}
