.is-show-snippet {

  &__wrap {
    margin-bottom: 20px;
    display: block;
  }

  &__wrap2 {
    margin-bottom: 40px;
    display: block;
  }

  &__img-wrap {
    display: block;
    width: 100%;
  }

  &__img {
    margin: auto;
  }

  &__bottom {
    display: block;
    text-align: center;
    margin-top: 34px;
    padding: 0 88px;

    &--no-pad {
      padding: 0;
    }

    @media (max-width: 991px) {
      padding: 0;
    }
  }

  &__price {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 10px;
    -webkit-font-smoothing: auto;
  }

  &__small-title {
    text-transform: uppercase;
    color: #0060ad;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
  }

  &__title {
    margin: 10px 0 20px;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -1.5px;
    color: #ffffff;

    &--black {
      color: #221f1f;
    }
  }

  &__text {
    @include fontGillSans;
    margin: 20px 0 30px;
    color: #acbecb;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.5px;

    &--black {
      color: #7a7979;
    }
  }

  &__button-wrap {
    margin: 30px 0 20px;
  }

  &__link {
    @include fontGillSans;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    &--black {
      color: #221f1f;

      &:hover {
        color: #7fafd6;
      }
    }
  }
}

.is-show-snippet--small {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  flex:1;

  .is-show-snippet__img {
    min-height: 200px;
    object-fit:cover;
  }

  .is-show-snippet__bottom {
    margin-top: 15px;
    // display: flex;
    // flex-flow: column nowrap;
    // height: 100%;
  }
  .is-show-snippet__title {
    @include fontGillSans;
    color: #293036;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 10px;
  }

  .is-show-snippet__text {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .small-text {
    padding: 0 20px;
    margin-bottom: 30px;
  }

  .is-show-snippet__button-wrap {
    position: relative;
    margin-top: auto;
    text-align: center;

    .is-show-snippet__link {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-show-snippet {
    &__title {
      font-size: 20px;
      line-height: 20px;
    }
  }
  .is-show-snippet__wrap2 {
    margin-bottom: 20px;
  }
}



