.e-menu {
	position: relative;
	display: block;
	background-color: #ffffff;
	border-radius: 3px;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
	
	&__nav {
		padding: 10px 60px 10px 0;
		flex:initial;
		@media (max-width: 991px) {
			padding: 10px 10px 10px 0;
			 
		}
		@media (max-width: 660px) {
			display: flex;
			flex-flow:row wrap;
		}
	}

	&__nav-item {
		display: block;
		position: relative;
		margin: 26px 0;
		padding-left: 40px;

		&::before {
			content:'';
			display: block;
			position: absolute;
			left:0;
			top:0;
			bottom:0;
			width:4px;
			background-color: #0060ad;
			transition: transform .2s ease;
			transform:scaleY(0);
		}

		&.active {
			&::before {
				transform:scaleY(1);
			}
		}
		@media (max-width: 660px) {
			width:50%;
			margin:13px 0;
		}
		@media (max-width: 577px) {
			width:100%;
		}
		@media (max-width: 480px) {
			margin:6px 0;
		}
	}

	&__nav-item-text {
		position: relative;
		display: block;
		@include fontGillSans;
		color: rgba(#221f1f, .5);
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
		padding:7px 0;

		&:hover, &:focus {
			color: #221f1f;
		}
	}

	&__wrap {
		display: flex;
		@media (max-width: 660px) {
			flex-flow:column nowrap;
		}
	}

	&__content {
		flex: 1;
		box-shadow: inset 1px 0 0 #ebebeb;
		padding:50px 40px 20px 40px;

		@media (max-width: 660px) {
			box-shadow: inset 0px 1px 0 #ebebeb;
		}

		@media (max-width: 480px) {
			padding:10px 10px 20px 10px;
		}
	}

	&__price {
		color: #0060ad;
		font-size: 12px;
		font-weight: 700;
		line-height: 20px;
		text-transform: uppercase;
	}

	&__text {
		display: block;
		margin-top: 10px;
		margin-bottom: 30px;
		@include fontGillSans;
		color: #7a7979;
		font-size: 18px;
		line-height: 25px;
		letter-spacing: -0.5px;
		text-align: left;
	}

	&__btn-group {
		display: flex;
		margin-top: 30px;

		.btn + .btn {
			margin-left: 20px;
		}

		@media (max-width: 660px) {
			flex-flow:column wrap;

			.btn + .btn {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}

  position: relative;
  display: block;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);

  &__nav {
    padding: 10px 60px 10px 0;
    flex: initial;
  }

  &__nav-item {
    display: block;
    position: relative;
    margin: 26px 0;
    padding-left: 40px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: #0060ad;
      transition: transform .2s ease;
      transform: scaleY(0);
    }

    &.active {
      &::before {
        transform: scaleY(1);
      }
    }
  }

  &__nav-item-text {
    position: relative;
    display: block;
    @include fontGillSans;
    color: rgba(#221f1f, .5);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    padding: 7px 0;

    &:hover, &:focus {
      color: #221f1f;
    }
  }

  &__wrap {
    display: flex;
  }

  &__content {
    flex: 1;
    box-shadow: inset 1px 0 0 #ebebeb;
    padding: 50px 40px 20px 40px;
  }

  &__price {
    color: #0060ad;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__text {
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    @include fontGillSans;
    color: #7a7979;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.5px;
    text-align: left;
  }

  &__btn-group {
    display: flex;
    margin-top: 30px;

    .btn + .btn {
      margin-left: 20px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .e-menu {
    box-shadow: none;
    border-bottom: 1px solid #ebebeb;
    &__wrap {
      flex-direction: column;
    }
    &__nav {
      padding: 0;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      display: flex;
    }
    &__nav-item {
      margin: 0 10px;
      padding-left: 0;
      padding-bottom: 12px;
      &:before {
        top: auto;
        left: 0;
        right: 0;
        height: 4px;
        width: auto;
        transform: scaleX(0);
      }
      &.active {
        &:before {
          transform: scaleX(1);
        }
      }
    }
    &__nav-item-text {

    }
    &__content {
      box-shadow: none;
      border-top: 1px solid #ebebeb;
      padding-left: 0;
      padding-right: 0;
      padding-top: 25px;
      padding-bottom: 25px;
    }
    &__btn-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .btn + .btn {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }
}








