.is-page-lessons {
	
	&__section {
		margin-top: 0;
	}

	&__main-top-offset {
		margin-top: 20px;
	}

	&__header {
		font-size: 30px;
	    font-weight: 700;
	    line-height: 30px;
	    letter-spacing: -1.5px;
	    margin: 0 0 30px 0;
	}

	&__list {
		@include is-grid(2,30);
		padding-bottom: 40px;

		@media (max-width:768px) {
			@include is-grid(1,30);
		}
	}
}