.page-how-to-get {
	
	.map {
		height: 500px;
		margin: 70px 0 0;
	}

	h5 {
		margin-top: 0;
	}

	&__section {
		margin-bottom: 70px;
	}

	.b-section__title {
		font-size: 60px;
	}

	&__inner-section {
		margin-bottom: 50px
	}
}