.is-f-banner {
	display: block;
	position: relative;
	background-repeat: no-repeat;
	background-position: center top;
	color:#ffffff;

	&__inner {
		padding:80px 100px;
	}

	&__title {
		display: block;
		color: #ffffff;
		font-size: 30px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -1.5px;
		margin: 0 0 30px 0;
		max-width: 300px;
	}

	&__btn-group {
		display: block;
		margin-top: 30px;
		text-align: left;
	}

	&__footer-to-right {
		display: block;
		text-align: right;
		margin-top: 46px;
	}

	&__contacts {
		display: inline-block;
		text-align: left;
		@include fontGillSans;

		.contact-row {
			display: block;

			.label {
				display: block;
				font-size: 18px;
				color:rgba(#ffffff, .5);
				line-height: 25px;
				letter-spacing: -0.5px;
				margin-bottom: 5px;
			}

			.text {
				color: #ffffff;
				font-size: 30px;
				line-height: 32px;
				letter-spacing: -0.5px;

				&.link:hover, &.link:focus {
					color:#f5a623;
				}
			}
		}

		.contact-row + .contact-row {
			margin-top: 20px;
		}
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-f-banner {
    &__inner {
      padding: 30px 20px;
    }
    &__title {
      font-size: 20px;
      line-height: 24px;
    }
    &__footer-to-right {
      text-align: left;
    }
    &__contacts {
      .contact-row {
        .text {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}
