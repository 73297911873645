@mixin text-whith-ellipsis($font-size, $line-height, $lines-to-show) {
	display: block;
  	display: -webkit-box;
  	height: calc(#{$lines-to-show} * #{$line-height});
  	font-size: $font-size;
  	line-height: $line-height;
  	-webkit-line-clamp: $lines-to-show;
  	-webkit-box-orient: vertical;
  	overflow: hidden;
  	text-overflow: ellipsis;
}

@mixin centerIt($position) {
	position: $position;
	left:50%;
	top:50%;
	transform:translate(-50%, -50%);
}

@mixin centerItHorizintal($position) {
	position: $position;
	left:50%;
	transform:translateX(-50%);
}

@mixin fontGillSans {
	font-family: "Gill Sans", 'Arial', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin clearfix {
  *zoom: 1;
  &::before { content: " "; display: table; }
  &::after { content: " "; display: table; }
  &::after { clear: both; }
}
