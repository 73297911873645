
.is-hamburger {
	position: relative;
	z-index: 20;
	top:0;
	font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 20px 25px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    display: none;

    &:hover {
    	opacity: .7;
    }
    @media (max-width: 1199px) {
    	display: block;
    }
}
.is-hamburger-box {
	position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
}
.is-hamburger-inner {
	top:50%;
	display: block;
	margin-top: -2px;
}
.is-hamburger--slider .is-hamburger-inner {
	top:2px;
}
.is-hamburger-inner, .is-hamburger-inner:after, .is-hamburger-inner:before {
	position: absolute;
    width: 30px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #000;
}

.is-hamburger .is-hamburger-inner, .is-hamburger .is-hamburger-inner:after, .is-hamburger .is-hamburger-inner:before {
	background-color: #0060ad;
}
.is-hamburger-inner:after, .is-hamburger-inner:before {
	display: block;
	content:'';
}
.is-hamburger--slider .is-hamburger-inner:after {
	top:16px;
}
.is-hamburger-inner, .is-hamburger-inner:after, .is-hamburger-inner:before {
	position: absolute;
    width: 30px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 2px;
    background-color: #000;
}
.is-hamburger-inner:after {
	bottom:-10px;
}

.is-hamburger--slider .is-hamburger-inner:before {
	top: 8px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform,opacity;
}
.is-hamburger-inner:before {
	top: -10px;
    width: 30px;
}

.is-hamburger--slider.is-active .is-hamburger-inner {
	transform:translate3d(0, 6px,0) rotate(45deg);
}
.is-hamburger--slider.is-active .is-hamburger-inner:before {
	transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
    opacity: 0;
}
.is-hamburger--slider.is-active .is-hamburger-inner:after {
	transform:translate3d(0,-16px,0) rotate(-90deg);
}
