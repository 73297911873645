.is-m-plan__canvas {
	.f-num.three {
		width: em(317);
		height: em(145);
	}
	.f3-bl1 {
		position: relative;
		z-index: 1;
		height: em(400);
		width: em(990);
		margin-left: auto;
		right: em(7);
	}
	.f3-bl2 {
		position: absolute;
		z-index: 2;
		right: 0;
		bottom: em(36);
		height: em(408);
		width: em(1000);
	}
		
	.floor-3 {
		// position: relative;
		padding-top: em(24);
		.f-layer-1 {
			z-index: 2;
			left: em(580);
			bottom: em(92);
			width: em(111);
			height: em(39);
		}
		.f-layer-2 {
			z-index: 3;
			width: em(177);
			height: em(67);
			bottom: em(91);
			left: em(400);
		}
		.f-layer-3 {
			z-index: 2;
			width: em(119);
			height: em(46);
			bottom: em(125);
			left: em(363);
		}
		.f-layer-4 {
			z-index: 2;
			width: em(623);
			height: em(227);
			bottom: em(201);
			left: em(246);
		}
		.f-layer-5 {
			z-index: 3;
			width: em(55);
			height: em(31);
			bottom: em(175);
			left: em(318);
		}
		.f-layer-6 {
			z-index: 3;
			width: em(47);
			height: em(29);
			bottom: em(174);
			left: em(458);
		}
		.f-layer-7 {
			z-index: 3;
			width: em(51);
			height: em(33);
			bottom: em(329);
			right: em(282);
		}
		.f-layer-8 {
			z-index: 3;
			width: em(40);
			height: em(21);
			bottom: em(400);
			right: em(328);
		}
		.f-layer-9 {
			z-index: 2;
			width: em(96);
			height: em(37);
			bottom: em(271);
			right: em(89);
		}
		.f-layer-10 {
			z-index: 3;
			width: em(157);
			height: em(58);
			bottom: em(318);
			right: em(74);
		}
		.f-layer-11 {
			z-index: 2;
			width: em(95);
			height: em(37);
			bottom: em(356);
			right: em(158);
		}
		.f-layer-12 {
			z-index: 3;
			width: em(83);
			height: em(60);
			bottom: em(299);
			left: em(636);
		}
		.f-layer-13 {
			z-index: 3;
			width: em(146);
			height: em(34);
			bottom: em(262);
			left: em(574);
		}
		.f-layer-14 {
			z-index: 3;
			width: em(58);
			height: em(17);
			bottom: em(186);
			left: em(329);
		}
		.f-layer-15 {
			z-index: 3;
			width: em(58);
			height: em(17);
			bottom: em(184);
			left: em(460);
		}
		.f-layer-16 {
			z-index: 3;
			width: em(58);
			height: em(17);
			bottom: em(342);
			right: em(266);
		}
		.f-layer-17 {
			z-index: 3;
			width: em(54);
			height: em(17);
			bottom: em(400);
			right: em(305);
		}
		.f-layer-18 {
			z-index: 3;
			width: em(105);
			height: em(72);
			bottom: em(376);
			right: em(59);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: 0s;
		}
		.f-layer-19 {
			z-index: 3;
			width: em(39);
			height: em(26);
			bottom: em(458);
			right: em(202);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: 1.4s;
		}
		.f-layer-20 {
			z-index: 3;
			width: em(51);
			height: em(18);
			bottom: em(477);
			right: em(222);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: .6s;
		}
		.i-l-1 {
			z-index:10;
			left: em(683);
			bottom: em(150);
			width: em(47);
			height: em(30);
		}
		.i-l-2 {
			z-index:10;
			right: em(243);
			bottom: em(256);
			width: em(47);
			height: em(30);
		}
		.i-l-3 {
			z-index:10;
			left: em(603);
			bottom: em(105);
			width: em(50);
			height: em(30);
		}
		.i-l-4 {
			z-index:10;
			right: em(116);
			bottom: em(283);
			width: em(50);
			height: em(30);
		}
		.i-l-5 {
			z-index:10;
			right: em(145);
			bottom: em(335);
			width: em(15);
			height: em(34);
		}
		.i-l-6 {
			z-index:10;
			left: em(475);
			bottom: em(114);
			width: em(17);
			height: em(34);
		}
		.i-l-7 {
			z-index:10;
			left: em(641);
			bottom: em(149);
			width: em(29);
			height: em(38);
		}
		.i-l-8 {
			z-index:10;
			left: em(401);
			bottom: em(146);
			width: em(29);
			height: em(31);
		}
		.i-l-9 {
			z-index:10;
			right: em(191);
			bottom: em(370);
			width: em(34);
			height: em(41);
		}
		.i-l-10 {
			z-index:10;
			right: em(370);
			bottom: em(395);
			width: em(28);
			height: em(25);
		}
		.i-l-11 {
			z-index:10;
			right: em(331);
			bottom: em(335);
			width: em(52);
			height: em(25);
		}
		.i-l-12 {
			z-index:10;
			left: em(460);
			bottom: em(206);
			width: em(47);
			height: em(25);
		}
		.i-l-13 {
			z-index:10;
			left: em(342);
			bottom: em(207);
			width: em(24);
			height: em(25);
		}
	}
}