.is-search {
	position: relative;

	&__form {
		position: relative;
	}

	&__control {
		.is-input__control {
			padding-right: 70px;
		}
	}
	
	&__submit {
		position: absolute;
		top:0;
		right: 0;
		bottom: 0;
		width: 60px;
		background-size: 30px 30px;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url('../img/i-search.svg');
		background-color: transparent;
		border:none;
	}

	&__result {
		margin-top: 30px;
		margin-bottom: 45px;
		color: #7a7979;
		@include fontGillSans;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.4px;
	}

	&__item {
		margin-bottom: 50px;
	}

	&__item-header {
		font-size: 30px;
		font-weight: 700;
		line-height: 1;
		letter-spacing: -1.5px;

		.link {
			color: #221f1f;
			&:hover, &:focus {
				color: #7fafd6;
			}
		}
		@media (max-width: 768px) {
			font-size: 20px;
			line-height: 22px;
		}
	}

	&__preview {
		@include fontGillSans;
		margin:20px 0;
		font-size: 18px;
		color: #7a7979;
		letter-spacing: -0.5px;
	}

	&__link {
		margin-top: 20px;
	}

	&__breadcrumbs {
		margin:20px 0;

		.breadcrumbs {
			left:0;
		}
	}
}