.is-show-btn {
	display: inline-block;
	cursor: pointer;
	border: 2px solid rgba(34, 31, 31, 0.1);
	border-radius: 3px;
	height:50px;
	line-height: 50px;
	padding-left: 48px;
	padding-right: 18px;
	background-image: url('../img/show-dots.svg');
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position: left 18px center;
	transition: all .25s ease-out;

	&--lg {
		height:60px;
		line-height: 60px;
		padding-left: 63px;
		padding-right: 28px;

		.is-show-btn__text {
			font-family: 'Open Sans';
			text-transform: uppercase;
			color: #221f1f;
			font-size: 12px;
			font-weight: 700;
		}
	}

	&__text {
		@include fontGillSans;
		display: inline-block;
		color:rgba(#221f1f, .6);
		font-size: 16px;
		line-height: 20px;
		white-space: nowrap;
		transition: color .25s ease-out;
	}

	&:hover, &:focus {
		background: #f5a523;
		border: 2px solid #f5a623;
		background-image:url('../img/show-dots-h.svg'), radial-gradient(circle 100px at 51.05% 50%,rgba(255,255,255,.15) 0,rgba(0,0,0,.03) 100%);
		background-size: 20px 20px;
		background-repeat: no-repeat;
		background-position: left 18px center;
		.is-show-btn__text {
			color:#ffffff;
		}
	}
}