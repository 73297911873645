.is-transfer {
	
	&__container {
		display: flex;
		justify-content: space-between;
		flex-flow:row wrap;
		margin-top: 50px;
	}

	&__item {
		width: 30%;
		padding: 0 5px;
		text-align: center;
		margin-bottom: 40px;
		@media (max-width:768px) {
			width: 50%;
		}
		@media (max-width:480px) {
			width: 100%;
		}
	}

	&__img {
		height: 45px;
		display: inline-block;
	}

	&__name {
		color: #221f1f;
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 10px;
		letter-spacing:-0.5px;
	}

	&__desc {
		color: #7a7979;
		font-size: 18px;
		line-height: 20px;
		letter-spacing:-0.5px;
	}
}