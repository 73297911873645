.is-schedule {
	display: flex;
	align-items: flex-start;

	&__events-column {
		width: 300px;
		display: flex;
		flex-flow:column nowrap;
		margin-top: 30px;
		box-shadow: 8px 0px 8px -8px rgba(#9b9b9b, .8);
		-moz-box-shadow: 8px 0px 8px -8px rgba(#9b9b9b, .8);
		z-index: 1;
		@media (max-width: 767px) {
			width: 200px;
		}
		@media (max-width: 600px) {
			width: 140px;
		}
		@media (max-width: 480px) {
			width: 100px;
		}
	}

	&__timeline {
		position: relative;
		z-index: 2;
		top:1px;
	}

	&__events-list {
		flex:1;
		width: 100%;
		overflow-x: auto;
		white-space: nowrap;
		margin-top: -40px;
	}

	&__timespace-col {
		position: relative;
		width: 200px;
		flex: 0 0 200px;
		border-left: 1px dashed #e5e5e5;
		border-top: 1px dashed #e5e5e5;
	}

	&__time-row-parent.close-on-entry {
		position: relative;
		&::after {
			content: 'Закрыт на вход';
			display: block;
			position: absolute;
			right: 1px;
			top:0;
			bottom: 0;
			width: 200px;
			border-left: 1px dashed #0060ad;
			padding: 20px 0 0 15px;
			@include fontGillSans;
			color: #7a7979;
			font-size: 14px;
		}
	}

	&__time-row-parent:nth-child(odd) {
		.is-schedule__timespace-col {
			background-color: #f2f2f2;
		}
	}

	&__time-row {
	    display: flex;
	    flex-flow: row nowrap;
	    position: relative;
	    top: 0;
	    min-height: 100%;
	    height: 145px;
	}

	&__e-head {
		// flex:1;
	}

	&__navs {
		display: block;
		position: relative;
		width: calc(100% - 300px);
		margin-left: auto;
		&.bottom-navs {
			.is-schedule__nav-item {
				top:auto;
				bottom: calc(100% + 25px);
			}
		}
		@media (max-width: 767px) {
			width: calc(100% - 200px);
		}
		@media (max-width: 600px) {
			width: calc(100% - 140px);
		}
		@media (max-width: 480px) {
			width: calc(100% - 100px);
		}
	}

	&__nav-item {
		display: block;
		position: absolute;
		width: 40px;
		height: 40px;
		border: 1px solid #eaeff2;
		border-radius: 40px;
	    background-image: url(../img/arrow.png);
	    background-color: #fff;
	    background-repeat: no-repeat;
	    background-position: center center;
	    transition: border-color .3s;
	    cursor:pointer;
	    z-index: 10;
	    top:75px;
	    &:hover {
	    	border-color: #0060ad;
	    }

		&--next {
			right: 0;
		}
		&--prev {
			left: -60px;
			transform: scale(-1);
		}
	}
}