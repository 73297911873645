.tags {

	&__item {
		display: inline-block;
		vertical-align: top;
		color:#{map-get($palette, accent-blue)}!important;
		text-transform: uppercase;
		font-size: 10px;
		margin-right: 3px;

		&:hover, &:focus {
			color:#{map-get($palette, accent-blue)};
		}
	}
}