.is-feedback {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  &__outer {
    border: 10px solid #f7f7f7;
    background-color: #ffffff;
    flex: 1;
  }

  &__inner {
    padding: 43px 73px 43px 52px;
  }

  &__title {
    color: #221f1f;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -1.2px;
    margin: 0 0 10px;
  }

  &__text {
    margin: 10px 0;
    @include fontGillSans;
    color: #7a7979;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.5px;
  }

  &__phone {
    display: block;
    color: #293036;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin: 20px 0;
    white-space: nowrap;

    &:hover, &:focus {
      color: #0060ad;
    }
  }

  &__mail {
    display: block;
    @include fontGillSans;
    color: #7a756a;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.5px;
    white-space: nowrap;

    &:hover, &:focus {
      color: #0060ad;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-feedback {
    &__inner {
      padding: 25px;
    }
    &__title {
      font-size: 20px;
      line-height: 24px;
    }
    &__phone {
      font-size: 20px;
      line-height: 24px;
    }
    &__mail {
      font-size: 18px;
    }
  }
}
