.is-m-plan__canvas {

	.f0-bl1	{
		position: relative;
		z-index: 1;
		width: 100%;
		height: em(678);
	}
	.f0-bl2 {
		position: absolute;
		z-index: 2;
		width: 100%;
		right: 0;
		bottom: em(40);
		height: em(681);
		transition: all .3s ease-in-out;
		will-change: width;
		width: em(760);
		bottom: em(-80);
		right: em(220);
	}

	&.is-active {
		.f0-bl2 {
			width: 100%;
			right: 0;
			bottom: em(40);
		}
	}
		
	.floor-0 {
		position: relative;
		.f-num {
			bottom: em(510);
		}
		
		.f-layer-1 {
			z-index:3;
			left: em(271);
			bottom: em(65);
			width: em(456);
			height: em(149);
		}
		.f-layer-2 {
			z-index:4;
			left: em(633);
			bottom: em(177);
			width: em(118);
			height: em(54);
		}
		.f-layer-3 {
			z-index: 2;
			left: em(121);
			bottom: em(181);
			width: em(210);
			height: em(123);
		}
		.f-layer-4 {
			z-index: 3;
			left: em(114);
			bottom: em(281);
			width: em(288);
			height: em(112);
		}
		.f-layer-5 {
			z-index: 4;
			left: em(273);
			bottom: em(313);
			width: em(301);
			height: em(122);
		}
		.f-layer-6 {
			z-index: 5;
			left: em(425);
			bottom: em(408);
			width: em(182);
			height: em(76);
		}
		.f-layer-7 {
			z-index: 5;
			left: em(514);
			bottom: em(462);
			width: em(128);
			height: em(58);
		}
		.f-layer-8 {
			z-index: 5;
			left: em(594);
			bottom: em(450);
			width: em(98);
			height: em(40);
		}
		.f-layer-9 {
			z-index: 5;
			left: em(647);
			bottom: em(412);
			width: em(149);
			height: em(61);
		}
		.f-layer-10 {
			z-index: 6;
			left: em(754);
			bottom: em(310);
			width: em(95);
			height: em(115);
		}
		.f-layer-11 {
			z-index: 5;
			left: em(769);
			bottom: em(309);
			width: em(181);
			height: em(126);
		}
		.f-layer-12 {
			z-index: 5;
			left: em(758);
			bottom: em(243);
			width: em(113);
			height: em(70);
		}
		.f-layer-13 {
			z-index: 6;
			left: em(821);
			bottom: em(251);
			width: em(107);
			height: em(57);
		}
		.f-layer-14 {
			z-index: 3;
			left: em(897);
			bottom: em(227);
			width: em(47);
			height: em(28);
		}
		.f-layer-15 {
			z-index: 3;
			left: em(754);
			bottom: em(164);
			width: em(142);
			height: em(77);
		}
		.f-layer-16 {
			z-index: 3;
			left: em(615);
			bottom: em(115);
			width: em(125);
			height: em(51);
		}
		.f-layer-17 {
			z-index: 3;
			left: em(977);
			bottom: em(357);
			width: em(99);
			height: em(37);
		}
		.f-layer-18 {
			z-index: 7;
			left: em(405);
			bottom: em(309);
			width: em(61);
			height: em(31);
		}
		.f-layer-19 {
			z-index: 7;
			left: em(526);
			bottom: em(355);
			width: em(62);
			height: em(30);
		}
		.f-layer-20 {
			z-index: 3;
			left: em(692);
			bottom: em(626);
			width: em(203);
			height: em(83);
		}
		.f-layer-21 {
			z-index: 4;
			left: em(727);
			bottom: em(569);
			width: em(315);
			height: em(121);
		}
		.f-layer-22 {
			z-index: 7;
			left: em(927);
			bottom: em(397);
			width: em(174);
			height: em(102);
			animation-delay: -.6s;
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
		}
		.f-layer-23 {
			z-index: 4;
			left: em(551);
			bottom: em(74);
			width: em(242);
			height: em(48);
		}
		.f-layer-24 {
			z-index: 10;
			left: em(128);
			bottom: em(155);
			width: em(138);
			height: em(53);
		}
		.f-layer-25 {
			z-index: 10;
			left: em(147);
			bottom: em(404);
			width: em(172);
			height: em(53);
		}
		.f-layer-26 {
			z-index: 10;
			left: em(842);
			bottom: em(316);
			width: em(110);
			height: em(22);
		}
		.i-l-1 {
			z-index:10;
			left: em(515);
			bottom: em(334);
			width: em(82);
			height: em(63);
		}
		.i-l-2 {
			z-index:10;
			left: em(457);
			bottom: em(350);
			width: em(50);
			height: em(30);
		}
		.i-l-3 {
			z-index:10;
			left: em(419);
			bottom: em(289);
			width: em(34);
			height: em(63);
		}
		.i-l-4 {
			z-index:10;
			left: em(514);
			bottom: em(135);
			width: em(112);
			height: em(56);
		}
		.i-l-5 {
			z-index:10;
			left: em(396);
			bottom: em(133);
			width: em(36);
			height: em(62);
		}
		.i-l-6 {
			z-index:10;
			left: em(899);
			bottom: em(614);
			width: em(50);
			height: em(31);
		}
		.i-l-7 {
			z-index:10;
			left: em(330);
			bottom: em(309);
			width: em(39);
			height: em(33);
		}
		.i-l-8 {
			z-index:10;
			left: em(528);
			bottom: em(420);
			width: em(39);
			height: em(33);
		}
		.i-l-9 {
			z-index:10;
			left: em(761);
			bottom: em(613);
			width: em(39);
			height: em(33);
		}
		.i-l-10 {
			z-index:10;
			left: em(907);
			bottom: em(678);
			width: em(39);
			height: em(33);
		}
		.i-l-11 {
			z-index:10;
			left: em(753);
			bottom: em(206);
			width: em(39);
			height: em(33);
		}
		.i-l-12 {
			z-index: 10;
			left: em(168);
			bottom: em(209);
			width: em(57);
			height: em(50);
		}
		.i-l-13 {
			z-index: 10;
			left: em(237);
			bottom: em(321);
			width: em(80);
			height: em(50);
		}
		.i-l-14 {
			z-index: 10;
			left: em(342);
			bottom: em(367);
			width: em(58);
			height: em(62);
		}
		.i-l-15 {
			z-index: 10;
			left: em(445);
			bottom: em(430);
			width: em(80);
			height: em(35);
		}
		.i-l-16 {
			z-index: 10;
			left: em(545);
			bottom: em(466);
			width: em(60);
			height: em(37);
		}
		.i-l-17 {
			z-index: 10;
			left: em(611);
			bottom: em(455);
			width: em(63);
			height: em(33);
		}
		.i-l-18 {
			z-index: 10;
			left: em(682);
			bottom: em(420);
			width: em(54);
			height: em(51);
		}
		.i-l-19 {
			z-index: 10;
			left: em(788);
			bottom: em(342);
			width: em(82);
			height: em(20);
		}
		.i-l-20 {
			z-index: 10;
			left: em(861);
			bottom: em(359);
			width: em(81);
			height: em(64);
		}
		.i-l-21 {
			z-index: 10;
			left: em(790);
			bottom: em(260);
			width: em(59);
			height: em(39);
		}
		.i-l-22 {
			z-index: 10;
			left: em(653);
			bottom: em(184);
			width: em(85);
			height: em(31);
		}
		.i-l-23 {
			z-index: 10;
			left: em(763);
			bottom: em(647);
			width: em(58);
			height: em(62);
		}
		.i-l-24 {
			z-index: 10;
			left: em(740);
			bottom: em(558);
			width: em(63);
			height: em(44);
		}
		.i-l-25 {
			z-index: 10;
			left: em(899);
			bottom: em(232);
			width: em(39);
			height: em(17);
		}
		.i-l-26 {
			z-index: 10;
			left: em(301);
			bottom: em(293);
			width: em(26);
			height: em(28);
		}
		.i-l-27 {
			z-index: 10;
			left: em(739);
			bottom: em(605);
			width: em(26);
			height: em(28);
		}
		.i-l-28 {
			z-index: 10;
			left: em(1001);
			bottom: em(373);
			width: em(35);
			height: em(43);
		}
		.i-l-29 {
			z-index: 10;
			left: em(724);
			bottom: em(190);
			width: em(45);
			height: em(33);
		}
		.i-l-30 {
			z-index: 10;
			left: em(777);
			bottom: em(207);
			width: em(45);
			height: em(33);
		}
		.i-l-31 {
			z-index: 10;
			left: em(657);
			bottom: em(135);
			width: em(29);
			height: em(39);
		}
		.i-l-32 {
			z-index: 10;
			left: em(357);
			bottom: em(215);
			width: em(29);
			height: em(39);
		}
		.i-l-33 {
			z-index: 10;
			left: em(463);
			bottom: em(201);
			width: em(43);
			height: em(28);
		}
		.i-l-34 {
			z-index: 10;
			left: em(874);
			bottom: em(282);
			width: em(43);
			height: em(32);
		}
		.i-l-35 {
			z-index: 10;
			left: em(947);
			bottom: em(257);
			width: em(22);
			height: em(29);
		}
		.i-l-36 {
			z-index: 10;
			left: em(975);
			bottom: em(272);
			width: em(35);
			height: em(30);
		}
		.i-l-37 {
			z-index: 10;
			left: em(635);
			bottom: em(397);
			width: em(34);
			height: em(35);
		}
		.i-l-38 {
			z-index: 10;
			left: em(167);
			bottom: em(318);
			width: em(36);
			height: em(49);
		}
		.i-l-39 {
			z-index: 10;
			left: em(302);
			bottom: em(333);
			width: em(49);
			height: em(47);
		}
	}
}