.is-m-plan__canvas {
	.f-num.one {
		width: em(59);
		height: em(144);
	}
	.f1-bl1 {
		position: relative;
		z-index: 1;
		height: em(399);
		width: em(989);
		margin-left: auto;
		right: em(8);
	}
	.f1-bl2 {
		position: absolute;
		z-index: 2;
		right: 0;
		bottom: em(37);
		height: em(419);
		width: em(1000);
	}
	.floor-1 {
		// position: relative;
		padding-top: em(24);
		// height: em(455);

		.f-layer-1 {
			z-index: 2;
			right: 0;
			bottom: em(263);
			width: em(268);
			height: em(129);
		}
		.f-layer-2 {
			z-index: 2;
			width: em(292);
			height: em(107);
			bottom: em(94);
			left: em(288);
		}
		.f-layer-3 {
			z-index: 2;
			width: em(156);
			height: em(88);
			bottom: em(86);
			left: em(536);
		}
		.f-layer-4 {
			z-index: 3;
			width: em(49);
			height: em(43);
			bottom: em(155);
			left: em(595);
		}
		.f-layer-5 {
			z-index: 3;
			width: em(47);
			height: em(63);
			bottom: em(139);
			left: em(626);
		}
		.f-layer-6 {
			z-index: 3;
			width: em(68);
			height: em(30);
			bottom: em(122);
			left: em(712);
		}
		.f-layer-7 {
			z-index: 3;
			width: em(239);
			height: em(61);
			bottom: em(214);
			left: em(346);
		}
		.f-layer-8 {
			z-index: 3;
			width: em(162);
			height: em(82);
			bottom: em(233);
			left: em(608);
		}
		.f-layer-9 {
			z-index: 3;
			width: em(106);
			height: em(122);
			bottom: em(306);
			left: em(720);
		}
		.f-layer-10 {
			z-index: 3;
			width: em(137);
			height: em(65);
			bottom: em(164);
			left: em(801);
		}
		.f-layer-11 {
			z-index: 3;
			width: em(20);
			height: em(30);
			bottom: em(236);
			left: em(847);
		}
		.f-layer-12 {
			z-index: 3;
			width: em(94);
			height: em(39);
			bottom: em(370);
			left: em(903);
		}
		.f-layer-13 {
			z-index: 4;
			width: em(64);
			height: em(32);
			bottom: em(308);
			left: em(854);
		}
		.f-layer-14 {
			z-index: 3;
			width: em(107);
			height: em(37);
			bottom: em(280);
			left: em(841);
		}
		.f-layer-15 {
			z-index: 3;
			width: em(69);
			height: em(30);
			bottom: em(227);
			left: em(962);
		}
		.f-layer-16 {
			z-index: 5;
			width: em(77);
			height: em(43);
			bottom: em(399);
			right: em(129);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: .6s;
		}
		.f-layer-17 {
			z-index: 5;
			width: em(59);
			height: em(22);
			bottom: em(467);
			right: em(237);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: 0s;
		}
		.f-layer-18 {
			z-index: 3;
			width: em(59);
			height: em(60);
			bottom: em(98);
			left: em(587);
		}
		.f-layer-19 {
			z-index: 3;
			width: em(247);
			height: em(20);
			bottom: em(75);
			left: em(484);
		}
		.f-layer-20 {
			z-index: 3;
			width: em(199);
			height: em(47);
			bottom: em(325);
			right: em(63);
		}
		.f-layer-21 {
			z-index: 3;
			width: em(58);
			height: em(13);
			bottom: em(155);
			left: em(709);
		}
		.f-layer-22 {
			z-index: 3;
			width: em(44);
			height: em(13);
			bottom: em(255);
			right: em(170);
		}
		.f-layer-23 {
			z-index: 2;
			width: em(136);
			height: em(65);
			bottom: em(203);
			right: em(338);
		}

		.i-l-1 {
			z-index:10;
			left: em(407);
			bottom: em(131);
			width: em(84);
			height: em(43);
		}
		.i-l-2 {
			z-index:10;
			left: em(445);
			bottom: em(231);
			width: em(43);
			height: em(22);
		}
		.i-l-3 {
			z-index:10;
			left: em(597);
			bottom: em(168);
			width: em(38);
			height: em(30);
		}
		.i-l-4 {
			z-index:10;
			right: em(298);
			bottom: em(313);
			width: em(38);
			height: em(30);
		}
		.i-l-5 {
			z-index:10;
			right: em(380);
			bottom: em(362);
			width: em(43);
			height: em(22);
		}
		.i-l-6 {
			z-index:10;
			left: em(698);
			bottom: em(220);
			width: em(44);
			height: em(32);
		}
		.i-l-7 {
			z-index:10;
			right: em(398);
			bottom: em(267);
			width: em(44);
			height: em(32);
		}
		.i-l-8 {
			z-index:10;
			left: em(647);
			bottom: em(168);
			width: em(44);
			height: em(32);
		}
		.i-l-9 {
			z-index:10;
			left: em(568);
			bottom: em(256);
			width: em(15);
			height: em(34);
		}
		.i-l-10 {
			z-index:10;
			right: em(450);
			bottom: em(329);
			width: em(18);
			height: em(34);
		}
		.i-l-11 {
			z-index:10;
			left: em(734);
			bottom: em(115);
			width: em(58);
			height: em(33);
		}
		.i-l-12 {
			z-index:10;
			right: em(152);
			bottom: em(213);
			width: em(58);
			height: em(33);
		}
		.i-l-13 {
			z-index:10;
			left: em(566);
			bottom: em(212);
			width: em(25);
			height: em(25);
		}
		.i-l-14 {
			z-index:10;
			right: em(392);
			bottom: em(303);
			width: em(25);
			height: em(25);
		}
		.i-l-15 {
			z-index:10;
			right: em(393);
			bottom: em(220);
			width: em(39);
			height: em(39);
		}
		.i-l-16 {
			z-index:10;
			right: em(287);
			bottom: em(202);
			width: em(46);
			height: em(41);
		}
		.i-l-17 {
			z-index:10;
			right: em(352);
			bottom: em(177);
			width: em(24);
			height: em(34);
		}
		.i-l-18 {
			z-index:10;
			right: em(330);
			bottom: em(251);
			width: em(21);
			height: em(27);
		}
		.i-l-19 {
			z-index:10;
			right: em(147);
			bottom: em(288);
			width: em(28);
			height: em(37);
		}
		.i-l-20 {
			z-index:10;
			right: em(243);
			bottom: em(387);
			width: em(33);
			height: em(41);
		}
		.i-l-21 {
			z-index:10;
			left: em(627);
			bottom: em(234);
			width: em(36);
			height: em(43);
		}
		.i-l-22 {
			z-index:10;
			left: em(685);
			bottom: em(258);
			width: em(43);
			height: em(43);
		}
		.i-l-23 {
			z-index:10;
			right: em(275);
			bottom: em(283);
			width: em(44);
			height: em(32);
		}
	}
}
















