.is-order-form {

	width:520px;

	&__inner {
		padding: 20px 30px;
	}

	&__title {
		margin:0 0 30px;
		display:block;
		text-align: center;
		font-size: 30px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -1.5px;
	}

	&__cb-group {
		display: flex;
		flex-flow:row wrap;
		justify-content: space-between;

		.is-checkbox {
			width:30%;

			@media (max-width: 414px) {
				width:auto;
			}
		}
	}

	.hint-label	{
		position: absolute;
		font-size: 16px;
		line-height: 20px;
	    display: block;
	    white-space: nowrap;
	    @include fontGillSans;

	    &--right {
			top:50%;
			transform:translateY(-50%);
			left: 100%;
			margin-left: 30px;
	    }
	}

	.is-err-state {
		transition: color .2s ease-in-out;
		color: #f22435!important;
	}

	.is-hidden-field {
		height:0;
		overflow: hidden;
		transition: height .2s ease-in-out;
	}
}

.datepickers-container {
	z-index: 99999;
	// position:fixed;
}

.datepicker--day-name {
	color:#f5a623;
}

.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current- {
	background:#0060ad;
}
.datepicker--cell.-current- {
	color:#0060ad;
}
.datepicker--cell.-disabled- {
	color: #f22435;
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-order-form {
    width: auto;
    &__inner {
      padding-left: 0;
      padding-right: 0;
    }
  }

}
