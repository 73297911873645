.breadcrumbs {
	position: relative;

	&--theme-dark {
		left:120px;

		.breadcrumbs__item-text {
			color: #221f1f;
		}
		.breadcrumbs__item::before {
			color: #221f1f;
		}
		a.breadcrumbs__item-text {
			&:hover, &:focus {
				color:rgba(#221f1f, .6);
			}
		}

		@media (max-width: 1469px) {
			left: -30px;
		}
		@media (max-width: 1199px) {
			left:0;
		}
		@media (max-width: 768px) {
			left:-20px;
		}
		@media (max-width: 577px) {
			left:10px;
		}
		@media (max-width: 480px) {
			left:0;
		}
	}

	&--position-wide {
		left: 22px;
		@media (max-width: 1469px) {
			left: -30px;
		}
		@media (max-width: 1199px) {
			left:0;
		}
	}
	&--position-wide2 {
		left: 120px;
		@media (max-width: 1469px) {
			left: -30px;
		}
		@media (max-width: 1199px) {
			left:0;
		}
	}
	&--position-wide3 {
		left: 22px;
		@media (max-width: 1469px) {
			left: -127px;
		}
		@media (max-width: 1199px) {
			left:0;
		}
	}

	&__container {
		position: relative;
		display: block;
	}

	&__list {
		display: flex;
		margin: 0;
		padding: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin-right: 8px;
		padding-left: 12px;
		white-space: nowrap;

		&::before {
			content: '\2022';
			color: rgba(#ffffff, .6);
			line-height: 15px;
			position: absolute;
			top:3px;
			left:0;
			display: inline-block;
		}

		&:first-child {
			padding-left: 0;

			&::before {
				content: none;
			}
		}
	}

	&__item-text {
		color: rgba(#ffffff, .6);
		font-family: "Gill Sans";
		font-size: 13px;
		font-weight: 400;
		line-height: 15px;
		transition: color .3s ease;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		user-select:none;
	}

	&-cnt{
		position: relative;
	}

	&--theme-gray {
		opacity: .6;
		.breadcrumbs__item-text {
			color: #221f1f;
		}
		.breadcrumbs__item::before {
			color: #221f1f;
		}

		.breadcrumbs-cnt &{
			position: absolute;
			top: -97px;
			@media (max-width: 991px) {
				top: -97px;
			}
		}
	}
}

a.breadcrumbs__item-text {
	&:hover, &:focus {
		color:#ffffff;
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .breadcrumbs__list {
    flex-wrap: wrap;
  }
  .breadcrumbs__item {
    white-space: normal;
  }
}
