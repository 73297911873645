.is-show-page {
	display: block;

	&--start {
		.b-section__social {
			background-color: #fff;
		}

		.is-show-page__do-not-miss {
			background-color: #f7f7f7;
			box-shadow: inset 0 -1px 0 rgba(155, 155, 155, 0.24);
		}
	}

	&__slider {
		margin-top: 40px;
		margin-bottom: 30px;
	}

	&__start-page-grid {
		@include is-grid(2,50);
		margin-top: 70px;

		.b-section__title {
			margin-top: 0px;
		}
		.b-section__longtitle, .b-section__text, .b-section__title {
			text-align: left;
		}

		@media (max-width: 991px) {
			margin-top: 50px;
		}
		@media (max-width: 768px) {
			.is-grid__item {
				width:100%;
			}
			.responsive-img {
				margin:0 auto;
			}
		}
	}

	&__animals-section {
		padding-bottom: 84px;

		.btn-wrap {
			text-align: center;
			margin-top: 30px;
		}
	}

	&__visit-plan {
		padding:0 90px 15px;
		position: relative;

		.close-info {
			display: block;
			@include fontGillSans;
			color: rgba(#221f1f, .6);
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			text-align: center;
			padding:5px 15px;
			background-color: rgba(#acbecb, .1);
			margin:0;
		}

		@media (max-width: 1199px) {
			padding:0 15px 15px;
		}

	}

	&__tickets-grid {
		@include is-grid(2,50);
		margin-top: 30px;

		.is-grid__item {
			margin-bottom: 0;
			&--cntr{
				margin-left: auto;
				margin-right: auto;
			}
			&-cnt{
				width: 100%;
			}
		}
	}

	&__tickets-section {
		margin-bottom: 30px;
		.title {
			@include fontGillSans;
			color: #221f1f;
			font-size: 13px;
			font-weight: 700;
			line-height: 20px;
			text-transform: uppercase;
			padding-left: 15px;
			margin-bottom: 10px;
		}
	}

	&__tickets-item {
		margin-bottom: 10px;
	}

	&__activities {

		.section {

		}

		.head-title {
			color: #221f1f;
			font-size: 30px;
			font-weight: 700;
			line-height: 30px;
			letter-spacing: -1.5px;
			margin-bottom: 30px;
		}

		.grid-food {
			@include is-grid(3,50);
		}
		.grid-relax {
			@include is-grid(2,50);
		}
		.grid-service {
			@include is-grid(4,26);
		}
	}

	&__faq {
		margin-top: 0px;
		margin-bottom: 95px;
	}

	&__social-section {
		.b-section__social {
			background-color: #ffffff;
			padding-top: 0;
			padding-bottom: 45px;
		}
	}

	&__do-not-miss {
		box-shadow: inset 0 -1px 0 rgba(155, 155, 155, 0.24);

		.title {
			margin-bottom: 40px;
		}

		.row-2 {
			@include is-grid(2,50);
			.is-item__img {
				height:300px;
				object-fit:cover;
				object-position:center;
			}

			@media (max-width: 600px) {
				.is-grid {
					margin-left: -15px;
					margin-right: -15px;
				}
				.is-grid__item {
					margin-left: 15px;
					margin-right: 15px;
					    width: calc(100% / 2 - 30px);
				}
				.is-item__img {
					height:180px;
				}
			}
			@media (max-width: 480px) {
				.is-grid {
					margin-left: -5px;
					margin-right: -5px;
				}
				.is-grid__item {
					margin-left: 5px;
					margin-right: 5px;
					    width: calc(100% / 2 - 10px);
				}
				.is-item__img {
					height:120px;
				}
			}

		}

		.row-3 {
			@include is-grid(3,50);

			.is-item__img {
				height:200px;
				object-fit:cover;
				object-position:center;
			}

			@media (max-width: 600px) {
				.is-grid {
					margin-left: -15px;
					margin-right: -15px;
				}
				.is-grid__item {
					margin-left: 15px;
					margin-right: 15px;
					    width: calc(100% / 3 - 30px);
				}
				.is-item__img {
					height:120px;
				}
			}
			@media (max-width: 480px) {
				.is-grid {
					margin-left: -5px;
					margin-right: -5px;
				}
				.is-grid__item {
					margin-left: 5px;
					margin-right: 5px;
					    width: calc(100% / 3 - 10px);
				}
				.is-item__img {
					height:80px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.is-show-page__tickets-grid .is-grid__item {
		width:100%;
	}
	.is-show-page__activities .grid-food .is-grid {
		margin-left: -10px;
		margin-right: -10px;
	}
	.is-show-page__activities .grid-food .is-grid__item {
		width: calc(100% / 3 - 20px);
		margin-left: 10px;
		margin-right: 10px;
	}
	.is-show-page__activities .grid-service .is-grid {
		margin-left: -10px;
		margin-right: -10px;
	}
	.is-show-page__activities .grid-service .is-grid__item {
		width: calc(100% / 2 - 20px);
		margin-left: 10px;
		margin-right: 10px;
		.is-item__img {
			width:100%;
		}
	}
}
@media (max-width: 577px) {
	.is-show-page__activities .grid-food .is-grid {
		margin-left: -5px;
		margin-right: -5px;
	}
	.is-show-page__activities .grid-food .is-grid__item {
		width: calc(100% / 1 - 10px);
		margin-left: 5px;
		margin-right: 5px;

		.is-item__outer {
			width:100%;
		}
		.is-item__img {
			width:50%;
		}
	}
	.is-show-page__activities {
    /*
		width:300px;
    */
		margin:auto;
	}
	.is-show-page__activities .grid-relax .is-grid {
		margin-left: -10px;
		margin-right: -10px;
	}
	.is-show-page__activities .grid-relax .is-grid__item {
		margin-left: 10px;
		margin-right: 10px;
		width:100%;
	}
	.is-show-page__activities .grid-service .is-grid {
		margin-left: -5px;
		margin-right: -5px;
	}
	.is-show-page__activities .grid-service .is-grid__item {
		width: calc(100% / 2 - 10px);
		margin-left: 5px;
		margin-right: 5px;
	}
}

.fancybox-navigation {

	.fancybox-button--arrow_right {
		background:transparent;
		width: 40px;
	    height: 40px;
	    background-image: url(../img/sp-arrow.png);
	    svg {
			display:none;
		}
	}

	.fancybox-button--arrow_left {
		background:transparent;
		width: 40px;
	    height: 40px;
	    background-image: url(../img/sp-arrow.png);
	    transform: scale(-1);
	    svg {
			display:none;
		}
	}
}

@media (max-width: 480px) {
	.is-show-page__activities {
    /*
		width:290px;
    */
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  width: 100%;
}










