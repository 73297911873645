.is-toggle {
	display: inline-block;
	text-align: right;
	position: absolute;
	right: 0;
	top:-30px;

	&__label {
		color: #221f1f;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
		@include fontGillSans;
		text-align: right;
		margin-bottom: 14px;
	}

	&__wrap {
		display: inline-block;
		position: relative;
		background-color: #ffffff;
		user-select: none;
		transition: .4s;

		&::before {
			content: '';
			position: absolute;
			display: block;
			top:2px;
			left: 2px;
			width: 46px;
			height: 46px;
			border-radius: 50%;
			transition: .4s;
		}
	}
	
	&__container {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 110px;
		height: 50px;
		border-radius: 25px;
		border: 2px solid rgba(172, 190, 203, 0.5);
		cursor: pointer;
	}

	&__switch {
		width: 46px;
		height: 46px;
		position: relative;
		border-radius: 50%;

		svg {
			@include centerIt(absolute);
		}

		.svg, .svg use {
			transition: .4s;
		}
	}

	@media (max-width: 1300px) {
		position: relative;
		top:0;
		text-align: left;
		display: flex;
    	justify-content: flex-end;
    	align-items: center;
    	margin-top: 3rem;

		.is-toggle__label {
			text-align: left;
			margin:0 12px 0 0;
		}
	}
}

.is-toggle  {
	.is-toggle__wrap.full {
		&::before {
			transform: translateX(0);
			box-shadow: 7px 2px 12px rgba(0, 0, 0, 0.08);
		}
		.v-grid .svg {
			stroke:#acbecb;
			fill:#fff;
		}
	}
}

.is-toggle {
	.is-toggle__wrap.grid {
		&::before {
			transform: translateX(60px);
			box-shadow: -7px 2px 12px rgba(0, 0, 0, 0.08);
		}
		.v-grid .svg {
			stroke:#0060ad;
		}
		.v-full .svg use {
			stroke:#acbecb;
    		fill: #fff;
		}
	}
}





