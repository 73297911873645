.is-slider {
	
	&__nav {
		width: 40px;
	    height: 40px;
	    background-image: url("../img/arrow.png");
	    background-size: 8px 12px;
	    background-repeat: no-repeat;
	    background-position: left 17px center;
	    margin-top: -20px;
	    border-radius: 50%;
	    background-color: rgba(#ffffff, .5);
	    transition:background-color .3s ease;

		&--prev {
			left:20px;
			transform:scale(-1);
		}

		&--next {
			right:20px;
		}

		&:hover {
			background-color: rgba(#ffffff, 1);
		}
	}

	&__bullets {
		.swiper-pagination-bullet {
			width:6px;
			height:6px;
			background-color: #cdbdbd;
			opacity: 1;
			transition:background-color .3s ease;
		}
		.swiper-pagination-bullet-active {
			background-color: #0060ad;
		}
	}

	img {
		width:100%;
	}
}

.swiper-container-horizontal>.swiper-pagination-bullets.is-slider__bullets {
	bottom: 15px;
}