.m-header {
	display: block;
	position: fixed;
	z-index: 200;
	top:0;
	left:0;
	right:0;

	&__main {
	    z-index: 10;
	    display: table;
	    box-sizing: border-box;
	    width: 100%;
	    height:60px;
	    background: #ffffff;
	    box-shadow: inset 0 -1px 0 #e5e5e5;
	}

	&__main-content {
		display: table;
		width:970px;
		padding-left: 15px;
		padding-right: 15px;
		margin-left:auto;
		margin-right: auto;
		@media (max-width: 970px) {
			width:100%;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__left {
		display: table-cell;
		vertical-align: top;
		white-space: nowrap;
	}

	&__middle {
		display: table-cell;
		vertical-align: middle;
		width:100%;
	}

	&__right {
		display: table-cell;
		vertical-align: top;
		white-space: nowrap;

		@media (max-width: 420px) {
			white-space: normal;
		}
	}

	&__middle-wrap {
		position: relative;
		width:100%;
	}

	&__menu-btn {
		box-shadow: inset -1px 0 0 #e5e5e5;
	}

	&__main {
		display: table;
	}

	&__logo {
		display: table-cell;
		vertical-align: top;
    padding: 11px 0 8px 18px;
    white-space: nowrap;
    width:100%;
	}

	&__phone {
		display: table-cell;
		vertical-align: top;
		padding:0 40px 0 34px;
		background-image: url('../img/call.svg');
		background-size: 21px 22px;
		background-position: left top 20px;
		background-repeat: no-repeat;
		width:1px;
		white-space: nowrap;

		@media (max-width: 620px) {
			padding:0 20px 0 34px;
		}

		@media (max-width: 577px) {
			width:auto;
			white-space: normal;
			display: block;
			margin:0 auto;
			padding:20px 20px 20px 55px;
			background-size: 36px 38px;
			background-position: left top 18px;
		}
	}

	&__phone-num {
		display: block;
		padding-top: 10px;
		font-size: 16px;
		font-weight: 700;
		color: #293036;
		line-height: 20px;
		letter-spacing: -0.3px;
		white-space: nowrap;
		cursor:pointer;

		@media (max-width: 577px) {
			font-size: 18px;
			line-height: 30px;
			letter-spacing: -0.3px;
			padding-top: 0;
		}
	}

	&__get-call {
		display: block;
		color: rgba(41,48,54,.6);
		@media (max-width: 577px) {
			@include fontGillSans;
			color: rgba(#221f1f, .5);
			font-size: 18px;
			line-height: 20px;
			letter-spacing: -0.45px;
		}
	}

	&__ticket-btn {

	}

	&__dropdown {
    opacity: 0;
    visibility: hidden;
    transform:translateX(-50%) scale(0.4);
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
    position: absolute;
	top:100%;
	left: 50%;
	background-color: #fff;
	width:940px;
    	.is-menu-visible & {
	      opacity: 1;
	      transform: scale(1);
	      visibility: visible;
	      top: 60px;
		  transform:translateX(-50%) scale(1);
		  overflow-y: auto;
		  height: calc(100vh - 60px);
		  background: transparent;
	    }

		@media (max-width: 970px){
			transform:translateX(0) scale(0.4);
			width:100%;
			left:0;
			right:0;
			.is-menu-visible & {
				transform:translateX(0) scale(1);
			}
		}
	}

	&__dropdown-wrap {
		display: flex;
		flex-flow:row nowrap;

		@media (max-width: 577px) {
			flex-flow:column nowrap;
			align-items: center;
			background-color: #f7f7f7;
		}
	}

	&__main-menu {
		flex:1;
		background-color: #fff;
		padding:21px 85px 40px 56px;
		@media (max-width: 710px) {
			padding: 21px 20px 30px 20px;
		}
		@media (max-width: 577px) {
			width:100%;
			padding: 21px 20px 5px 20px;
		}
	}

	&__site-nav {
		background-color: #f7f7f7;
		padding: 40px 15px 54px 27px;
		width:312px;
		display: flex;
		flex-flow:column nowrap;

		.header-top-nav__item + .header-top-nav__item {
			margin-top: 35px;
			@media (max-width: 577px) {
				margin-top: 0;
				text-align: center;
				position: relative;
				&::before {
					content:'';
					position: absolute;
					display: block;
					top:-1px;
					left:-1px;
					right:0px;
					bottom: 0px;
					box-shadow: inset 0 0 0 1px #e5e5e5;
				}
			}
		}
		.header-top-nav__item {
			padding-left: 0;
		}

		@media (max-width: 577px) {
			padding: 0;
			width:100%;
		}

	}

	&__menu2 {
		flex:1;

		@media (max-width: 577px) {
			display: flex;
			flex-flow:row wrap;
			padding: 0 20px;
		}
	}

	&__site-nav-item {
		display: block;

		.header-top-nav__text {
			line-height: 25px;
		}

		@media (max-width: 577px) {
			margin:0;
			padding:17px 0;
			width:100%;
		}
	}

	&__langs {
		.langs__item {
			margin:0;
			height:30px;
		}

		.langs__item + .langs__item {
			margin-left: 30px;
		}

		.langs__flag {
			width:42px;
			height:30px;

			&--ru {
				background-image: url("../img/m-ru.png");
			}

			&--en {
				background-image: url('../img/m-en.png');
			}

			&--ch {
				background-image: url('../img/m-ch.png');
			}
		}
		.langs__inner {
			@media (max-width: 577px) {
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 20px 0;
				margin-bottom: 50px;
			}
		}
	}

	&__search {
		width:100%;
		line-height: 60px;
	}

	&__search-control {
		width:220px;
	}

	&__search-btn {
		position: absolute;
		display:block;
		top:0;
		right:0;
		bottom:0;
		width:60px;
		background-image: url('../img/i-search.svg');
		background-size: 22px 22px;
		background-repeat: no-repeat;
		background-position: center;
		background-color: transparent;
		border:none;
	}

}

.menu-ovl {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.is-menu-visible + .menu-ovl {
  opacity: .55;
  visibility: visible;
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {}

.header.headroom--unpinned .header__logo img {
    height: 30px;
}
.page__line-at-head-section .col-26 {
    width: 26%;
    float: left;
    min-height: 1px;
}
@media (max-width: 991px) {
    .page__line-at-head-section .col-26 {
        width: 50%;
        margin-bottom: 10px;
    }
}
@media (max-width: 480px) {
    .page__line-at-head-section .col-26 {
        width: 100%;
    }
}
.page__line-at-head-section .col-24 {
    width: 24%;
    float: left;
    min-height: 1px;
}
@media (max-width: 991px) {
    .page__line-at-head-section .col-24 {
        width: 50%;
        margin-bottom: 10px;
    }
}
@media (max-width: 480px) {
    .page__line-at-head-section .col-24 {
        width: 100%;
    }
}












