.contacts {

	&__inner {
		display: flex;
		justify-content: center;

		@media (max-width: 710px) {
			flex-flow:column nowrap;
			align-items: center;

			.contacts__col + .contacts__col {
				margin-top: 30px;
			}
		}
	}

	&__col {
		min-width: 200px;
		margin: 0 25px;

		&--center {
			text-align: center;
		}

		@media (max-width: 991px) {
			margin:0 15px;
		}

		@media (max-width: 710px) {
			margin:0;
		}
	}

	&__icon-wrap {
		display: inline-block;
		min-height: 48px;
	}

	&__icon {
		display: inline-block;
	}

	&__ttl {
		display: block;
		margin:4px 0 10px 0;
		font-size: 18px;
		font-weight: 700;
		line-height: 30px;
		color: #293036;
		letter-spacing: -0.3px;
		white-space: nowrap;
	}

	&__longtitle {
		@include fontGillSans;
		margin:0;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		color: #221f1f;
		letter-spacing: -0.45px;
	}

	&__nav {
		display: block;
		margin-top: 20px;
	}

	&__nav-list {
		display: block;
		text-align: center;
	}

	&__nav-item {
		display: block;
		margin-bottom: 5px;
		@media (max-width: 991px) {
			margin-bottom: 15px;
		}
	}

	&__nav-item-text {
		@include fontGillSans;
		color: #7a756a;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
	}

	&__button {
		width:100%;
		display: block;

		&.bay-ticket-btn {
			margin-top: 20px;
		}
	}
}
