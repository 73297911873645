.b-section {
	display: block;
  	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	&--blue-gradient {
		background-color: rgba(0, 96, 173, 0.9);
		background-image: linear-gradient(to top, #0058a2 0%, #005ba4 11%, #0064ab 31%, #0071b6 39%, #0085c7 52%, #009edf 88%, #00aeef 100%);
	}

	&--blue-reverse-gradient {
		&::before {
			content:'';
			display: block;
			position: absolute;
			z-index: 0;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background-color: rgba(#0060ad, .8);
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
		}

		.container {
			position: relative;
			z-index: 1;
		}
	}

	&__lg-inner {
		padding-left: 90px;
		padding-right: 90px;

		@media (max-width: 1469px) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&--gray-bg {
		background-color: #f7f7f7!important;
	}

	&__title {
		font-size: 40px;
		font-weight: 700;
		line-height: 1;
		letter-spacing: -1px;
		color: #221f1f;
		text-align: center;
		margin: em(70,40) auto 0;
		max-width: 600px;

		&.h1 {
			font-size: 60px;
			line-height: 1.34;
			font-weight: 700;
			letter-spacing: -2px;
			margin: 0 0 60px 0;
			max-width: none;

			@media (max-width: 737px) {
				font-size: 36px;
				margin: 0 0 50px 0;
			}
		}

		&--color-white {
			color:#ffffff;
		}

		&--left {
			text-align: left;
			margin-left: 0;
		}

		.marker {
			color:#ffffff;
			background-color: #f5a523;
			background-image: radial-gradient(circle 100px at 51.05% 50%,rgba(255,255,255,.15) 0,rgba(0,0,0,.03) 100%);
		}

		@media (max-width: 991px) {
			font-size: 30px;
		}

		@media (max-width: 768px) {
			max-width: none;
		}

		@media (max-width: 577px) {
			font-size: 20px;
			margin-top: 50px;
		}
	}

	&__longtitle {
		@include fontGillSans;
		color: #7a7979;
		font-size: 24px;
		font-weight: 400;
		line-height: 28px;
		margin-top: 30px;
		margin-bottom: 20px;
		text-align: center;

		&--color-white {
			color:#ffffff;
		}

		&--left {
			text-align: left;
		}

		@media (max-width: 768px) {
			font-size: 20px;
			line-height: 24px;
			margin-top: 20px;
			margin-bottom: 15px;
		}
	}

	&__text {
		@include fontGillSans;
		font-size: 18px;
		line-height: 25px;
		color: #7a7979;
		font-weight: 400;
		letter-spacing: -0.5px;
		margin:20px 0;
    +.page__block {
      padding-top: 0;
    }

		&--center {
			text-align: center;
		}

		&--description {
			margin-top: 30px;
			margin-bottom: 25px;
		}

		&--color-lightgray {
			color: #acbecb;
		}

		&--color-dark {
			color: #2c2929;
		}

		@media (max-width: 991px) {
			font-size: 16px;
		}
	}

	&__hero {
		position: relative;
		display: block;
		width:100%;
		margin-top: 89px;

		@media (max-width: 1199px) {
			margin-top: 59px;
		}
	}

	&__padding {
		padding:70px 0;
		@media (max-width: 991px) {
			padding:50px 0;
		}
	}

	&__promo {
		background-color: #{map-get($palette, light-gray)};
		padding: 40px 20px;

		@media (max-width: 1199px) {
			padding:20px 0;
		}
		@media (max-width: 577px) {
			padding:20px 0 36px;

			.container {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&__about {
		background-color: rgba(0, 95, 173, 0.9);
        background-image: linear-gradient(to top,#0058a2 0,#005ba3 0%,#0063ab 31%,#0071b6 39%,#0085c7 100%,#009edf 88%,#00aeef 100%);
    @media (max-width: 577px) {
      background: rgba(0, 96, 173, 0.9);
    }
	}

	&__events {
		padding-top: 52px;
		padding-bottom: 70px;
		background-color: #005fad;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
        overflow: hidden;
        &.interactive-clock {
        	// overflow: auto;
        }

        @media (max-width: 767px) {
        	padding-bottom: 50px;
        	padding-top: 50px;
        }
	}

	&__social {
		background-color: #f7f7f7;
		padding-bottom: 45px;

		@media (max-width: 991px) {
			padding-bottom: 30px;
		}
	}

	&__social-tags {
		margin-top: 14px;
		margin-bottom: 31px;
		text-align: center;

		.tags__item {
			font-size: 12px;
			font-weight: 700;
		}
	}

	&__social-links {
		display: flex;
		justify-content: center;
		margin-bottom: 25px;

		@media (max-width: 991px) {
			margin-bottom: 45px;
		}
	}

	&__contacts {
		padding-top: 30px;
	}

	&__breadcrumbs {
		position: absolute;
		z-index: 10;
		top:150px;
		left:115px;
		width:auto;
		@media (max-width: 1199px) {
			top:120px;
			left:10px;
		}

		@media (max-width: 768px) {
			left:50px;
		}
	}

	&__page-nav {
		display: block;
		width:100%;
		position: relative;
	}

	&__page-inner {
		display: block;
		width:1000px;
		margin:0 auto;
    overflow: hidden;
		@media (max-width: 1199px) {
			width:100%;
		}
		@media (max-width: 768px) {
			overflow: hidden;
		}
	}

	&__page-inner2 {
		padding-bottom: 50px;
	}

	&__page-inner3 {
		display: block;
		margin:0 auto;
		max-width: 800px;
	}

	&__show-snippets-container {
		max-width: 1220px;
		display: block;
		margin:0 auto;
		@include is-grid(2,50);

		.is-grid {
			justify-content: center;
		}
		@media (max-width: 800px) {
			.is-grid {
				margin-left: 0;
				margin-right: 0;
			}
			.is-grid__item {
				width:100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	&__head-table {
		margin-bottom: 49px;
	}

	&__discount-banner {
		display: block;
		width:100%;
		margin-top: 40px;
		padding:em(110) em(20) em(15) em(100);
		min-height: em(320);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;

		.title {
			display: block;
			max-width: 500px;
			color:#fff;
			font-size: em(30);
			font-weight: 700;
			line-height: 1;
			letter-spacing: em(-1.5,30);
			margin:0;
		}

		.description {
			display: block;
			max-width: 500px;
			font-size: em(24);
			margin:em(20,24) 0;
			font-weight: 400;
			line-height: 1.16;
			@include fontGillSans;
			color:#fff;
		}
	}

	&__info-banner {
		display: block;
		width:100%;
		padding:em(92) em(20) em(15) em(100);
		min-height: em(340);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;

		.title {
			@include fontGillSans;
			color:#fff;
			font-size: 13px;
			text-transform: uppercase;
			line-height: 20px;
			font-weight: bold;
			margin:0;
		}

		.description {
			font-size: em(24);
			margin:em(30,24) 0;
			font-weight: 400;
			line-height: em(28,24);
			@include fontGillSans;
			color:#fff;
			letter-spacing: -0.4px;
		}

		@media (max-width: 768px) {
			padding:80px 15px 15px 20px;
		}
	}

	&__table {
		display: block;
		position: relative;

		&--knowledge-center {
			margin-top: 30px;
		}
	}

	&__e-list {
		@include is-grid(4, 40);
		margin-bottom: 30px;
		@media (max-width: 768px) {
			.is-grid {
				margin-left: -10px;
				margin-right: -10px;
			}
			.is-grid__item {
				margin:0 10px 10px 10px;
				width: calc(100% / 4 - 20px);
			}
		}

		@media (max-width: 480px) {
			.is-grid__item {
				width: calc(100% / 2 - 20px);
			}
		}
	}

	&__fw-carousel {
		padding-bottom: 70px;

		.showing-up-text {
			display: block;
			margin:0 auto;
			max-width: 600px;
			text-align: center;
		}
	}

	&__page-404 {
		padding-top: 60px;

		.logo {
      display:block;
      margin:0 auto;
      width:242px;
      height:50px;
      background-image:url('../img/logo_new_inversed.svg?v=2');
      background-size: 242px 50px;
      background-repeat: no-repeat;
		}
		@media (max-width: 414px) {
			font-size: 6px;
		}
	}
}


@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .b-section__breadcrumbs {
    top: 80px;
    left: 20px;
    right: 20px;
  }

  .b-section__page-inner2 {
    padding-bottom: 25px;
  }
  .b-section__fw-carousel {
    padding-bottom: 35px;
  }
}















