.is-sl-nav {
	position: absolute;
	top:50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	height: 0;
	
	&__arr {
		position: absolute;
		display: block;
		top:-21px;
		width: 42px; height: 42px;
		cursor:pointer;

		&::before {
			content:'';
			display: block;
			position: absolute;
			top:0;
			left: 0;
			width: 42px;
			height: 42px;
			opacity: 0;
			transition: opacity .4s cubic-bezier(.35,.96,1,1.04);
		}

		&:hover,&:focus {
			&::before {
				opacity: 1;
			}
		}

		&--left {
			left: 20px;
    		background: url('../img/nav_sprites.png') -10px -10px;

    		&::before {
    			background: url('../img/nav_sprites.png') -72px -10px;
    		}
    		@media (max-width: 768px) {
    			left:5px;
    		}
		}

		&--right {
			right: 20px;
			background: url('../img/nav_sprites.png') -10px -72px;

			&::before {
				background: url('../img/nav_sprites.png') -72px -72px;
    		}
    		@media (max-width: 768px) {
    			right: 5px;
    		}
		}
	}
}

.is-gallery__main-screen {
	.is-sl-nav {
		height: 100%;
		top:0;
		bottom: 0;
		transform: translateY(0);
	}
	.is-sl-nav__arr {
		top:0;
		bottom: 0;
		width: 14%;
		height: 100%;
		background: none;

		&:after {
			content:'';
			display: block;
			position: absolute;
			top:0;
			left: 0;
			width: 42px;
			height: 42px;
			transition: opacity .4s cubic-bezier(.35,.96,1,1.04);
		}

		&:before, &:after {
			top:50%;
			margin-top: -21px;
		}

		&--right {
			right: 0;
			&:after {
				background: url('../img/nav_sprites.png') -10px -72px;
			}
			&:before, &:after {
				right: 20px;
				left: auto;
				@media (max-width: 768px) {
					right: 5px;
				}
			}
		}
		&--left {
			left:0;
			&:after {
				background: url('../img/nav_sprites.png') -10px -10px;
			}
			&:before, &:after {
				left:20px;
				@media (max-width: 768px) {
					left: 5px;
				}
			}
		}
	}
}
