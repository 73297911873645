.instafeed {
	@media (max-width: 1469px) {
		font-size: 9.5px;
	}

	@media (max-width: 1199px) {
		font-size: 7.8px;
	}

	@media (max-width: 991px) {
		font-size: 14px;
	}

	@media (max-width: 700px) {
		&.swiper-container {
			margin: 0 -15px;
		}
	}

	&__wrap {
		@media (min-width: 992px) {
			padding: em(5) em(70) em(7);
			display: flex;
			flex-flow:row wrap;
			justify-content: center;	
		}

		@media (max-width: 991px) {
			padding: 5px 0 7px;
		}
	}

	&__item {
		@media (min-width: 992px) {
			width: em(220);
			height: em(220);
			display: inline-block;
			margin: em(20);
			border-radius: 3px;
			overflow:hidden;	
		}
	}

	&__image {
		width:100%;
		height:100%;
		object-fit:cover;
		object-position: center;
		transition: all .45s cubic-bezier(.39,.575,.565,1)!important;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		transform-style: preserve-3d;
		@media (min-width: 992px) {
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}