.is-page-news {

	.breadcrumbs__item-text {
		color: #221f1f;
	}

	a.breadcrumbs__item-text {
		&:hover, &:focus {
			color:#f5a623;
		}
	}
	.breadcrumbs__item::before {
		color: #221f1f;
	}

	&__detail {
		padding-top: 120px;
	}

	&__list {
		padding-top: 70px;
		@include is-grid(3,50);
	}

	&__similar-news {
		padding-bottom: 20px;
		padding-top: 40px;

		@media (max-width: 767px) {
		    .is-grid {
		    	margin-left: 0;
		    	margin-right: 0;
		    }
		    .is-grid__item {
		    	width:100%;
		    	margin-left: 0;
		    	margin-right: 0;
		    	.is-news-item {
		    		width:290px;
		    		margin-left: auto;
		    		margin-right: auto;
		    	}
		    }
		}
	}

	&__banner {
		display: block;
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: center top;
	    background-repeat: no-repeat;
	    padding:123px 100px 74px 100px;
	    color:#ffffff;

	    .title {
	    	@include fontGillSans;
	    	font-size: 25px;
	    	line-height: 28px;
	    	letter-spacing: -0.4px;
	    	display: block;
	    	margin-bottom: 20px;
	    	max-width: 500px;
	    }

	    .form {
	    	display: block;
	    	margin-top: 20px;
	    	font-size: 14px;
	    }
	    @media (max-width: 660px) {
	    	padding:123px 20px 74px 20px;
	    }
	}

	&__pager {
		display: block;
		margin:35px 0 70px;
	}

	&__top-offset {
		display: block;
		width:100%;
		height:240px;
		@media (max-width: 991px) {
			height:215px;
		}
	}

	&__main-text {
		padding: 30px 0 60px;
	}

	&__promo-banner {
		padding: 100px 20px 106px 100px;
		margin:0;
		@media (max-width: 768px) {
			padding: 20px 20px 106px 20px;
		}
	}


	&__date-cnt{
		position: relative;
	}
	&__date{
		position: absolute;
		top: -34px;
		color: #acbecb;
		font-family: "Gill Sans";
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
	}
}


@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-page-news {
    &__list {
      padding-top: 35px;
      @include is-grid(1,40);
    }
    &__pager {
      margin-bottom: 35px;
    }
    &__main-text {
      padding-top: 0;
    }
  }
}






