.loader {
    background: none repeat scroll 0 0 #ffffff;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.loader_inner {
    background-image: url('../img/page_preloader.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    height: 60px;
    width: 60px;
    margin-top: -30px;
    margin-left: -30px;
    left: 50%;
    top: 50%;
    position: absolute;
}
