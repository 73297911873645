.multi-tickets-list {
    background-color: #0060ad;
    border-radius: 3px;

    &__bottom-link {
    	margin-left: 82px;
    	padding:0 0 20px;
    }

    .is-ticket--past {
    	opacity: .4;
    	.is-ticket__outer {
    		background-color: #0060ad;
    	}
    	.is-ticket__title {
    		color:#ffffff;
    	}
    	.is-ticket__age {
    		color:#ffffff;
    	}
    	.is-ticket__time {
    		background-color: #ffffff;
    		color: #0060ad;
    	}
    	
    }

	.is-show-page__tickets-item {
		margin-bottom: 0;
	}

	.is-ticket__outer {
		border:0;
	}
    .is-ticket--past:not(.is-ticket--all-day) .is-ticket__outer {
        border: none;
    }
}