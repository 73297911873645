.clock {

	&__wrap {
		padding-left: em(225);

		@media (max-width: 1469px) {
			padding-left: 80px;
		}

		@media (max-width: 1199px) {
			padding-left: 0;
		}
	}

	&__title {
		color:#fff;
		font-size: em(30);
		font-weight: 700;
		line-height: 1;
		letter-spacing: -1.5px;
		text-align: center;
		margin-bottom: 17px;
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;

		@media (max-width: 577px) {
			font-size: 20px;
		}
	}

	&__time-circle-wrap {
		overflow:hidden;
		width: auto;
    	height: 355px;
    	position: relative;
    	@include vw(12px);
    	@media (max-width: 577px) {
			@include vw(18px);
			height: auto;
		}
		@media (max-width: 480px) {
			@include vw(22px);
		}
	}

	&__inner {
		width: em(399);
		text-align: center;
		@media (max-width: 991px) {
    		width:auto;
    	}
	}

	&__time-circle-mask {
		width: em(302);
		height: em(302);
		display: inline-block;
		position: absolute;
		top: em(29);
		left: em(29);
		background-image: url('../img/active.png');
		background-size: contain;
		background-repeat: no-repeat;
		transform: rotate(-60deg);
		transition: transform .3s ease;
	}

	&__time-circle {
		width: em(356);
		height: em(356);
		display: inline-block;
		position: relative;
		background-image: url('../img/time-circle.svg');
		background-size: contain;
		background-repeat: no-repeat;

		.clock__btns {
			position: relative;
			display: block;
			width: em(356);
			height: em(356);
		}
	}

	&__time-sector {
		position: absolute;
	    top: 0;
	    left: 0;
	    overflow: hidden;
	    display: block;
	    width: 50%;
	    height: 50%;
	    font-size: 1.5em;
	    transition: all .3s ease .3s;
        transform-origin: 100% 100%;
        background:transparent;

    	@for $i from 1 through 12 {
    		&:nth-child(#{$i}) {
    			
    			transform: rotate(#{$i*30deg}) skew(60deg);

    			.sector-inner {
    				border: none;
	    			padding: 0;
    				background-color: rgba(#ffffff, 0);
    			}
    		}
    	}

    	.sector-inner {
    		display: block;
		    font-size: 1.18em;
		    height: 14em;
		    width: 14em;
		    position: absolute;
		    bottom: -7em;
		    right: -7em;
		    border-radius: 50%;
		    text-decoration: none;
		    color: #fff;
		    padding-top: 1.8em;
		    text-align: center;
		    transform: skew(-60deg) rotate(-75deg) scale(1);
	        transition: all 0.3s ease;

	        &:hover {
	        	background-color: rgba(#ffffff, 0);
	        }

	        .time {
	        	display: none;
	        	font-size: 14px;
	        	transform: skew(0deg) rotate(13deg) scale(1);
    			position: absolute;
    			top:-25px;
	        }
    	}
	}

	&__button-wrap {
		margin-top: em(52);
		margin-bottom: 10px;

		@media (max-width: 991px) {
    		margin:25px 0 20px;
    	}
    	@media (max-width: 767px) {
    		margin:40px 0 10px;

    		.btn {
    			width:280px;
    		}
    	}
	}

	&__all-events {
		text-align: center;

		.link {
			color:#fff;
			@include fontGillSans;
			font-size: 13px;
			font-weight: 400;
			line-height: 32px;

			@media (max-width: 991px) {
				font-size: 16px;
				line-height: 30px;
			}
		}
	}
}