.is-page-contacts {
	
	&__title {
		font-size: 60px;
		margin-top: 0;
		margin-bottom: 50px;

		@media (max-width: 991px) {
			font-size: 40px;
		}
	}

	&__map {
		height:500px;
		margin-top: 70px;
		margin-bottom: 70px;

		@media (max-width: 991px) {
			margin:50px auto;
			height:420px;
		}
	}

	&__info {
		@include is-grid(3,24);

		.is-grid__item {
			margin-bottom: 8px;
		}

		@media (max-width: 991px) {
			.is-grid__item {
				width:calc((100% - 72px) / 2);
				margin-bottom: 50px;
			}

			.is-grid__item:nth-child(3) {
				width:calc((100% - 72px) / 1);

				.contact-column {
					overflow:auto;
				}

				.section {
					display: inline-block;
					padding-right: 20px;
					width:50%;
					float: left;
					margin-top: 20px;
				}
			}
			.is-style h4 {
				font-size: 20px;
			}
		}
		@media (max-width: 660px) {
			.is-grid__item, .is-grid__item:nth-child(3) {
				width:calc((100% - 75px) / 1);
				margin-bottom: 50px;

				.section {
					display: block;
					padding-right: 0;
					width:100%;
					float: none;
					margin-top: 20px;
				}
			}
		}
	}

	.contact-column {
		padding-right: 50px;

		.title {
			margin-top: 0;
		}

		.section {
			@include fontGillSans;

			& + .section {
				margin-top: 20px;
			}
		}

		.subtitle {
			margin-bottom: 5px;
			color: #221f1f;
			font-size: 16px;
			font-weight: 600;
		}

		.text {
			color: #7a7979;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: -0.5px;

			&.phone:hover {
				color: #0060ad;
			}
		}
		.small-text {
			font-size: 14px;
			line-height: 20px;
			letter-spacing: -0.4px;
			color: #221f1f;
		}

		.is-link {
			font-size: 18px;
			line-height: 25px;
		}
		@media (max-width: 1199px) {
			padding-right: 30px;
		}

		@media (max-width: 991px) {
			padding-right: 0;
		}
	}
}



















