.is-page-404 {
	background-color: rgba(0, 96, 173, 0.9);

	&__main {
		margin-top: em(106);
		margin-bottom: em(103);
		text-align: center;
	}

	&__num {
		@include vw(200px);
		text-align: center;
		color: #ffffff;
		line-height: 1;
	}

	&__title {
		color: #ffffff;
		@include vw(40px);
		font-weight: 700;
		line-height: 1;
		letter-spacing: -1px;
		margin:em(39,40) 0 em(30,40);
	}

	&__text {
		@include fontGillSans;
		color: #acbecb;
		font-size: 18px;
		line-height: 25px;
		margin-top: 30px;
		padding: 0 15px;
	}

	&__button {
		margin-top: em(70);
	}

	&__link {
		display: inline-block;
		@include fontGillSans;
		color: #ffffff;
		font-size: 13px;
		line-height: 20px;
		margin-top: 22px;

		&:hover, &:focus {
			color:#7fafd6;
		}
	}
}