.is-page-lectures {
	
	&__fw-carousel {

		.btn-wrap {
			margin-top: 20px;
		}
	}

	.b-section__social {
		background-color: #ffffff;
	}
}