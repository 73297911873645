.sch-event {
	position: absolute;
	left: 0;
	z-index: 2;
	display: block;
	width: auto;
	min-width: 160px;
	padding: 20px 20px 0 0;
	@include fontGillSans;

	&__duration-bar {
		position: absolute;
		display: block;
		top: 0; left: 0; right: 0;
		height: 5px;
		background-color: #0060ad;
	}
	
	&__price {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 4px;
		color: #7a7979;
		padding-left: 2px;
	}

	&__title {
		color: #221f1f;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		margin: 4px 0 10px;
		white-space: normal;
		padding-left: 2px;
	}

	&__age-limit {
		font-size: 12px;
		line-height: 20px;
		text-transform: uppercase;
		color: #acbecb;
		letter-spacing: 0.5px;
	}

	&__meta {
		margin-top: 10px;
		padding-left: 2px;
	}

	&__time {
		border-radius: 3px;
		background-color: #0060ad;
		display: inline-block;
		vertical-align: middle;
		padding: 0 5px;
		text-align: center;
		width: auto;
		min-width: 49px;
		margin-right: 15px;
		color: #ffffff;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: -0.39px;
	}

	&__sm-text {
		font-size: 13px;
		line-height: 20px;
		color: #221f1f;
		display: inline-block;
		vertical-align: middle;
		white-space: nowrap;
	}
}

// mods 
.sch-event--past:not(.sch-event--all-day) {
	color: rgba(34,31,31,.4);
	.sch-event {
		&__duration-bar, &__time {
			background-color: rgba(#9b9b9b, .5);
		}
		&__title, &__sm-text, &__age-limit {
			color: rgba(#221f1f, .6);
		}
	}
}