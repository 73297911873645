.promo {
	position: relative;
  &__inner {
    position: relative;
  }
	@media (max-width: 1469px) {
		font-size: 10px;
	}

	@media (max-width: 1199px) {
		font-size: 8.5px;
	}
  @media (max-width: 577px) {
    padding: 0 20px 30px 20px;
  }
  .swiper-container {
    box-shadow: 10px 0 10px -10px rgba(185,185,185,.75);
    @media (max-width: 557px) {
      box-shadow: 0;
      background-color: #ffffff;
    }    
  }
  .swiper-wrapper {
    align-items: stretch;
    padding-bottom: 15px;
    @media (max-width: 577px) {
      padding-bottom: 0;
    }
  }
  .swiper-slide {
    width: 280px;
    background: #ffffff;
    box-shadow: 2px 0px 15px 0px rgba(185,185,185,0.75);
    &:hover {
      .promo__title {
        color: #0060ad;
      }
    }
    @media (max-width: 1470px) {
      width: 230px;
    }
    @media (max-width: 557px) {
      height: 100%;
      box-shadow: none;
    }
    
  }
	.swiper-pagination {
		left:50%;
		transform:translateX(-50%);
		top:100%;
		width:100%;
    @media (max-width: 578px) {
      margin-top: 18px;
    }
		.swiper-pagination-bullet {
			margin: 0 8px;
			width:10px;
			height:10px;
		}

		@media (min-width: 578px) {
			display: none;
		}
	}
  .swiper-button-next, .swiper-button-prev {
    top:50%;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    background-image: url("../img/arrow.png");
    background-size: 8px 12px;
    background-repeat: no-repeat;
    background-position: left 17px center;
    border-radius: 50%;
    background-color: rgba(#ffffff, .5);
    transition:background-color .3s ease, opacity 0.3s, visibility 0.3s;
    @media (max-width: 577px) {
      display: none;
    }

    &:hover {
      background-color: rgba(#ffffff, 1);
    }
  }

  .swiper-button-next {
    right: -60px;
  }

  .swiper-button-prev {
    transform:rotate(180deg);
    left:-60px;
  }
  .swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }
	&__outer-link {
		display: block;
		cursor: pointer;
	}

	&__snippet {
		background-color: #fff;

		&:hover, &:focus {
			.promo__link {
				color:#{map-get($palette, accent-blue)};
				left:4px;
			}
		}

		@media (max-width: 991px) {
			width:auto;
		}
	}

	&__content {
    padding: 24px;
    @media (max-width: 1470px) {
      padding: 15px;
    }
    @media (max-width: 577px) {
      padding: 24px;
    }
	}

	&__img-wrap {
    @media (max-width: 577px) {
      min-height: 100%;
    }
	}

	&__image {
		margin-left: auto;
		width: 100%;
    height: 280px;
		object-fit:cover;
		object-position:0;
    @media (max-width: 1470px) {
      height: 230px;
    }
    @media (max-width: 577px) {
      height: auto;
    }
	}

	&__tags {
		height: 34px;
    	display: block;
    	overflow: hidden;

    	@media (max-width: 577px) {
    		.tags__item {
    			font-size: 8px;
    		}
    	}
	}

	&__title {
		font-size: 16px;
		color: #221f1f;
		line-height: 1.3;
		font-weight: 700;
		letter-spacing: -0.4px;
		margin:0;
    transition: color 0.3s;
	}

	&__longtitle {
		@include fontGillSans;
		color: rgba(#293036, .6);
		font-weight: 400;
		margin: 16px 0 0 0;
		letter-spacing: -0.44px;
		display: block;
		overflow:hidden;
		@include text-whith-ellipsis(16px, 1.375, 3);

		// @-moz-document url-prefix() {
		//     overflow: hidden;
		//     position: relative;
		//     &::before {
		//       background: transparent;
		//       bottom: 0;
		//       position: absolute;
		//       right: 0;
		//       float: right;
		//       content: '\2026';
		//       margin-left: -3rem;
		//       width: 3rem;
		//     }
		//     &::after {
		//       content: '';
		//       background: transparent;
		//       position: absolute;
		//       height: 50px;
		//       width: 100%;
		//       z-index: 1;
		//     }
  // 		}

	}

	&__link {
		@include fontGillSans;
		font-size: 16px;
		color:#{map-get($palette, accent-blue)};
		font-weight: 400;
		line-height: 1.5;
		transition: all .45s cubic-bezier(.39,.575,.565,1);
		position: relative;
		left:0;
		user-select:none;
	}
  &__show-all {
    text-align: center;
    padding-top: 30px;
    @media (max-width: 577px) {
      display: none;
    }
  }
  &__show-all-btn {
    font-family: 'Open Sans';
    font-size: 12px;
    color: #0060ad;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .promo {
    &__snippet {
      flex-direction: column-reverse;
    }
  }
  .promo__img-wrap {
    width: 100%;
  }
  .promo__content-inner {
    position: static;
    transform: none;
  }
  .promo__image {
    width: 100%;
  }
}
