.is-ticket {
	@include fontGillSans;
	display: block;
	position: relative;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.5px;
	color:rgba(#ffffff, 0.6);
	
	&__outer {
		border-radius: 3px;
		border: 1px solid #0060ad;
		background-color: #0060ad;
	}

	&__inner {
		padding: 20px 20px 15px 20px;
	}

	&__wrap {
		display: flex;
	}

	&__left {

	}

	&__right {
		padding-left: 20px;
	}

	&__row {
		display: flex;
		margin-bottom: 5px;
	}

	&__title {
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
	}

	&__age {
		margin-left: 5px;

	}

	&__link {
		color: #f5a623;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: -0.39px;
		display: inline-block;
		position: relative;
		padding-right: 16px;
		-webkit-font-smoothing:auto;
		&::after, &::before {
			content:'';
			position: absolute;
			display: block;
			width:6px;
			height:8px;
			background-size: 5px 8px;
			background-repeat: no-repeat;
			top:7px;
			right:4px;
			transition:all .3s ease;
		}

		&::after {
			background-image: url('../img/t-arr.png');
			opacity:1;
		}

		&::before {
			background-image: url('../img/t-arr-h.png');
			opacity: 0;
		}

		&:hover,&:focus {
			color: #ffffff;
			&::after {
				right: 0;
				opacity: 0;
			}
			&::before {
				right: 0;
				opacity:1;
			}
		}
	}

	&__show-on-plan {
		color:#ffffff;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.39px;

		&:hover,&:focus {
			color: #f5a623;
		}
	}

	&__price {
		margin-left: 6px;
		font-size: 14px;
	}

	&__time {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		padding:0 5px;
		border-radius: 3px;
		color: #0060ad;
		text-align: center;
		background-color: #ffffff;
	}

	&__timing {
		color:rgba(#ffffff, .6);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.39px;
		margin-top: 5px;
	}

	&__text {
		margin-top: 5px;
		line-height: 1.2;
	}
}
// mods
.is-ticket--past:not(.is-ticket--all-day) {
	color: rgba(#221f1f, 0.4);

	.is-ticket__title {
		color: rgba(#221f1f, 0.6);
	}

	.is-ticket__outer {
		border: 1px solid rgba(34, 31, 31, 0.1);
		background-color: #ffffff;
	}

	.is-ticket__time {
		background-color: rgba(#9b9b9b, 0.5);
		color:#ffffff;
	}
	.is-ticket__show-on-plan {
		color: #0060ad;
		&:hover {
			color: #7fafd6;
		}
	}
}
.multi-tickets-list .is-ticket--past .is-ticket__show-on-plan {
	color: #ffffff;
	&:hover {
		color: #f5a623;
	}
}

.is-ticket--no-events {
    border: 1px solid rgba(34, 31, 31, 0.1);
    color: #221f1f;
    font-size: 18px;
    &.multi-tickets-list {
		background-color: #ffffff;
		padding: 5px 15px;
    }
}












