.is-lesson {
	
	&__head {
		position: relative;
		width: 100%;
		height: 250px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 4px;

		&::before {
			content: '';
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, #000 100%);
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    height: 55%;
		    z-index: 0;
		    opacity: 0.5;
		}
	}

	&__name-row {
		display: flex;
		position: absolute;
		bottom:0;
		left:0;
		margin-bottom: 20px;
		align-items:flex-start;
		padding: 0 20px;
	}

	&__name {
		font-size: 16px;
	    font-weight: 700;
	    line-height: 20px;
	    color: #fff;
	    margin-right: 20px;
	}

	&__age {
		font-size: 14px;
	    font-weight: 400;
	    line-height: 20px;
	    padding: 0 5px;
	    -webkit-border-radius: 3px;
	    border-radius: 3px;
	    color: #0060ad;
	    text-align: center;
	    background-color: #fff;
	    min-width: 32px;
	}

	&__main-txt {
	    font-size: 16px;
	    margin-top: 10px;
	    margin-bottom: 20px;
	    color:#7a7979;
	    @include fontGillSans;
	}

	&__info {
	}

	&__info-row {
		display: flex;
		margin-bottom: 18px;
	}

	&__info-item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;


		& + .is-lesson__info-item {
			margin-left: 20px;
		}
		.icon {
			width: 32px;
			min-width: 32px;
			margin-right: 5px;
			height: 32px;
			display: block;
			vertical-align: top;
			
			svg {
			    width: 100%;
    			height: 100%
			}
		}

		.label {
			display: inline-block;
			vertical-align: top;
			@include fontGillSans;
			font-size: 16px;
	    	padding-top: 5px;
		}
	}

	&__button-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;
	}

	&__button-label {
		display: inline-block;
		vertical-align: middle;
		margin-left: 30px;
	}
}







