.is-link, .is-style .is-link {
	color: #0060ad;
	@include fontGillSans;
	font-size: 16px;
	cursor: pointer;
	transition: color .25s ease-out;

	&:hover, &:focus {
		color: #7fafd6;
	}

	&--sm {
		color: #221f1f;
		font-size: 13px;
		&:hover, &:focus {
			color: #7fafd6;
		}
	}

	&--primary {
		position: relative;
		padding-right: 12px;
		&::after {
			content: '';
			position: absolute;
			display: block;
			top:8px;
			right:0;
			width: 5px;
			height:5px;
			border-bottom: 1px solid #0060ad;
			border-right: 1px solid #0060ad;
			transform:rotate(-45deg);
		}

		&:hover, &:focus {
			&::after {
				border-bottom: 1px solid #7fafd6;
				border-right: 1px solid #7fafd6;
			}
		}
	}

	&--dashed {
		display: inline-block;
		border-bottom: 1px dashed #0060ad;
		&:hover, &:focus {
			color: #7fafd6;
			border-bottom: 1px dashed #7fafd6;

		}
	}

	&--extra {
		color: #f5a623;
		font-size: 14px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: 0.4px;
		position: relative;
		padding-right: 16px;
		-webkit-font-smoothing:auto;
		&::after, &::before {
			content:'';
			position: absolute;
			display: block;
			width:6px;
			height:8px;
			background-size: 5px 8px;
			background-repeat: no-repeat;
			top:5px;
			right:4px;
			transition:all .3s ease;
		}

		&::after {
			background-image: url('../img/t-arr.png');
			opacity:1;
		}

		&::before {
			background-image: url('../img/t-arr-h.png');
			opacity: 0;
		}

		&:hover,&:focus {
			color: #ffffff;
			&::after {
				right:0px;
				opacity: 0;
			}
			&::before {
				right:0px;
				opacity:1;
			}
		}
	}
}
























