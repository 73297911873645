.header-top-nav {
	display: inline-table;
	padding-top: 2px;

	&__item {
		display: table-cell;
		vertical-align: middle;
		padding: 0 20px 0 12px;
		cursor:pointer;
		white-space: nowrap;

		&:hover {
			.header-top-nav__text--on-hover {
				color: #7fafd6;
			}
		}

		@media (max-width: 1299px) {
			padding: 0 15px 0 8px;
		}
	}

	&__icon-wrap {
		position: relative;
    	display: inline-block;
	}

	&__icon {
		display: inline-block;
		width: 16px;
	    height: 16px;
	    vertical-align: top;
	    background: center no-repeat;

	    svg {
	    	width:100%;
	    	height:100%;
	    }

	    &--call {
	    	width:16px;
	    	height: 16px;
	    }

	    &--search {
	    	width:16px;
	    	height:16px;
	    }

	    &--map {
			width:16px;
	    	height:16px;
	    }

	    &--souvenir {
	    	width:16px;
	    	height:16px;
	    }

	    &--personal {
	    	width:16px;
	    	height:16px;
	    }

	    &--calendar {
	    	width:16px;
	    	height:16px;
	    }
	}

	&__text {
		color: rgba(#293036, .7);
		font-size: 10px;
		letter-spacing: -0.42px;
		padding-left: 3px;
		white-space: nowrap;
    &--red {
      color: #f04e67;
    }
		&--on-hover {
			transition: all .45s cubic-bezier(.39,.575,.565,1);
		}
	}
}






