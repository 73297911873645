.is-simple-form {
	display: block;
	position: relative;
	
	&__row {
		display: flex;
		flex-flow:row nowrap;
		align-items: center;
		@media (max-width: 480px) {
			flex-flow:column wrap;
			align-items: flex-start;
		}
	}

	&__input {
		width:400px;

		.is-input {
			@include fontGillSans;
			background:#ffffff;
		}
		@media (max-width: 480px) {
			width:100%;
		}
	}

	&__btn {
		margin-left: 10px;
		@media (max-width: 480px)  {
			margin-left: 0;
			margin-top: 20px;
		}
	}
}