.is-aquarium-page {

	&__intro-section {
		margin-bottom: 70px;

		.bottom-info {
			margin-top: 30px;
			text-align: center;

			.b-section__text {
				margin-bottom: 10px;
			}

			.small-text {
				margin-bottom: 30px;
			}
		}

		.btn-wrap {
			display: block;
			margin-top: 40px;
			text-align: center;
		}
	}

	&__top-events {
		@include is-grid(3,30);

		@media (max-width: 768px) {
			.is-grid {
				margin-left: 0;
				margin-right: 0;
			}
			.is-grid__item {
				width:100%;
				.is-show-snippet {
					max-width: 320px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	.page__promo-banner {
		margin-bottom: 0;
	}

	.b-section__info-banner {
		margin-bottom: 70px;
	}

	.b-section__social {
		background-color: #ffffff;
	}

	&__prices {
		margin-bottom: 21px;
	}

	&__activities {
		margin-top: 70px;
	}

	&__info-banner {
		margin-top: 20px;
	}

	&__video {
		padding-top: 70px;
		.page__wrapper {
			padding-bottom: 0;
		}
	}

	.page__watch-video .is-grid {
		justify-content: center;
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-aquarium-page__top-events {
    @include is-grid(1,20);
  }
}
