.page-nav {
	display: block;
	width: 100%;
	position: relative;
	box-shadow: inset 0 -1px 0 rgba(155, 155, 155, 0.24);
	background-color: #f7f7f7;

	&__container {
		display: inline-table;
		padding-left: 125px;
	}

	&__item {
		display: table-cell;
		padding: 15px;
		vertical-align: top;
		white-space: nowrap;
	}

	&__text {
		font-size: 14px;
		line-height: 20px;
		color: #7a7979;
		font-weight: 400;
		transition:color .3s ease;
		@include fontGillSans;

		&:hover, &:focus {
			color: #221f1f;
		}
	}
}