.is-page-events {

	&__head-image {
		display: block;
		width:100%;
	}

	&__head-outer {
		position: relative;
		margin-top: 40px;
	}

	&__menu {
		position: relative;
		width:800px;
		left: 50%;
		transform:translateX(-50%);
		top:-40px;
		@media (max-width: 991px) {
			width: calc(100% - 30px);
		}
		@media (max-width: 480px) {
			width:100%;
			top:-10px;
		}
	}

	&__e-list {
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		padding-bottom: 10px;

		.is-e-item {
			flex:.16;
		}
		@media (max-width: 577px) {
			flex-flow:row wrap;
			justify-content: center;
			.is-e-item {
				flex:auto;
				width:50%;
				text-align: center;
			}
		}
	}

	&__halls {
		@include is-grid(3,50);
		.btn-wrap {
			margin-top: 40px;
			margin-bottom: 50px;
		}
		@media (max-width: 991px) {
			.is-grid__item {
				width: calc(100% / 2 - 50px);
			}
		}
		@media (max-width: 767px) {
			.is-grid {
				margin:0;
			}
			.is-grid__item {
				width: 100%;
				margin-left: 0;
				margin-right: 0;

				.is-item {
					margin:0 auto;
					width:290px;
				}
			}
		}
	}

	&__price-request {
		padding: 45px 90px 0;
		display: flex;
		align-items: flex-start;
		@media (max-width: 1199px) {
			padding: 45px 0 0;
		}
		@media (max-width: 767px) {
			flex-flow:column nowrap;
			width:300px;
			margin:0 auto;
		}
	}

	&__l-side {
		display: block;
		position: relative;
		flex: .4;
		@media (max-width: 1199px) {
			padding-left: 15px;
		}
		@media (max-width: 767px) {
			padding:0 5px;
			flex: 1;
		}
	}

	&__r-side {
		flex: .6;
		@media (max-width: 1199px) {
			padding-right: 15px;
		}
		@media (max-width: 767px) {
			margin-top: 30px;
			padding:0;
			flex:1;
		}
	}

	&__form {
		padding-right: 100px;
		@media (max-width: 767px)  {
			padding-right: 0;
			.is-input--theme {
				min-width: auto;
				width:100%;
			}
		}
	}

	&__form-title {
		display: block;
		margin:0 0 30px;
		color: #221f1f;
		font-size: 30px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -1.5px;
		padding-right: 40px;
		@media (max-width: 768px) {
			font-size: 20px;
			padding-right: 0;
		}
	}

	&__form-decor-img {
		width:100%;
	}

	&__people-talks {
		padding-top: 70px;
		padding-bottom: 30px;
		@media (max-width: 991px) {
			padding-top: 50px;
		}
	}

	&__person-section {
		margin-bottom: 70px;
	}

	&__person-info {
		display: block;
		position: relative;
		
		&--left {
			padding-right: 70px;
			@media (max-width: 991px) {
				padding-right: 10px;
			}
		}

		&--right {
			padding-left: 70px;
			@media (max-width: 991px) {
				padding-left: 10px;
			}
		}
	}

	&__person-photos {

	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-page-events {
    &__menu-offset {
      display: none;
    }
    &__menu {
      position: static;
      width: auto;
      transform: none;
    }
    &__e-list {
      padding-top: 35px;
      flex-wrap: wrap;
      .is-e-item {
        flex-grow: 0;
        flex-shrink: 0;
        width: calc((100% - 40px) / 2);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
      }
    }
    &__halls {
      @include is-grid(1, 40);
      .is-grid__item {
        +.is-grid__item {
          margin-top: 20px;
        }
      }
    }

    &__price-request {
      padding: 25px;
      > img {
        display: none;
      }
    }
    &__form {
      padding-right: 0;
    }
    &__form-title {
      font-size: 20px;
      line-height: 24px;
    }
    &__people-talks {
      padding-top: 30px;
      .row {
        margin-left: 0;
        margin-right: 0;
      }
      [class^=col-] {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__person-info--right {
      padding-left: 0;
      margin-bottom: 25px;
    }
    &__person-info--left {
      padding-right: 0;
      margin-bottom: 25px;
    }

  }
}






