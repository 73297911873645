.main-slider {

	&__wrapper {
		margin:0 auto;
		height:500px;
		z-index: 3;

		@media (max-width: 768px) {
			width:100%!important;
		}
	}

	.link {
		&:hover, &:focus {
			color:#f5a623;
		}
	}

	&__centerY {
		display: block;
		white-space: normal!important;
		
		@media (max-width: 1199px) {
			left:10px!important;
		}

		@media (max-width: 768px) {
			left:55px!important;
			top:140px!important;
		}

		@media (max-width: 577px) {
			top:115px!important;
			left:20px!important;
		}
	}

	&__title {
		position: relative!important;
		width:600px;
		white-space: normal!important;
		color:#fff;
		font-size: 60px;
		font-weight: 700;
		letter-spacing: -2px;
		line-height: 1;

		@media (max-width: 710px) {
			width:90%;
			font-size: 32px!important;
		}
	}

	&__introtext {
		position: relative!important;
		@include fontGillSans;
		width: 600px;
		white-space: normal!important;
		color:rgba(#fff, .8);
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 0;
		margin-top: 20px!important;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 28px;
		@media (max-width: 577px) {
			font-size: 20px;
			line-height: 25px;
		}

		&--color-white {
			color:#fff;
		}

		@media (max-width: 710px) {
			width:90%;
			font-size: 20px!important;
		}
	}

	&__btn-group {
		position: relative!important;
		z-index: 10;
		display: flex;
		margin-top: 40px!important;

		.btn + .btn {
			margin-left: 20px;
		}
	}

	.sp-layer__container {
		width:1440px!important;
		height:500px!important;
	}

	.sp-button {
		border: none;
		width: 10px;
		height: 10px;
		background-color: #cdbdbd;
		transition:background-color .3s ease;
    margin: 4px 6px;
		&:hover {
			background-color: #7fafd6;
		}
	}
	.sp-selected-button {
		background-color: #0060ad;

		&:hover {
			background-color: #0060ad;
		}
	}

	.sp-buttons {
		margin-top: -30px;
		padding-top: 0;
		padding-bottom: 12px;
	}
	.sp-grabbing, .sp-grab {
		cursor: default;
	}
	.sp-slide{
		display: block;
		position: absolute;
	}
	.sp-image-container{
		z-index: 1;
	}
	.sp-video-container{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .main-slider__centerY {
    left: 20px!important;
    top: 90px!important;
  }
	.sp-video-container{
		display: none;
	}
}
