.is-inhabitant-item {
	display: block;
	&__title {
		display: inline-block;
		margin: 0 0 20px 0;
	}

	&__link {
		display: inline-block;
		margin-bottom: 15px;
	}

	&__text {
		margin: 0 0 20px 0;
	}

	&__img {
		@media (min-width: 577px) {
			object-fit: cover;
			min-height: 270px;
		}
	}
}