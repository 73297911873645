.is-select {
	float:none;
	width:100%;
	height:50px;
	line-height: 50px;
	border: 2px solid #eef2f5;
	border-radius: 3px;

	&::before {
		content:'';
		position: absolute;
		top:50%;
		right:18px;
		margin-top: -7px;
	    display: inline-block;
	    width: 0;
	    height: 0;
	    border-style: solid;
	    border-width: 0 4px 5px 4px;
	    border-color: transparent transparent #acbecb transparent;
	    transform-origin: 0;
	    transition:all .15s ease-in-out;
	}

	&::after {
		content:'';
		position: absolute;
		right:18px;
	    display: inline-block;
	    width: 0;
	    height: 0;
	    border-style: solid;
        border-width: 5px 4px 0 4px;
	    border-color: #acbecb transparent transparent transparent;
	    top:50%;
	    margin-top: 2px;
	    transform-origin: 0;
	    transition:all .15s ease-in-out;
	    transform:rotate(0deg);
	}

	&--err {
		border: 2px solid #f22435;
	}

	&--disable {
		pointer-events:none;
	}

	.current {
		font-size: 16px;
    	color: #7a7979;
    	line-height: 48px;
	    white-space: nowrap;
	    overflow: hidden;
	    display: block;
	}

	&.nice-select .list {
		width:100%;
		right:0;
		border-radius: 3px;
	}
	
	&.nice-select .option.focus, &.nice-select .option.selected.focus, &.nice-select .option:hover {
		background-color:#f7f7f7;
	}

	&.nice-select.open, &.nice-select:active, &.nice-select:focus {
		border-color: #f5a623;
	}

	&.nice-select.open::after, &.nice-select:focus::after {
		border-style: solid;
        border-width: 5px 4px 0 4px;
	    border-color: #f5a623 transparent transparent transparent;
    	transform:rotate(0deg);
	}
	&.nice-select.open::before, &.nice-select:focus::before {
		border-color: transparent transparent #f5a623 transparent;
	}
	
	&.nice-select.open::after {
		margin-top: 4px;
	}
	&.nice-select.open::before {
		margin-top: -9px;
	}

	&.nice-select .option {
		padding: 12px 18px 11px;
		white-space: normal;
		line-height: 1.2;
	}
}