.dd-menu {
	position: relative;
	
	&__btn {
		position: relative;
		padding-right: 15px;

		&::before {
			content: '';
			position: absolute;
			right:0;
			top:6px;
			display: block;
			width:8px;
			height:4px;
			background-image: url('../img/i-dd.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			transition:transform .3s ease;
		}
	}
}