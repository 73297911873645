.btn {
	position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
    border: 0;
    outline: 0;
    background: 0 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    touch-action: manipulation;
    overflow: visible;
    font-family: inherit;
    text-align: center;
    cursor:pointer;
    transition: background-color .25s ease-out;
    font-size: 12px;

    @media (max-width: 390px) {
        font-size: 10px;
    }

    .btn__text {
        transition: color .3s ease-in-out;
    }

    &__rounded {
    	border-radius: em(3,12);
    }

    &--primary {
    	background-color: #f5a523;
        line-height: em(60);
        height: em(60);
		background-image: radial-gradient(circle 100px at 51.05% 50%,rgba(255,255,255,.15) 0,rgba(0,0,0,.03) 100%);
        transition: background-image .3s ease-in-out;

		.btn__text {
			text-shadow: 0 em(1,12) em(2,12) rgba(0, 0, 0, 0.3);
			color: #ffffff;
			font-size: em(12);
			font-weight: 700;
			text-transform: uppercase;
		}

        &:hover {
            background-image: radial-gradient(circle 100px at 51.05% 50%,rgba(255,255,255,0) 0,rgba(0,0,0,0) 100%);
        }
    }

    &--gray {
        line-height: em(60);
        height: em(60);
        background-color: #eef2f5;

        .btn__text {
            color: #221f1f;
            font-size: em(12);
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &__lg {
    	width: 100%;
    }

    &--fit {
        width:auto;
    }

    &__text {
		font-size: em(12);
		padding:0 em(30,12);
    }

    &--invert {
        background-color: #fff;
        background-image: none;
        line-height: em(56);
        border: 2px solid #f5a523;

        .btn__text {
            text-shadow:none;
            color: #221f1f;
        }
    }

    &--transp-primary {
        background-color: transparent;
        border: 2px solid #f5a623;
        color:#ffffff;
        background-image:none;

        .btn__text {
            color: #221f1f;
            text-shadow:none;
        }

        &:hover, &:focus {
            background-color: #f5a523;
            background-image: radial-gradient(circle 100px at 51.05% 50%,rgba(255,255,255,.15) 0,rgba(0,0,0,.03) 100%);

            .btn__text {
                color: #ffffff;
                text-shadow: 0 em(1,12) em(2,12) rgba(0, 0, 0, 0.3);
            }
        }
    }

    &--transp-white {
        background-color: transparent;
        border: 2px solid #ffffff;
        color:#ffffff;
        background-image:none;
    }

    &--disabled {
        pointer-events:none;
        background: #eef2f5;
        background-image: radial-gradient(circle 57px at 578.32% 3973.33%, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);

        .btn__text {
            color: #acbecb;
            text-shadow:none;
        }
    }
}













