.is-m-plan {
	display: block;
	width: 100%;
	@media (max-width: 1199px) {
		padding: 0 20px;
	}
	@media (max-width: 550px) {
		padding: 0 10px;
	}
	
	&__header {
		height: 130px;
		background-color: #ffffff;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		align-items: center;
		position: relative;
    	z-index: 10;
    	@media (max-width: 1240px) {
    		flex-flow: column wrap;
    	}
    	@media (max-width: 550px) {
    		height: auto;
    	}
	}	

	&__header-left {
		display: flex;
		align-items: center;
		@media (max-width: 1240px) {
			flex:1;
			order:2;
		}
		@media (max-width: 550px) {
			margin-bottom: 20px;
		}
	}

	&__header-right {
		display: flex;
		align-items: flex-start;
		margin-left: auto;
		@media (max-width: 1240px) {
			flex:1;
			order:1;
		}
		@media (max-width: 550px) {
			margin-left: 0;
			flex-flow: column nowrap;
		}
	}

	&__search {
		width: 250px;
		margin-left: 40px;
		.is-input--theme {
			@include fontGillSans;
			font-size: 16px;
			font-weight: normal;
			-webkit-font-smoothing: antialiased;
  			-moz-osx-font-smoothing: grayscale;
  			::-webkit-input-placeholder {
		      color: #7a7979;
		    }
		    ::-moz-placeholder {
		      color: #7a7979;
		    }
		    :-ms-input-placeholder {
		      color: #7a7979;
		    }
		    :-moz-placeholder {
		      color: #7a7979;
		    }
		}
		.easy-autocomplete input {
			border-width: 0;
			box-shadow: none;
		}
		.easy-autocomplete-container ul .eac-category, .easy-autocomplete-container ul li {
			border-color: #eef2f5;
			font-size: 16px;
			div {
				line-height: 1.6;
			}
		}
		@media (max-width: 550px) {
			margin: 30px 0 35px;
		}
	}

	&__filter-menu {
		position: relative;
		width: 100%;

		.is-m-icon:hover {
			.is-m-icon__text {
				color: #f5a623;
			}
		}
	}

	&__filter-m-outer {
		position: absolute;
		top: -10px;
		left: 0;
		right: 0;
		background-color: #fff;
		border: 10px solid #f7f7f7;
		border-top: none;
		padding: 0 40px;
		max-height: 0;
		visibility: hidden;
		transition: max-height .3s ease-in;
		z-index: -100;
		&.is-open {
			z-index: 100;
			visibility: visible;
			height: auto;
			.is-m-plan__filter-m-inner {
				opacity:1;
				visibility: visible;
			}
		}
		@media (max-width: 1240px) {
			top:10px;
		}
		@media (max-width: 550px) {
			padding: 0;
			border: 5px solid #f7f7f7;
		}
	}

	&__filter-m-inner {
		border-top: 1px solid #ebebeb;
		padding-top: 40px;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease-in .4s;
	}

	&__icons-grid {
		@include is-grid(5, 15);
		.is-m-icon {
			margin-bottom: 25px;
			min-height: 40px;
		}
		@media (max-width: 1240px) {
			@include is-grid(4, 15);
		}
		@media (max-width: 900px) {
			@include is-grid(3, 15);
		}
		@media (max-width: 720px) {
			@include is-grid(2, 10);
			.is-m-icon {
				width: auto;
			}
		}
		@media (max-width: 550px) {
			.is-m-icon__img {
				width: 30px;
				height: 30px;
			}
			.is-m-icon__text {
				font-size: 14px;
			    line-height: 18px;
			    margin-left: 10px;
			    
			}
		}
	}

	&__canvas {
		font-size: 100%;
		padding-top: em(114);
		width: 100%;
		position: absolute;
		&.is-active {
			display: block;
		}
		.f-num {
			position: absolute;
			z-index:4;
			left: em(0);
			width: em(302);
			height: em(145);
			opacity:0;
			visibility: hidden;
			bottom: em(310);
		}
		.layer {
			background-repeat: no-repeat;
			background-size: contain;
			background-position: left bottom;
			transition: opacity .4s ease-in-out , visibility .4s;
			will-change: opacity;
		}
		.f-layer {
			opacity:0;
			visibility: hidden;
			transform: scale(0);
			position: absolute;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			pointer-events: none;
			transition: all .4s ease-in-out;
			will-change: transform , opacity;
			path {
				// transition: fill .2s ease-in-out;
				pointer-events: fill;
				cursor: default;
			}
		}
		.icon-layer {
			opacity:0;
			visibility: hidden;
			transform: scale(0);
			position: absolute;
			background-repeat: no-repeat;
			background-size: contain;
			transition: all .4s ease-in-out;
			will-change: transform , opacity;
			&.out-of-filter {
				fill-opacity: .3;
				opacity: 0!important;
				fill:#ccc;
				transform: none!important;
				filter: none;
				-webkit-filter: none;
				pointer-events: none;
				cursor: default;
				path, circle {
					fill:#ccc;
				}
			}
		}		
	}
	&__legend-box {
		margin-top: em(120);
		@media (max-width: 550px) {
			margin-top: 5em;
		}
	}

	&__legend {
		display: none;
		position: relative;
		margin-top: 70px;
		.is-m-icon {
			cursor: default;
		}
	}

	// new plan styles

	&__f-base {
		position: absolute;
		height: 0;
		bottom: 0;
		right: 0;
		left: 0;
		svg {
			pointer-events: none;
			g {
				pointer-events: none;
			}
		}
		path {
		  pointer-events: fill;
		}
	}

	&__canvas {
		height: 0;
		padding: 0;
		bottom: em(620);
		opacity:0;
		visibility: hidden;
		transition: bottom .8s ease , opacity .4s ease-in-out .1s , visibility .4s;
		will-change: bottom , opacity;
		@for $i from 0 through 5 {
			&:nth-child(#{$i + 1}) {
				right: $i*15px;
				.f-num {
					left: $i*15px;
				}
			}
		}
		&#floor-0 {z-index: 2;}
		&#floor-1 {z-index: 3;}
		&#floor-2 {z-index: 4;}
		&#floor-3 {z-index: 5;}
		&#floor-4 {z-index: 6;}
		&.is-active {
			bottom: em(50)!important;
			opacity:1!important;
			visibility: visible !important;
			transform: scale(1) !important;

			.icon-layer, .f-layer, .f-num {
				opacity: 1;
				visibility: visible;
				transform: scale(1);
			}
		}
	}

	&__total-canvas {
		position: relative;
		height: em(820);
		max-height: em(820);
		width: 100%;
		margin-bottom: em(100);
		transition: max-height .2s linear;
		will-change: max-height;
	}

}

#planApp .b-section__title {
	@media (max-width: 1199px) {
		padding: 0 20px;
	}
	@media (max-width: 550px) {
		padding: 0 10px;
	}
}

$amplitude: 14px;

@keyframes sinusoid {
    0%,
    50% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: cubic-bezier(.2, .25, .55, 1);
        animation-timing-function: cubic-bezier(.2, .25, .55, 1)
    }
    25% {
        -webkit-transform: translateY(calc(#{$amplitude} / -2));
        transform: translateY(calc(#{$amplitude} / -2));
        -webkit-animation-timing-function: cubic-bezier(.45, 0, .8, .75);
        animation-timing-function: cubic-bezier(.45, 0, .8, .75)
    }
    75% {
        -webkit-transform: translateY(calc(#{$amplitude} / 2));
        transform: translateY(calc(#{$amplitude} / 2));
        -webkit-animation-timing-function: cubic-bezier(.45, 0, .8, .75);
        animation-timing-function: cubic-bezier(.45, 0, .8, .75)
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@media (max-width: 600px) {
	$amplitude: 6px;

	@keyframes sinusoid {
	    0%,
	    50% {
	        -webkit-transform: translateY(0);
	        transform: translateY(0);
	        -webkit-animation-timing-function: cubic-bezier(.2, .25, .55, 1);
	        animation-timing-function: cubic-bezier(.2, .25, .55, 1)
	    }
	    25% {
	        -webkit-transform: translateY(calc(#{$amplitude} / -2));
	        transform: translateY(calc(#{$amplitude} / -2));
	        -webkit-animation-timing-function: cubic-bezier(.45, 0, .8, .75);
	        animation-timing-function: cubic-bezier(.45, 0, .8, .75)
	    }
	    75% {
	        -webkit-transform: translateY(calc(#{$amplitude} / 2));
	        transform: translateY(calc(#{$amplitude} / 2));
	        -webkit-animation-timing-function: cubic-bezier(.45, 0, .8, .75);
	        animation-timing-function: cubic-bezier(.45, 0, .8, .75)
	    }
	    to {
	        -webkit-transform: translateY(0);
	        transform: translateY(0)
	    }
	}
}












