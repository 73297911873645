.is-feature {

	&__outer {
		text-align: center;
	}

	&__img-wrap {
		height:40px;
		margin-bottom: 20px;
	}

	&__img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}

	&__text {
		display: block;
		@include fontGillSans;
		color: #221f1f;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
		margin:10px 0;
	}

	&__title {
		color: #0060ad;
		font-size: 30px;
		display: block;
		font-weight: 700;
		letter-spacing: -1.5px;
		line-height: 30px;
		margin-bottom: 10px;
	}
	
}