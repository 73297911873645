.hero {
	position: relative;

	&--prices-bg {
		background-color: #005fad;
	}

	&__watch {
		position: absolute;
		z-index: 100;
		top:20px;
		right: 20px;
		@media (max-width: 1199px) {
			top:30px;
			right:auto;
			left:56px;
		}

		@media (max-width:577px) {
			left:20px;
		}
	}

	&__button-group-outer {
		min-height: 0;
	}

	&__button-group {
		position: absolute;
		bottom: 84px;
		left: 105px;
	}
	&__slider-video-cnt{
		width: 100%;
		height: 500px;
		position: relative;
		text-align: center;
		overflow: hidden;
		z-index: 2;
	}
	&__slider-video{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		height: auto;
		min-height: 500px;
		min-width: 100%;
		display: inline-block;
	}
	&__slider-video-cnt + .hero__slider-img{
		display: none;
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .hero__button-group {
    left: 20px;
  }
	.hero__slider-video,
	.hero__slider-video-cnt{
		display: none;
	}
	&__slider-video-cnt + .hero__slider-img{
		display: block;
	}
}
