.is-tabs {
	@include fontGillSans;
	-webkit-font-smoothing:auto;

	&--type2 {

		.is-tabs__nav {
			box-shadow:none;
		}
		.is-tabs__nav-item {
			height:50px;
			line-height: 50px;
			padding:0 20px;
			border-radius: 3px;

			& + .is-tabs__nav-item {
				margin-left: 20px;
			}
			&.active {
				background-color: #0060ad;

				.is-tabs__nav-item-text {
					color:#ffffff;
				}
				&:hover {
					.is-tabs__nav-item-text {
						color:#ffffff;
					}
				}
			}
			&:hover {
				.is-tabs__nav-item-text {
					color: #0060ad;
				}
			}
		}
		.is-tabs__nav-item-text {
			color: #221f1f;
			font-size: 15px;
			font-weight: 600;
			line-height: 20px;
			&::after {
				content:none;
			}
		}
	}

	&__outer {
		margin-top: 50px;
	}
	
	&__nav {
		display: flex;
		flex: 1;
		justify-content: flex-start;
		align-items: flex-start;
		box-shadow: inset 0 -1px 0 rgba(#9b9b9b, .2);
	}

	&__nav-item {
		
		& + .is-tabs__nav-item {
			margin-left: 50px;
		}

		&.active {
			.is-tabs__nav-item-text {
				color: #221f1f;

				&::after {
					transform:scaleX(1);
				}
			}
		}

		&:hover, &:focus {
			.is-tabs__nav-item-text {
				color: #221f1f;
			}
		}
	}

	&__nav-item-text {
		color: rgba(#221f1f, .5);
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
		padding: 0 0 20px 0;
		position: relative;
		display: inline-block;

		&::after {
			content:'';
			display: block;
			position: absolute;
			left:0;
			right:0;
			bottom:0;
			height:2px;
			background-color: #0060ad;
			transform:scaleX(0);
			transition:transform .2s ease;
		}
	}

	&__panel {
		padding:30px 200px 30px 0;
	}
}