.is-style {
	font-size: 12px;
	h1, h2, h3, h4, h5, h6 {
		color: #221f1f;
		&.center {
			text-align: center;
		}
	}

	@media (max-width: 991px) {
		font-size:10px;
	}

	@media (max-width: 768px) {
		font-size:8px;
	}

	@media (max-width: 577px) {
		font-size:6px;
	}

	h1 {
		font-size: em(60);
		font-weight: 700;
		line-height: 1;
		letter-spacing: em(-2,60);
		margin-top: 0;
		margin: em(70,60) 0 em(30,60) 0;
		margin-top: 0;
	}
	h2 {
		font-size: em(40);
		font-weight: 700;
		line-height: 1;
		letter-spacing: em(-1,40);
		margin: em(70,40) 0 em(20,40) 0;
	}
	h3 {
		font-size: 2.5rem;
		font-weight: 700;
		line-height: 1;
		letter-spacing: em(-1.5,30);
		margin: em(70,30) 0 em(15,30) 0;
	}
	h4 {
		font-size: 2rem;
		font-weight: 700;
		line-height: em(30,24);
		letter-spacing: em(-1.2,24);
		margin: em(70,24) 0 em(15,24) 0;
	}
	h5 {
		@include fontGillSans;
		font-weight: normal;
		color: #293036;
		font-size: 2rem;
		line-height: em(28,24);
		letter-spacing: em(-0.4,24);
		margin: em(50,24) 0 em(15,24) 0;
	}
	h6 {

	}
	p {
		@include fontGillSans;
		margin: 0;
		font-size: 18px;
		line-height: 25px;
		color: #7a7979;
		text-align: left;

		& + p {
			margin: 10px 0 0 0;
		}
		&.center {
			text-align: center;
		}

		&.longtitle {
			font-size: 24px;
			line-height: 28px;
		}
	}
	ul, ol {
			width:49%;
			padding-left: 30px;
			padding-right: 20px;
			margin:15px 0 25px;
			@include fontGillSans;
			display: inline-block;

			li {
				font-size: 16px;
				color: #7a7979;
				line-height: 25px;
				letter-spacing: -0.5px;
				display: block;
				position: relative;
				margin:0;
			}

			li + li {
				margin-top: 12px;
			}
		}

		ul.x-cross li::before {
			width: 14px;
    		height: 14px;
    		position: absolute;
		    top: 6px;
		    left: -20px;
		    content: '';
		    display: block;
		    background-image: url('../img/cross.png');
		    transform: none;
		    border:none;
		    
		}

		ul li::before {
					width: 7px;
		    		height: 12px;
		    		transform: translateY(-5px) rotate(45deg);
		    		position: absolute;
				    top: 10px;
				    left: -20px;
				    content: '';
				    display: block;
				    border-bottom: 2px solid #0060ad;
				    border-right: 2px solid #0060ad;
				}
		ol {
			counter-reset: is-counter;
		}
		li {
			counter-increment: is-counter;
		}
		ol li::before {
			content: counter(is-counter) ".";
			color:#0060ad;
			position: absolute;
			top:1px;
			left:-30px;
		}

	a {
		font-size: inherit;
		font-family: inherit;
		color: #0060ad;
		cursor: pointer;
		transition: color .25s ease-out;

		&:hover, &:focus {
			color: #7fafd6;
		}
	}

	.is-block-wrap {
		margin: 70px 0;
		display: block;
	}

	.button-wrapper {
		display: inline-block;
		vertical-align: top;

		& + .button-wrapper {
			margin-left: 20px;
		}
	}
	
}

.is-table {
  margin-bottom: 30px;
  font-size: 12px;
  table {
    width: 100%;
    // table-layout: fixed;
    td {
      cursor: default;
      border-collapse: collapse;
      padding: 20px 0 20px 20px;
      // border: 1px solid #d5dae0;
      .measure {
      	display: inline;
		padding-left: 4px;
		font-size: 11px;
		font-weight: 400;
		color: #7a7979;
		font-family: 'HelveticaNeueCyr';
		white-space: nowrap;
	  }
	  .smalltext {
	  	font-family: 'HelveticaNeueCyr';
	  	color: #7a7979;
	  	display: block;
	  	font-size: 11px;
	  	margin-top: 10px;
	  }
    }
    thead {
      td {
      	@include fontGillSans;
        vertical-align: top;
        width: 100px;
        background: #ffffff;
        font-size: em(16);
        color: #7a7979;
      }
    }
    tbody {
      td {
      	@include fontGillSans;
        vertical-align: top;
        font-size: em(18);
        transition: background .2s;
        color: #221f1f;
      }
      tr {
        &:nth-child(odd) {
        	background-color: #f2f2f2;
        }
      }
    }
  }
}

@media (max-width: 991px) {
	h1, h2, h3, h4 {
		margin-top: 50px;
	}
}

.is-float-image {
	position: relative;
	&::before, &::after {
		content: " ";
    	display: table;
	}

	&::after {
		clear: both;
	}

	.to-right {
		display: block;
	    float: right;
	}
	.to-left {
		float: left;
	}
}

.is-style.editable-content {
	margin: em(70) 0 0 0;
	h2 {
		margin: 0 0 30px 0;
	}
	h4 {
		margin: 0 0 20px 0;
		@include fontGillSans;
		font-weight: normal;
	}
	p {
		margin: 10px 0;
	}

	a {
		@include fontGillSans;
	}

	.f-light {
		color: #7a7979;
		font-weight: normal;
	}

	.to-right {
		position: relative;
		max-width: 400px;
	    margin: 0 0 70px 125px;

	    &.image {
	    	display: block;
	    	max-width: 475px;
	    }
	    .slider-link {
	    	position: absolute;
	    	top:100%;
	    	left: 0;
	    	right: 0;
	    	font-size: 16px;
	    	margin-top: 20px;
	    }
	    @media (max-width: 768px) {
	    	float: none;
	    	margin: 0 auto 70px;
	    }
	    @media (max-width: 577px) {
	    	max-width: none;
	    }
	}
	.to-left {
		position: relative;
		max-width: 400px;
	    margin: 0 125px 70px 0;
	    &.image {
	    	display: block;
	    	max-width: 475px;
	    }
	    .slider-link {
	    	position: absolute;
	    	top:100%;
	    	left: 0;
	    	right: 0;
	    	font-size: 16px;
	    	margin-top: 20px;
	    }
	    @media (max-width: 768px) {
	    	float: none;
	    	margin: 0 auto 70px;
	    }
	    @media (max-width: 577px) {
	    	max-width: none;
	    }
	}
}

.is-style.editable-content--theme-lg {
	.to-left {
		margin: 0 30px 30px 0;
		&.image {
			max-width: 540px;
		}
	}
	.to-right {
		margin: 0 0 30px 30px;
		&.image {
			max-width: 540px;
		}
	}
}





