.b-about {

	@media (max-width: 1469px) {
		font-size: 10px;
	}

	@media (max-width: 1199px) {
		font-size: 9px;
	}

	&__title {
		font-size: em(60);
		font-weight: 700;
		line-height: 1;
		display: block;
		text-align: center;
		letter-spacing: em(-2,60);
		color:#ffffff;
		margin: em(51,60) 0;

		@media (max-width: 991px) {
			font-size: 60px;
		}

		@media (max-width: 577px) {
			font-size: 32px;
			margin:50px 0;
		}
	}

	&__title2 {
		color: #ffffff;
		font-size: 30px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -1.5px;
		margin-bottom: 10px;
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
	}

	&__text {
		@include fontGillSans;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.5px;
		margin-bottom: 40px;
		color: #acbecb;
	}

  &__description {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    @include fontGillSans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.5px;
    margin-bottom: 45px;
    color: #ffffff;
    p {
      margin-top: 0;
    }

  }
  &__description-toggle {
    transition: height 0.3s;
    position: relative;
    @media (max-width: 1199px) {
      height: 125px;
      overflow: hidden;
      margin-bottom: 10px;
      text-align: center;
      &:after {
        content: '';
        width: 100%;
        height: 55px;
        background: linear-gradient(to bottom, rgba(25,112,181,0.6) 0%, rgba(25,112,181,1) 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }    
    &.toggled {
      height: auto;
      &:after {
        content: none;
      }
    }
  }
  &__description-button {
    display: none;
    color: #ffffff !important;
    font-size: 12px;
    @media (max-width: 1199px) {
      display: inline-block;
    }
  }
	&__head {
		display: block;
		position: relative;
		width:100%;
		text-align: center;

		@media (max-width: 1199px) {
			display: none!important;
		}
	}

	&__head-inner {
		display: inline-block;
	}

	&__main-content {
		position: relative;
		display: flex;
		width: 100%;
		justify-content: center;

		@media (max-width: 991px) {
			flex-flow:column nowrap;
			align-items: center;
			justify-content: flex-start;

			.snippet2 {
				font-size: 162%;

				&__img {
					width:100%;
				}
			}

			.snippet2 + .snippet2 {
				margin-top: 70px;
			}
		}

		@media (max-width: 577px) {
			.snippet2 + .snippet2 {
				margin-top: 50px;
			}
		}

	}

	&__inner {
    padding-bottom: 60px;
		@media (max-width: 577px) {
			padding: 0 5px 30px 5px;
		}
	}

	&__carousel {

		
	}
}