.b-logo {
	display: inline-block;
	
	&__moskvarium {
		display: block;
		width: em(183);
		height:em(36);
		@media (min-width: 320px) and (max-width: 340px) {
      width: em(123);
      height:em(30);
    }
		svg {
			width:100%;
			height:100%;
		}
	}

	&--mobile-s {
		@media (max-width: 1199px) {
			//font-size: 67%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
		}
	}
}