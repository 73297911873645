.b-faq {
	@include fontGillSans;
	
	&__outer {
		position:relative;
		display: block;
	}

	&__inner {
		padding: 20px 0 30px;
	}

	&__title {
		display: block;
		font-size: 24px;
		line-height: 28px;
		color: #293036;
		letter-spacing: -0.4px;
		margin: 0 0 10px 0;
	}

	&__text {
		display: block;
		margin:0;
		color: #7a7979;
		font-size: 18px;
		line-height: 25px;
		letter-spacing: -0.5px;
		
		a {
			display:inline;
			transition: color .2s ease;
			font-size: inherit;
			color: #005eb0;
			&:hover, &:focus {
				color: #f5a623;
			}
		}
	}

	&__list {
		display: flex;
		flex-flow:row wrap;
		padding: 15px 0 25px;

		ul, ol {
			width:50%;
			padding-left: 30px;
			padding-right: 20px;
			@media (max-width: 768px) {
				width:100%;
				padding-right: 0;
			}
		}

		li {
			font-size: 16px;
			color: #7a7979;
			line-height: 25px;
			letter-spacing: -0.5px;
			display: block;
			position: relative;
			margin:0;

			&::before {
				width: 7px;
	    		height: 12px;
	    		transform: translateY(-5px) rotate(45deg);
	    		position: absolute;
			    top: 10px;
			    left: -20px;
			    content: '';
			    display: block;
			    border-bottom: 2px solid #0060ad;
			    border-right: 2px solid #0060ad;
			}
		}

		li + li {
			margin-top: 12px;
		}
	}

}