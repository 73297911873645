.is-page-search {
	
	&__tools {
		margin-top: 40px;
	}

	&__pager {
		margin-top: 50px;
		margin-bottom: 70px;
		@media (max-width: 768px) {
			margin-bottom: 50px;
		}
	}
}