.is-m-plan__canvas {
	.f-num.two {
		width: em(126);
		height: em(144);
	}
	.f2-bl1 {
		position: relative;
		z-index: 1;
		height: em(399);
		width: em(989);
		margin-left: auto;
		right: em(8);
	}
	.f2-bl2 {
		position: absolute;
		z-index: 2;
		right: 0;
		bottom: em(35);
		height: em(419);
		width: em(1000);
	}
		
	.floor-2 {
		// position: relative;
		padding-top: em(24);
		// height: 0;
		.f-layer-1 {
			z-index: 2;
			left: em(498);
			bottom: em(60);
			width: em(145);
			height: em(57);
		}
		.f-layer-2 {
			z-index: 2;
			width: em(218);
			height: em(80);
			bottom: em(105);
			left: em(304);
		}
		.f-layer-3 {
			z-index: 2;
			width: em(451);
			height: em(200);
			bottom: em(122);
			left: em(371);
		}
		.f-layer-4 {
			z-index: 2;
			width: em(459);
			height: em(209);
			bottom: em(226);
			right: em(0);
		}
		.f-layer-5 {
			z-index: 2;
			width: em(94);
			height: em(33);
			bottom: em(365);
			right: em(181);
		}
		.f-layer-6 {
			z-index: 3;
			width: em(58);
			height: em(55);
			bottom: em(143);
			left: em(610);
		}
		.f-layer-7 {
			z-index: 2;
			width: em(62);
			height: em(64);
			bottom: em(143);
			left: em(642);
		}
		.f-layer-8 {
			z-index: 2;
			width: em(74);
			height: em(25);
			bottom: em(306);
			left: em(825);
		}
		.f-layer-9 {
			z-index: 3;
			width: em(100);
			height: em(26);
			bottom: em(284);
			left: em(813);
		}
		.f-layer-10 {
			z-index: 5;
			width: em(129);
			height: em(86);
			bottom: em(367);
			right: em(35);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: 0s;
		}
		.f-layer-11 {
			z-index: 5;
			width: em(41);
			height: em(26);
			bottom: em(448);
			right: em(160);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: 1.4s;
		}
		.f-layer-12 {
			z-index: 5;
			width: em(46);
			height: em(25);
			bottom: em(484);
			right: em(227);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: .6s;
		}
		.f-layer-13 {
			z-index: 3;
			width: em(129);
			height: em(54);
			bottom: em(360);
			right: em(254);
		}
		.f-layer-14 {
			z-index: 3;
			width: em(219);
			height: em(20);
			bottom: em(235);
			left: em(435);
		}
		.f-layer-15 {
			z-index: 3;
			width: em(158);
			height: em(20);
			bottom: em(177);
			left: em(328);
		}
		.i-l-2 {
			z-index:10;
			left: em(382);
			bottom: em(138);
			width: em(50);
			height: em(30);
		}
		.i-l-3 {
			z-index:10;
			right: em(212);
			bottom: em(377);
			width: em(33);
			height: em(41);
		}
		.i-l-4 {
			z-index:10;
			left: em(533);
			bottom: em(188);
			width: em(17);
			height: em(39);
		}
		.i-l-5 {
			z-index:10;
			left: em(615);
			bottom: em(159);
			width: em(39);
			height: em(33);
		}
		.i-l-6 {
			z-index:10;
			right: em(323);
			bottom: em(306);
			width: em(39);
			height: em(33);
		}
		.i-l-7 {
			z-index:10;
			left: em(662);
			bottom: em(170);
			width: em(43);
			height: em(32);
		}
		.i-l-8 {
			z-index:10;
			right: em(291);
			bottom: em(279);
			width: em(43);
			height: em(32);
		}
	}
}











