.event {

	&__item {
		margin-bottom: 35px;
		position: relative;
		display: block;
		cursor:pointer;
		@media (max-width: 991px) {
			margin-bottom: 15px;
		}
	}

	&__wrap {
		display: flex;
		&:hover {
			& > * {
				color:#6599c6;
			}
		}

		@media (max-width: 991px) {
			padding-top: 30px;
		}
	}

	&__name {
		font-family: "Gill Sans";
		color: #6599c6;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.5px;
		width: 100px;
		min-width: 100px;
		padding-right: 10px;
		transition: color .25s ease-out;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@media (max-width: 991px) {
			position: absolute;
			top:0;
			left:0;
			line-height: 30px;
			width: auto;
		}
	}

	&__description {
		color: #ffffff;
		font-family: "Gill Sans";
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		padding-right: 10px;
		letter-spacing: -0.5px;
		transition: color .25s ease-out;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@media (max-width: 991px) {
			line-height: 25px;
		}
	}

	&__time {
		color: #ffffff;
		font-family: "Open Sans";
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		margin-left: auto;
		padding-right: 25px;
	    transition: color .25s ease-out;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@media (max-width: 1469px) {
			padding-right: 15px;
		}
	}

	&__modal {
		position: absolute;
    	top: 0;
    	right: 0;
    	left: 0;
    	z-index: 10;
    	transform: translateX(100%);
    	transition: visibility .4s, opacity .4s, transform .4s, -o-transform .4s;
    	background-color: #005fad;
    	background-image: linear-gradient(to top,transparent 0,rgba(0,0,0,.2) 100%);
    	opacity: 0;
    	visibility: hidden;
    	padding: 0 10px 20px 20px;

    	&.is-m-show {
    		opacity: 1;
    		visibility: visible;
    		transform: translateX(0);
    		transition-delay: .1s;
    	}
	}

	&__m-outer {
		position: relative;
	}

	&__m-inner {
		
	}

	&__m-close {
	    display: block;
	    position: absolute;
	    cursor: pointer;
	    z-index: 12;
	    top: 0;
	    right: 0;
	    width: 40px;
	    height: 40px;
	    -o-transition: background .2s;
	    transition: background .2s;
	    img {
    	    display: block;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    width: 16px;
		    height: 16px;
		    margin: -8px 0 0 -8px;
	    }
	    &:hover {
	    	background: rgba(#acbecb, .5);
	    }
	}
}

// mods
.event--past:not(.event--all-day) {
	.event__description, .event__time, .event__name {
		color: #6599c6;
	}
}

