.social1 {

	&__item-wrap {
		margin: 0 15px;
		display: inline-block;
		vertical-align: top;

		&:hover {
			.social1__item {
				top:-5px;
				box-shadow: 0 3px 8px 2px rgba(0,0,0,.2);
			}
		}

		@media (max-width: 577px) {
			margin:0 10px;
		}
	}
	
	&__item {
		width: 51px;
		height: 51px;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		background-color: #ffffff;
		display: inline-block;
		vertical-align: top;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		top: 0;
		transition: all .2s ease;

		svg {
			@include centerIt(absolute);
		}

		@media (max-width: 577px) {
			width:40px;
			height:40px;
		}

		&--fb {
			// background-image: url('../img/fb1.png');
			// background-size: 10px 21px;
		}

		&--vk {
			// background-image: url('../img/vk1.png');
			// background-size: 22px 14px;
		}

		&--inst {
			// background-image: url('../img/inst1.png');
			// background-size: 18px 18px;
		}

		&--odn {
			// background-image: url('../img/odn1.png');
			// background-size: 13px 20px;
		}

		&--yt {
			// background-image: url('../img/yt1.png');
			// background-size: 21px 17px;
		}
	}
}