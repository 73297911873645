.page-hero {
	display: block;
	position: relative;
	min-height: 500px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;

	&__inner {
		padding: 60px 0;
	}

	&__title {
		color: #ffffff;
		&.h1 {
			margin-bottom: 19px;
			line-height: 1;
		}
	}

	&__text {
		display: block;
		margin: 20px 0;
		color:rgba(#ffffff, .8);
		max-width: 600px;
		@include fontGillSans;
		font-size: 24px;
		line-height: 28px;
	}
}