.langs {
	
	&__inner {
		display: flex;
	}

	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin: 0 3px;

		&--enable {
			.langs__flag {
				&::before {
					opacity:0;
				}
			}
		}
	}

	&__flag {
		position: relative;
		display: inline-block;
		position: relative;
		width:14px;
		height:10px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		&--ru {
			background-image: url('../img/f-ru.png');
		}

		&--en {
			background-image: url('../img/f-en.png');
		}

		&--ch {
			background-image: url('../img/f-ch.png');
		}

		&::before {
			content: '';
			position: absolute;
			top:-1px;
			left:-1px;
			right:-1px;
			bottom:-1px;
			background-color: #fff;
			opacity:.8;
		}
	}
}