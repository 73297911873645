.snippet3 {

	&__outer {
		display: inline-block;
		vertical-align: top;
		width:em(220);
		margin:0 20px;

		@media (max-width: 768px) {
			width:auto;
			margin:0 15px;
			width:180px;
		}
		@media (max-width: 577px) {
			margin: 0 20px;
			width:130px;
		}
	}

	&__title-sm {
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 400;
		line-height: 1.66;
		color:#ffffff;
		margin-top: 20px;
		margin-bottom: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transition: all .45s cubic-bezier(.39,.575,.565,1);

		@media (max-width: 577px) {
			font-size: 7px;
			margin-top: 15px;
		}
	}

	&__title-lg {
		margin-top: 5px;
		margin-bottom: 0;
		font-size: em(24);
		line-height: 1.25;
		font-weight: 700;
		color:#ffffff;
		letter-spacing: -1.2px;
		text-align: center;
		padding: 0 2px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transition: all .45s cubic-bezier(.39,.575,.565,1);

		&--thin {
			@include fontGillSans;
			font-weight: 400;
			letter-spacing: -0.4px;
			line-height: em(28,24);
			margin-top: em(20,24);
		}

		@media (max-width: 577px) {
			font-size: 14px;
		}
	}

	&__img-wrap {
		box-shadow: 0 60px 60px rgba(0, 0, 0, 0.2);
		width: em(220);
		height: em(180);
		display: block;
		overflow:hidden;
		margin:0 auto;

		@media (max-width: 768px) {
			width:180px;
			height:auto;
			box-shadow: 0 20px 60px rgba(0, 0, 0, 0.2);
		}
		@media (max-width: 577px) {
			width:130px;
		}
	}

	&__img {
		transform:scale(1);
		transition: all .45s cubic-bezier(.39,.575,.565,1);
		border-radius: 4px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .snippet3 {
    &__img-wrap {
      box-shadow: 0 30px 30px rgba(0, 0, 0, 0.2);
    }
  }
}

.snippet3 {
	&:hover, &:focus {
		.snippet3__title-lg, .snippet3__title-sm {
			color: rgba(#ffffff, .6);
		}
	}
}