.is-faq {
	
	&__title {
		color: #221f1f;
		font-size: 40px;
		font-weight: 700;
		line-height: 40px;
		letter-spacing: -1px;
		text-align: center;
		margin: 50px 0 20px;

		@media (max-width: 768px) {
			font-size: 20px;
			line-height: 1;
			margin:30px 0 10px;
		}
	}
}