.is-notification {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, rgba(0, 174, 255, 1), rgba(244, 105, 141, 1), #ff7e4c);
  z-index: 99;
  &__text {
    color: #ffffff;
    @include fontGillSans;
    font-size: 18px;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    a {
      color: #ffffff;
      text-decoration: underline;
    }
  }
  &__inner {
    position: relative;
    padding: 44px 50px;
    overflow: hidden;
    @media (max-width: 767px) {
      padding: 15px 50px;
    }
    &:before {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -15px;
      background-image: url('/assets/img/icon_info.svg');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
        left: 15px;
        margin-top: -10px;
      }
    }
  }
  &__close {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    border: 1px solid #ffffff;
    background-image: url('/assets/img/icon-cross-white.svg');
    background-position: 50% 50%;
    border-radius: 50%;
    background-color: transparent;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    appearance: none;
    padding: 0;
    @media (max-width: 767px) {
      right: 15px;
      width: 20px;
      height: 20px;
      background-size: 10px 10px;
      margin-top: -10px;
    }
  }
}