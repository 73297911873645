.is-items-block {
	
	&__inner {
		padding-top: 70px;
		padding-bottom: 70px;

		@media (max-width: 768px) {
			padding:50px 0 0;
		}
	}

	&__title {
		text-align: center;
		color: #221f1f;
		font-size: 30px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -1.5px;
		margin: 0 auto 40px;

		@media (max-width: 768px) {
			font-size: 20px;
		}
	}

	&__inner-wrap {
		@include is-grid(3,50);
	}

	&__item {
		display: flex;
		align-items: flex-start;
	}

	&__icon {
		display: block;
		max-width: 100%;
	}

	&__text {
		@include fontGillSans;
		color: #221f1f;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
		margin-left: 20px;

		@media (max-width: 768px) {
			margin-left: 0;
			margin-top: 10px;
			text-align: center;
		}
	}

	@media (max-width: 768px) {
		.is-items-block__inner-wrap .is-grid__item {
			width:100%;
		}
		.is-items-block__item {
			align-items: center;
		    justify-content: center;
		    flex-flow: column;
		}
	}
}