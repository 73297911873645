.is-page-swimming {

	&__head-section {
		padding-bottom: 70px;

		.btn-wrap {
			margin-top: 30px;
			display: block;
			text-align: left;
		}
	}

	&__promo-banner {
		margin:0 auto 70px;
	}

	.b-section__social {
		background-color: #ffffff;
	}

	&__school-section {
		.title {
			margin-top: 40px;
			margin-bottom: 30px;
		}
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-page-swimming__head-section {
    padding-bottom: 35px;
  }
}
