.is-baloon {
	padding: 40px 28px 22px;
	text-align: center;
	@include fontGillSans;
	.block {
		margin-bottom: 20px;
	}
	.title {
		color: rgba(#221f1f, .6);
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.4px;
	}
	.meta {
		color: #221f1f;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
	}
	.link {
        display: block;
		color: #0060ad;
		font-size: 16px;
		line-height: 25px;
		letter-spacing: -0.5px;
        cursor: pointer;
        white-space: nowrap;
	}
}

.popover {
    display: block;
    position: absolute;
    top: 36px!important;
    left: 0;
    z-index: 1010;
    max-width: 276px;
    padding: 1px;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}
.popover .close {
    position: absolute;
    right: 5px;
    top: 1px;
}
.popover.top .arrow {
	top: -11px;
    left: 50%;
    margin-left: -1px;
    border-top-color: #999;
    border-top-color: rgba(0,0,0,0.25);
    border-bottom-width: 0;
}
.popover .arrow {
	border-width: 1px;
}
.popover .arrow, .popover .arrow::after {
	position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.popover .arrow::after {
    border-width: 1px;
    content: "";
}
.popover.top .arrow::after {
	bottom: 1px;
    margin-left: -1px;
    border-top-color: #fff;
    border-bottom-width: 0;
}
