.is-m-nav {

	&__item {
		display: block;
		position: relative;
		& + & {
			box-shadow: inset 0 1px 0 #e5e5e5;
		}
		&:hover, &:focus {
			.is-m-nav__item-text {
				color:#0060ad;
			}
			.is-m-nav__control-indicate {
				&::before {
					border-color: #0060ad;
				}
			}
		}
		&.is-active {
			.is-m-nav__item-text {
				color:#0060ad;
			}
			.is-m-nav__control-indicate {
				&::before {
					border-color: #0060ad;
				}
			}
		}
	}

	&__item-text {
		display: block;
		padding: 16px 35px 18px 0;
		@include fontGillSans;
		color: #293036;
		white-space: nowrap;
		font-size: 20px;
		line-height: 25px;
		letter-spacing: -0.5px;
	}

	&__control-indicate {
		display: block;
		position: absolute;
		top:14px;
		right:0;
		width:25px;
		height:25px;
		border: 1px solid #acbecb;
		background-color: grba(#ffffff, .5);
		border-radius: 50%;
		transform:rotate(-45deg);
		&::before {
			content: '';
			display: block;
			position: relative;
			top:8px;
			left:9px;
			width: 6px;
			height:6px;
			border-left: 1px solid #293036;
			border-bottom: 1px solid #293036;
			transition:all .25s ease;
		}
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-m-nav__control-indicate {
    transform: rotate(-135deg);
  }
}
