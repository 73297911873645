.is-share {
	display: inline-block;
	vertical-align: top;
	position: relative;
	
	&__container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__link {
		& + .is-share__link {
			margin-left: 5px;
		}
	}
}

#the_share {
    text-align:center;
}

#the_share .b-share {
    height:20px;
}

#the_share .b-share-icon_vkontakte,
#the_share .b-share-icon_facebook,
#the_share .b-share-icon_twitter,
#the_share .b-share-icon_gplus {
    height:20px;
    display:block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 5px;
}

#the_share .b-share .b-share__handle {
    padding:0!important;
}


#the_share .b-share-icon_vkontakte {
	width: 88px;
    background-image: url(../img/vk-share.png);
    
}

#the_share .b-share-icon_facebook {
	width: 91px;
    background-image: url(../img/fb-share.png);
}

#the_share .b-share-icon_twitter {
	width: 76px;
    background-image: url(../img/tw-share.png);
}

#the_share .b-share-icon_gplus {
	width: 32px;
    background-image: url(../img/g-share.png);
}