.b-footer {

	&__top {
		background-color: #f7f7f7;
		padding-bottom: 64px;
	}

	&__main {
		position: relative;
		padding-top: 15px;
		width: 100%;
		background-color: #ffffff;
	}

	&__container {
		display: block;
		width: 100%;
		margin: 0 auto;
	}

	&__actions {
		margin-top: 40px;
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
		width:100%;
		position: relative;
		padding-bottom: 29px;

		@media (max-width: 768px) {
			padding: 0 40px 30px;
		}
		@media (max-width: 577px) {
			padding: 0 5px 30px;
		}
	}

	&__main-inner {
		width: 1200px;
		margin: 0 auto;

		@media (max-width: 1240px) {
			width:100%;
			padding: 0 20px;
		}
	}

	&__action {
		display: inline-block;
		min-height: 30px;

		&--left-orientation {
			float:left;
			padding-left: 42px;
			padding-right: 100px;

			.b-footer__action-text {
				text-align: left;
				@media (max-width: 991px) {
					text-align: center;
				}
			}

			@media (max-width: 1199px) {
				padding-right: 20px;
			}
			@media (max-width: 991px) {
				display: block;
				float:none;
			}
		}

		&--right-orientation {
			float: right;
			padding-right: 44px;
			padding-left: 100px;

			.b-footer__action-text {
				text-align: right;
				@media (max-width: 991px) {
					text-align: center;
				}
			}

			@media (max-width: 1199px) {
				padding-left: 20px;
			}
			@media (max-width: 991px) {
				display: block;
				float:none;
			}
		}

		&--subscribe {
			background-image: url('../img/mail.png');
			background-size: 32px 24px;
			background-repeat: no-repeat;
			background-position: left top 4px;
			@media (max-width: 991px) {
				background-position: center top;
			}
		}

		&--download {
			background-image: url('../img/photo.png');
			background-size: 29px 24px;
			background-repeat: no-repeat;
			background-position: right top 4px;
			@media (max-width: 991px) {
				background-position: center top;
			}
		}

		&:hover {
			.b-footer__action-text {
				color: #7fafd6;
			}
		}

		@media (max-width: 991px) {
			text-align: center;
			padding: 38px 0 0;
		}
	}

	&__action-text {
		color: #0060ad;
		font-family: "Gill Sans";
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		display: inline-block;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transition: all .45s cubic-bezier(.39,.575,.565,1);
	}

	&__middle {
		position: relative;
		display: block;
		text-align: center;

		@media (max-width: 1199px) {
			margin:0 0 25px;
		}
	}

	&__shield {
		position: absolute;
		display: block;
		width: 50px;
		height:50px;
		background-image: url('../img/shield.png');
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		top:-75px;
		left:50%;
		transform: translateX(-50%);
	}

	&__social-links {
		display: flex;
		justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
	}

	&__menu {
		padding-top: 30px;
		padding-bottom: 25px;

		@media (max-width: 768px) {
			padding: 20px 0 0;
		}
		@media (max-width: 577px) {
			padding: 20px 5px 0;
		}
	}

	&__menu-row {
		display: flex;
		align-items: flex-start;
		margin: 0 -20px;

		@media (max-width: 768px) {
			flex-flow:row wrap;
			margin: 0;
		}
	}

	&__menu-col {
		width: 250px;
		margin: 0 20px;

		@media (min-width: 1241px) {
			&:nth-child(2) {
				margin-right: 40px;
			}
			&:nth-child(3) {
				margin-right: 80px;
			}
		}

		@media (max-width: 768px) {
			width: 100%;
			margin: 0;
			&:last-child {
				.b-footer__menu-wrap {
					box-shadow: none;
				}
			}
		}
	}

	&__menu-wrap {
		@media (max-width: 1199px) {
			margin-bottom: 25px;
		}

		@media (max-width: 768px) {
			box-shadow: inset 0 -1px 0 #e5e5e5;
    		margin-bottom: 0;
		}

	}

	&__menu-head {
		color: #293036;
		@include fontGillSans;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.5px;
		margin:0 0 15px 0;
		position: relative;
		white-space: nowrap;

		@media (max-width: 768px) {
      		padding-bottom: 18px;
      		padding-top: 16px;
      		&:before {
				content:'';
				display: block;
				position: absolute;
				top:50%;
        		margin-top: -13px;
				right:0;
				width:26px;
				height:26px;
				border: 1px solid #acbecb;
				background-color: rgba(255,255,255, .5);
				border-radius: 50%;
			}
			&::after {
				content: '';
				display: block;
				position: absolute;
				top:50%;
        		margin-top: -4px;
				right:10px;
				width: 6px;
				height:6px;
				border-left: 1px solid #293036;
				border-bottom: 1px solid #293036;
				transition:all .25s ease;
				transform:rotate(-45deg);
			}
		}

		&.active {
			&::after {
				transform:rotate(-225deg);
        margin-top: -3px;
				border-color: #0060ad;
			}
		}

		@media (max-width: 577px) {
			font-size: 20px;
			padding-right: 35px;
		}
	}

	&__menu-list {
		display: block;
    padding-bottom: 15px;
	}

	&__menu-item {
		display: block;
    & + & {
      margin-top: 5px;
    }

		@media (max-width: 768px) {
      & + & {
        margin-top: 15px;
      }
		}
	}

	&__menu-item-text {
		position: relative;
		display: inline-block;
		color: #7a756a;
		@include fontGillSans;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		transition: color .3s ease;

		@media (max-width: 768px) {
			font-size: 18px;
			line-height: 25px;
		}
	}

	&__bottom {
		padding-top: 20px;
		padding-bottom: 14px;
		margin-bottom: 6px;
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
		display: flex;

		@media (max-width: 1469px) {
			flex-flow:column nowrap;
			align-items: center;
		}

		@media (max-width: 768px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		.item {
			color: #7a756a;
			font-family: "Gill Sans";
			font-size: 13px;
			font-weight: 400;
			line-height: 16px;
			display: inline-block;
			vertical-align: top;
			transition:color .3s ease;

			&--margin-right {
				margin-right: 29px;
			}

			&.link {
				&:hover, &:focus {
					color: #7fafd6;
				}
			}
		}
	}

	&__bottom-left {

		@media (max-width: 1469px) {
			.item--margin-right {
				margin-right: 0;
			}
		}
	}

	&__bottom-middle {

		@media (max-width: 1469px) {
			margin: 20px 0;

			.item--margin-right {
				margin: 0 15px;
			}
		}

		@media (max-width: 710px) {
			display: flex;
			flex-flow:row wrap;
			margin: 20px 0 0;

			.item--margin-right {
				margin: 0;
				padding:0 10px;
				&:nth-child(1) {
					order:1;
				}
				&:nth-child(2) {
					order:3;
				}
				&:nth-child(3) {
					order:2;
				}
				&:nth-child(4) {
					order:4;
				}
			}

			.item {
				margin:0;
				text-align: center;
				width:50%;
				margin-bottom: 20px;
			}
		}
	}

	&__bottom-right {
		margin-left: auto;

		.item {
			span {
				transition:color .3s ease;
			}

			&:hover, &:focus {
				span {
					color:#f5a523;
				}
			}
		}

		@media (max-width: 1469px) {
			margin-left: 0;
		}
	}
  .is-safe-travel {
    width: 75px;
    height: 65px;
    flex-shrink: 0;
    margin: 0 0 0 10px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-image: url('/assets/img/icons/safe-travels.svg');
    @media (max-width: 768px) {
      margin: 10px 0 0 10px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {

}
