.ticket {
	position: relative;
	display: block;
	cursor: pointer;

	&__outer {
		display: block;
		position: relative;
		padding-left:23px;
		background-color: #f5a523;
		background-image: radial-gradient(circle 82px at 51.05% 50%,rgba(255,255,255,.15) 0,rgba(0,0,0,.03) 100%);
		-webkit-transition: background-image 1s ease-in-out;
		transition: background-image 1s ease-in-out;

		@media (max-width: 420px) {
			padding-left: 0;
			height:60px;
			display: table;
			width:80px;
		}

		&::before, &::after {
			content:'';
			position: absolute;
			display: block;
			width: 17px;
			height: 24px;
			top:50%;
			transform: translateY(-50%);
			transition: opacity .3s ease;

			@media (max-width: 577px) {
				content:none;
			}
		}

		&::after {
			background: url('../img/bookmark.png') -10px -10px;
			opacity: 0;
		}

		&::before {
			background: url('../img/bookmark.png') -47px -10px;
			opacity: 1;
		}
	}

	&__text {
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		color: #ffffff;
		text-transform: uppercase;
		font-family: "Gill Sans";
		font-size: 13px;
		font-weight: 600;
		padding:23px 25px 22px 25px;
		display: block;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@media (max-width: 420px) {
			background-image: none;
			padding:15px;
			display: table-cell;
			vertical-align: middle;
			line-height: 15px;
			text-align: center;
		}
	}
}

.ticket:hover {
	.ticket__outer {
		background-image: radial-gradient(circle 82px at 51.05% 50%,rgba(255,255,255,0) 0,rgba(0,0,0,0) 100%);
	}
}




