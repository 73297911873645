.is-input {
  touch-action: manipulation;
  position: relative;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s ease-in-out;

  &::before {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    content: '';
    background: #ffffff;
  }

  &--theme {
    line-height: 50px;
    font-size: 16px;
    color: #221f1f;
    border-radius: 3px;
    min-width: 300px;
    background: #eef2f5;
    ::-webkit-input-placeholder {
      color: rgba(#7a7979, .7);
    }
    ::-moz-placeholder {
      color: rgba(#7a7979, .7);
    }
    :-ms-input-placeholder {
      color: rgba(#7a7979, .7);
    }
    :-moz-placeholder {
      color: rgba(#7a7979, .7);
    }
    &:hover {
      background: #dbdbdb;
    }
  }

  &--wide {
    width: 100%;
    min-width: 0;
  }

  &--s-max {
    width: 100%;
    min-width: 0;
    line-height: 60px;
  }

  &--focused {
    background: #f5a623 !important;
  }

  &--err {
    background: #f22435 !important;
    ::-webkit-input-placeholder {
      color: rgba(#f22435, 1);
    }
    ::-moz-placeholder {
      color: rgba(#f22435, 1);
    }
    :-ms-input-placeholder {
      color: rgba(#f22435, 1);
    }
    :-moz-placeholder {
      color: rgba(#f22435, 1);
    }
    .is-input__control {
      color: #f22435 !important;
    }
    .hint-label {
      transition: color .2s ease-in-out;
      color: #f22435 !important;
    }
  }

  &__icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: block;

    &--datepicker {
      width: 20px;
      height: 22px;
      background-image: url('../img/inpt-calendar.svg');
      background-size: 20px 22px;
      background-repeat: no-repeat;
      background-position: center;
      touch-action: none;
    }

    &--search {
      width: 22px;
      height: 22px;
      background-size: 22px 22px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(../img/i-search.svg);
    }
  }

  &__box {
    position: relative;
    display: block;
    user-select: none;
  }

  &__control {
    font-size: inherit;
    line-height: normal;
    position: relative;
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    border: 0;
    color: #221f1f;
    outline: none;
    background: none;
    -webkit-appearance: none;

    padding: 0 20px;
  }

  .error {
    position: absolute;
    top: 100%;
    line-height: 1;
    font-size: 12px;
    margin-top: 5px;
    color: #f22435;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-input--theme {
    min-width: inherit;
    width: 100%;
  }
}
