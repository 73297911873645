.header {
	position: fixed;
	z-index: 100;
	top:0;
	left:0;
	right:0;
	background-color: #ffffff;
	box-shadow: inset 0 -1px 0 #e5e5e5;
	transition: .4s cubic-bezier(.35,.96,1,1.04),opacity .3s ease;
	
	&__top-menu {
		background-color: #f7f7f7;
		padding-top: 5px;
		padding-bottom: 2px;
	}

	&__top-menu-inner {
		padding-left: 50px;
		padding-right: 50px;

		@media (max-width: 1469px) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__left {
		display: table-cell;
		width: 1px;
    	white-space: nowrap;
	}

	&__middle {
		display: table-cell;
    	vertical-align: top;
    	padding-left: 94px;
    	width:100%;

    	@media (max-width: 1469px) {
    		padding-left: 54px;
    	}

      @media (max-width: 1269px) {
        padding-left: 0;
      }

    	@media (max-width: 1199px) {
    		padding-left: 5px;
    	}
	}

	&__rigth {
		display: table-cell;
    	vertical-align: middle;
    	width:1px;
    	white-space: nowrap;
	}

	&__get-call {
		display: table-cell;
		padding-left: 20px;

	}

	&__main {
		display: table;
		width:100%;
	}

	&__main-outer {
		padding-right: 0;
	}

	&__main-content {
		padding-left: 50px;

		@media (max-width: 1469px) {
			padding-left: 0;
		}
	}

	&__logo {
		display: table-cell;
    	vertical-align: top;
    	padding: 10px 36px 0 0;
    	transition: .4s cubic-bezier(.35,.96,1,1.04),opacity .3s ease;
	}

	&__main-nav {
		display: table-cell;
		vertical-align: top;
		width:100%;
	}

	&__inner {
		padding-left: 35px;

		@media (max-width: 1469px) {
			padding-left: 0;
		}
	}

	&__main-nav-item {
		display: inline-block;
		vertical-align: top;
		padding:14px 15px;
		transition: .4s cubic-bezier(.35,.96,1,1.04),opacity .3s ease;

		&:hover {
			.header__main-nav-item-text::after {
				transform: scaleX(1);
			}
		}

		&.is-active {
			.header__main-nav-item-text {
				color: #0060ad;
			}
		}

		@media (max-width: 1469px) {
			padding-right: 0!important;
		}
	}

	&__main-nav-item-text {
		position: relative;
		text-transform: uppercase;
		@include fontGillSans;
		font-size: 12.5px;
		line-height: 32px;
		font-weight: 600;
		color: #293036;
		white-space: nowrap;
		transition: color .2s ease;
		-webkit-font-smoothing: auto;
		-moz-osx-font-smoothing: auto;

		&:hover, &:focus {
			color: #0060ad;
		}

		@-moz-document url-prefix() {
			font-weight: 500;
		}
	}

	&__ticket {
		display: table-cell;
		width:1px;
		white-space: nowrap;
	}
}

.header.headroom--unpinned {
	top:-30px;

	.header__logo {
		padding: 5px 36px 3px 0;
	}

	.header__main-nav-item {
		padding: 5px 15px;
	}

	.ticket__text {
		padding: 14px 25px 13px 25px;
	}
}










