.is-page-menu {
	font-size: 12px;
	@media (max-width: 1199px) {
		font-size: 10px;
	}
	
	&__inner {
		padding: 70px 0 0;
	}

	&__f-layout {
		display: flex;
		align-items: flex-start;
		justify-content: center;

		@media (max-width: 991px) {
			flex-flow:row wrap;
			justify-content: flex-start;
		}
	}

	&__item {
		display: inline-block;
		position: relative;
		width:em(195);
		height:em(241);
		vertical-align: top;
		border: em(10) solid #f7f7f7;
		background-color: #ffffff; 
		margin: 0 em(10);
		@media (max-width: 991px) {
			width:calc(100% / 3);
			margin:0;
			height:180px;
		}
		@media (max-width: 600px) {
			height:120px;
			border: em(5) solid #f7f7f7;
		}
	}

	&__icon-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: em(30) auto 0;
		width:em(50);
		height: em(50);
		@media (max-width: 600px) {
			margin:8px auto 0;
		}
	}

	&__icon {
		display: block;
	}

	&__title {
		display: block;
		text-align: center;
		@include fontGillSans;
		color: #293036;
		font-size: em(24);
		line-height: em(28,24);
		letter-spacing: -0.4px;
		margin-top: em(20,24);
		@media (max-width: 600px) {
			font-size: em(16);
			margin-top: em(5,16);
		}
	}

	&__link {
		display: inline-block;
		position: absolute;
		bottom:em(30);
		left: 50%;
		transform:translateX(-50%);
		color: #0060ad;

		&:hover, &:focus {
			color: #7fafd6;
		}
		@media (max-width: 600px) {
			bottom:em(10);
		}
	}
}