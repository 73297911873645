.is-accordion {
	@include fontGillSans;
	
	&__panel-group {

	}

	&__panel {
		padding:0 0 30px 60px;
		box-shadow: inset 0 -1px 0 rgba(#9b9b9b, .2);
	}

	&__panel-header {
		position: relative;
		display: block;
		padding:30px 180px 0 0;

		&.active {
			.is-accordion__status-indicator {
				transform:rotate(180deg);
			}
		}

		&:hover {
			.is-accordion__title {
				color: #0060ad;
			}
			.is-accordion__status-indicator {
				border: 1px solid rgba(#acbecb, .5);
				background-color: rgba(#ffffff, .5);

				use.main-arr {
					fill: #0060ad;
				}
			}
		}
	}

	&__status-indicator {
		position: absolute;
		left:-60px;
		top:25px;
		width: 40px;
		height:40px;
		border: 1px solid rgba(#acbecb, .2);
		border-radius: 50%;
		background-color: rgba(#ffffff, .2);
		transition:all .3s ease;
		transform:rotate(0deg);

		svg {
			width: 100%;
    		height: 100%
		}

		use.main-arr {
			fill: #293036;
			transition: fill .3s ease;
		}
	}

	svg path {
	  fill: inherit;
	  stroke: inherit;
	  stroke-width: inherit;
	  transform: inherit;
	  transition:inherit;
	}

	&__icon {
		position: absolute;
		display: block;
	    width: 16px;
	    height: 12px;
	    left: 50%;
	    transform: translateX(-50%);
	    top: 14px;
	}

	&__title {
		color: #293036;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.4px;
	}

	&__panel-body {
		padding:10px 140px 0 0;
		display: none;
	}
}
