.is-timeline {

	&__container {
		display: flex;
		flex-flow:row nowrap;
	}

	&__item {
		width: 200px;
		flex: 0 0 200px;
		height: 30px;
		box-shadow: inset 1px -1px 0 #ebebeb;
		position: relative;
		&.is-first-visible::before {
			content: attr(data-time-idx) ':00';
			left: 0;
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 2px;
			height: 15px;
			box-shadow: inset -1px 0 0 #ebebeb;
			left: 50%;
			transform:translateX(-50%);
			bottom: 0;
		}
		&::before {
			content: attr(data-time-idx) ':00';
			display: block;
			position: absolute;
			@include fontGillSans;
			color: #221f1f;
			font-size: 16px;
			line-height: 20px;
			white-space: nowrap;
			bottom: calc(100% + 5px);
			width: 36px;
			left: -18px;
			transition: left .1s ease;
		}
		&:first-child::before {
			left: 0;
		}
		&:last-child::before {
			left: auto;
    		right: 100%;
		}
	}
	&.bottom-timeline {
		top:0;
		.is-timeline__item {
			box-shadow: inset 1px 1px 0 #ebebeb;
			&::after {
				bottom: auto;
				top:0
			}
			&::before {
				bottom: auto;
				top: calc(100% + 5px);
			}
		}
	}
}