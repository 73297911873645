/* This stylesheet generated by Transfonter (https://transfonter.org) on March 6, 2018 7:50 PM */


@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    src: url('../fonts/Open_Sans/OpenSans-Bold.eot');
    src:
        url('../fonts/Open_Sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Open_Sans/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/Open_Sans/OpenSans-Bold.woff') format('woff'),
        url('../fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    src: url('../fonts/Open_Sans/OpenSans-SemiBold.eot');
    src:
        url('../fonts/Open_Sans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Open_Sans/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/Open_Sans/OpenSans-SemiBold.woff') format('woff'),
        url('../fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    src: url('../fonts/Open_Sans/OpenSans-Regular.eot');
    src:
        url('../fonts/Open_Sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Open_Sans/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/Open_Sans/OpenSans-Regular.woff') format('woff'),
        url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    font-display: swap;
    src: url('../fonts/Gill_Sans/GillSans.eot');
    src:
        url('../fonts/Gill_Sans/GillSans.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gill_Sans/GillSans.woff2') format('woff2'),
        url('../fonts/Gill_Sans/GillSans.woff') format('woff'),
        url('../fonts/Gill_Sans/GillSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    font-display: swap;
    src: url('../fonts/helvetica/HelveticaNeueCyr-Roman.eot');
    src:
        url('../fonts/helvetica/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica/HelveticaNeueCyr-Roman.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Roman.woff') format('woff'),
        url('../fonts/helvetica/HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    font-display: swap;
    src: url('../fonts/helvetica/HelveticaNeueCyr-Bold_1.eot');
    src:
        url('../fonts/helvetica/HelveticaNeueCyr-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica/HelveticaNeueCyr-Bold_1.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Bold_1.woff') format('woff'),
        url('../fonts/helvetica/HelveticaNeueCyr-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


