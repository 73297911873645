.event-a {

	&__head {
		position: relative;
		width: 100%;
	    height: 250px;
	    background-size: cover;
	    background-position: center;
	    background-repeat: no-repeat;
	    border-radius: 4px;
	    &::before {
    	    content: '';
		    background: linear-gradient(to bottom,transparent 0,#000 100%);
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    height: 55%;
		    z-index: 0;
		    opacity: .5;
	    }
	}

	&__name-row {
		display: flex;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    margin-bottom: 20px;
	    align-items: flex-start;
	    padding: 0 20px;
	}

	&__name {
		font-size: 16px;
	    font-weight: 700;
	    line-height: 20px;
	    color: #fff;
	    margin-right: 20px;
	}

	&__age {
		font-size: 14px;
	    font-weight: 400;
	    line-height: 20px;
	    padding: 0 5px;
	    border-radius: 3px;
	    color: #0060ad;
	    text-align: center;
	    background-color: #fff;
	    min-width: 32px;
	}

	&__info {
		color: #ffffff;
		font-size: 16px;
		@include fontGillSans;
		padding: 10px 0 0 0;
	}

	&__row {
		display: flex;
    	margin-bottom: 18px;
    	background-repeat: no-repeat;
		background-position: left top;
		&.text {
			font-size: 16px;
		}
  	&.duration {
  		padding:0 0 12px 37px;
  		background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwIiB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHlsZT0ic3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjI7ZmlsbDpub25lO2ZpbGwtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNiA1KSI+PGVsbGlwc2UgY3g9IjkuNSIgY3k9IjkuODM3ODM4IiByeD0iOS41IiByeT0iOS44Mzc4MzgiLz48cGF0aCBkPSJtOS41IDMuOTM1MTM1MTR2NS45MDI3MDI3bDIuODUgMi45NTEzNTEzNiIvPjwvZz48L3N2Zz4=');
  	}
  	&.cost, &.content {
  		padding:0 0 12px 37px;
  		background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwIiB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHlsZT0ic3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjI7ZmlsbDpub25lO2ZpbGwtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZCIgdHJhbnNmb3JtPSJtYXRyaXgoLjcwNzEwNjc4IC43MDcxMDY3OCAtLjcwNzEwNjc4IC43MDcxMDY3OCAxNy45NzQ4NzQgMy4zMzI3MzgpIj48cGF0aCBkPSJtMTMgNS40NTQ1NDU0NXYxNC41NDU0NTQ1NWwtMy4yNS0yLjI3MjcyNzMtMy4yNSAyLjI3MjcyNzMtMy4yNS0yLjI3MjcyNzMtMy4yNSAyLjI3MjcyNzN2LTE0LjU0NTQ1NDU1LTUuNDU0NTQ1NDVsMy4yNSAyLjI3MjcyNzI3IDMuMjUtMi4yNzI3MjcyNyAzLjI1IDIuMjcyNzI3MjcgMy4yNS0yLjI3MjcyNzI3eiIvPjxwYXRoIGQ9Im00IDkuOTA5MDkwOTFoMy43MTQyODU3MSIvPjxwYXRoIGQ9Im00IDZoNS41NzE0Mjg1NyIvPjxwYXRoIGQ9Im00IDEzLjkwOTA5MDloNS41NzE0Mjg1NyIvPjwvZz48L3N2Zz4=');
  	}
	}

	&__btn-wrap {
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    margin-top: 30px;
	}

}




















