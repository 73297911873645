.is-m-plan__canvas {
	.f-num.four {
		width: em(197);
		height: em(145);
	}
	.f4-bl1 {
		position: relative;
		z-index: 1;
		height: em(344);
		width: em(997);
		margin-left: auto;
		right: 0;
	}
	.f4-bl2 {
		position: absolute;
		z-index: 2;
		right: em(3);
		bottom: em(34);
		height: em(388);
		width: em(1000);
	}
	.floor-4 {
		// position: relative;
		padding-top: em(24);

		.f-layer-1 {
			z-index: 2;
			width: em(723);
			height: em(246);
			bottom: em(155);
			left: em(198);
		}
		.f-layer-2 {
			z-index: 2;
			width: em(173);
			height: em(61);
			bottom: em(73);
			left: em(441);
		}
		.f-layer-3 {
			z-index: 2;
			width: em(136);
			height: em(52);
			bottom: em(152);
			left: em(654);
		}
		.f-layer-4 {
			z-index: 2;
			width: em(110);
			height: em(48);
			bottom: em(203);
			right: em(293);
		}
		.f-layer-5 {
			z-index: 2;
			width: em(105);
			height: em(60);
			bottom: em(233);
			right: em(238);
		}
		.f-layer-6 {
			z-index: 3;
			width: em(151);
			height: em(53);
			bottom: em(302);
			right: em(46);
		}
		.f-layer-7 {
			z-index: 2;
			width: em(88);
			height: em(32);
			bottom: em(338);
			right: em(138);
		}
		.f-layer-8 {
			z-index: 3;
			width: em(83);
			height: em(60);
			bottom: em(270);
			left: em(640);
		}
		.f-layer-9 {
			z-index: 3;
			width: em(140);
			height: em(34);
			bottom: em(233);
			left: em(585);
		}
		.f-layer-10 {
			z-index: 3;
			width: em(213);
			height: em(81);
			bottom: em(337);
			left: em(483);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: 0s;
		}
		.f-layer-11 {
			z-index: 3;
			width: em(26);
			height: em(14);
			bottom: em(375);
			left: em(446);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: .6s;
		}
		.f-layer-12 {
			z-index: 3;
			width: em(131);
			height: em(69);
			bottom: em(341);
			right: em(4);
			animation-fill-mode: both;
			animation: sinusoid 3s ease infinite;
			animation-delay: .6s;
		}
		.i-l-1 {
			z-index: 10;
			width: em(83);
			height: em(25);
			bottom: em(177);
			left: em(268);
		}
		.i-l-2 {
			z-index: 10;
			width: em(92);
			height: em(25);
			bottom: em(170);
			left: em(668);
		}
		.i-l-3 {
			z-index: 10;
			width: em(84);
			height: em(25);
			bottom: em(221);
			right: em(306);
		}
		.i-l-4 {
			z-index: 10;
			width: em(76);
			height: em(25);
			bottom: em(262);
			right: em(241);
		}
		.i-l-5 {
			z-index: 10;
			width: em(69);
			height: em(25);
			bottom: em(376);
			right: em(317);
		}
		.i-l-6 {
			z-index: 10;
			width: em(50);
			height: em(30);
			bottom: em(97);
			left: em(497);
		}
		.i-l-7 {
			z-index: 10;
			width: em(41);
			height: em(42);
			bottom: em(356);
			right: em(272);
		}
		.i-l-8 {
			z-index: 10;
			width: em(34);
			height: em(41);
			bottom: em(325);
			right: em(150);
		}
		.i-l-9 {
			z-index: 10;
			width: em(32);
			height: em(34);
			bottom: em(290);
			right: em(100);
		}
	}
}












