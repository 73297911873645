.slider-pro {

	.sp-slides.no-swipe {
		transform: translate3d(0px, 0px, 0px) !important;
	}

	h2.sp-layer {
		font-size: 60px;
		line-height: 1;
	}

	h1.sp-layer {
		font-size: 60px;
		line-height: 1;
	}

	h3.sp-layer {
		font-size: 24px;
		font-weight: 400;
		line-height: 28px;
		@media (max-width: 577px) {
			font-size: 20px;
			line-height: 25px;
		}
	}

	.sp-arrow {
		width: 40px;
		height:40px;
		background-image: url("../img/arrow.png");
	    background-size: 8px 12px;
	    background-repeat: no-repeat;
	    background-position: left 17px center;
		border-radius: 50%;
	    background-color: rgba(#ffffff, .5);
	    transition:background-color .3s ease;

		&::before, &::after {
			content: none;
		}

		&.sp-next-arrow {
			transform: rotate(0deg);
		}

		&.sp-previous-arrow {
			transform: rotate(180deg);
		}

		&:hover {
			background-color: rgba(#ffffff, 1);
		}
	}

	a {
		transition: color .25s ease-out!important;
	}
}

.sp-horizontal .sp-arrows {
	margin-top: -20px;
}


@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .sp-slides-container:hover .sp-fade-arrows {
    display: none;
  }

}
