.is-page-excursion {

	&__promo-banner {
		padding:50px 20px 20px 100px;
		margin-bottom: 50px;
		@media (max-width: 991px) {
			padding:20px 20px 20px 50px;
		}
		@media (max-width: 768px) {
			padding:10px 10px 20px 10px;
		}
	}

	.page__grid {
		margin-bottom: 20px;
	}

	.page__grid3-gap50 .is-grid__item {
		margin-bottom: 0;
	}

	.b-section__social {
		background-color: #ffffff;
	}

	.b-section__fw-carousel {
		margin-top: 70px;
	}

	&__top-excursion-grid {
		@include is-grid(3,50);
		.is-grid__item {
			display: flex;
		}
		
		@media (max-width: 768px) {
			.is-grid__item {
				width:100%;
			}
		}
	}
}