.is-person {
	
	&__status {
		text-transform: uppercase;
		color: #acbecb;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		margin: 0 0 10px 0;
	}

	&__name {
		position: relative;
		display: block;
		margin: 10px 0 20px;
		color: #221f1f;
		font-size: 30px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -1.5px;
		padding-bottom: 33px;

		&::after {
			content: '';
			position: absolute;
			display: block;
			left:0;
			bottom:0;
			width:52px;
			height:15px;
			background-image: url('../img/waves.png');
			background-repeat: no-repeat;
			background-size: 50px 13px;
		}
	}

	&__description {
		@include fontGillSans;
		color: #7a7979;
		font-size: 18px;
		line-height: 25px;
		letter-spacing: -0.5px;
		text-align: left;
		margin: 20px 0 0;

		p {
			margin:0;
		}

		p + p {
			margin-top: 15px;
		}
	}

	&__photos {
		@media (max-width: 767px) {
			width:400px;
			margin:30px auto 0;
		}
		@media (max-width: 480px) {
			width:290px;
		}
	}
}