.is-e-item {
	
	&__outer {
		display: inline-block;
		position: relative;
		text-align: center;
		margin-bottom: 40px;
	}

	&__img {
		margin:0 auto;
	}

	&__text {
		display: block;
		text-align: center;
		margin-top: 20px;
		@include fontGillSans;
		color: #221f1f;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.5px;
	}
}