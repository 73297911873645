.page {

	&__top-offset {
		margin-top: 210px;

		@media (max-width:768px) {
			margin-top: 150px;
		}
	}

	.responsive-img {
		border-radius: 4px;
	}

	img {
		border-radius: 4px;
	}
	
	&__grid {
		width:100%;
		display: block;
		&::before {
			content:'';
			display: table;
		}

		&::after {
			content:'';
			display: table;
			clear: both;
		}
	}

	&__grid-row {
		position: relative;
		margin-left: -25px;
		margin-right: -25px;

		&::before {
			content:'';
			display: table;
		}

		&::after {
			content:'';
			display: table;
			clear: both;
		}
		@media (max-width: 768px) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__info-block {
		@include is-grid(2,50);
		margin-top: 70px;
		.b-section__title {
			margin-top: 0px;
			max-width:none;
		}
		.is-grid__item {
			flex: 1;
		}
		@media (max-width: 768px) {
			margin-top: 50px;
		}
		@media (max-width: 767px) {
			.is-grid {
				margin-left: 0;
				margin-right: 0;
			}
			.is-grid__item {
				width:100%;
				flex: auto;
				margin-left: 0;
				margin-right: 0;
				.responsive-img {
					margin:0 auto;
				}
			}
		}
	}

	&__button-center {
		display: block;
		text-align: center;
	}

	&__grid-col-50 {
		position: relative;
		width: 50%;
		padding: 0 25px;
		float: left;
		min-height: 1px;

		@media (max-width: 768px) {
			width:100%;
			padding:0 15px;

			.responsive-img {
				margin:50px auto 0;

			}

			.small-text {
				margin-top: 10px;
			}
		}
		@media (max-width: 480px) {
			padding:0;
		}
	}

	&__block {
		padding-top: 30px;

		&--pb-20 {
			padding-bottom: 20px;
		}
	}

	&__text-block {
		margin-top: 0;
		margin-bottom: 30px;
	}

	&__table {
		margin-top: 20px;
	}

	&__btn-wrap {
		margin-top: 30px;
		display: inline-block;
		vertical-align: top;

		&.quest-btn {
			margin-left: 18px;
			@media (max-width: 600px) {
				margin-left: 0;
			}
		}
	}

	&__small-title {
		text-transform: uppercase;
		color: #acbecb;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		margin:10px 0;
	}

	&__title {
		font-size: 30px;
		font-weight: 700px;
		line-height: 30px;
		color: #221f1f;
		letter-spacing: -1.5px;
		margin:0 0 30px 0;
		@media (max-width: 991px) {
			font-size: 20px;
			margin:0 0 20px 0;
		}
	}

	&__small-text {
		@include fontGillSans;
		color: rgba(#221f1f, .6);
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		margin:20px 0;

		&--center {
			text-align: center;
		}
	}

	&__line-at-head-section {
		padding-top: 10px;

		.col-26 {
			width: 26%;
			float:left;
			min-height: 1px;

			@media (max-width: 991px) {
				width:50%;
				margin-bottom: 10px;
			}

			@media (max-width: 480px) {
				width:100%;
			}
		}

		.col {
			width:auto;
			float:left;
			min-height: 1px;

			@media (max-width: 991px) {
				width:50%;
				margin-bottom: 10px;
			}

			@media (max-width: 480px) {
				width:100%;
			}
		}

		.head {
			position: relative;

			.line {
				position: absolute;
				display: block;
				left:0;
				right:5px;
				height:1px;
				background-color: #005fad;
				top:12px;
				z-index: 1;
			}
		}

		.title {
			color: #0060ad;
			@include fontGillSans;
			font-size: 18px;
			font-weight: 400;
			letter-spacing: -0.5px;
			display: inline-block;
			vertical-align: middle;
			line-height: 20px;
			background-color: #fff;
			position: relative;
			z-index: 2;
			padding-right: 5px;
		}

		.text {
			display: block;
			@include fontGillSans;
			line-height: 25px;
			font-size: 16px;
			color: #7a7979;
			letter-spacing: -0.44px;
			padding-right: 35px;
			margin:10px 0;
			@media (max-width: 768px) {
				font-size: 14px;
				line-height: 20px;
			}
		}
		@media (max-width: 991px) {
			&::before, &::after {
				content:none;
			}
			display: flex;
			flex-flow:row wrap;
		}
	}

	&__wrapper {
		display: block;
		position: relative;
		width:1200px;
		margin:0 auto;
		padding-bottom: 45px;

		&--has-border {
			border-radius: 1px;
			border: 10px solid #f7f7f7;
			background-color: #ffffff;
		}

		.bottom-button-wrap {
			position: absolute;
			bottom:-33px;
			padding:0 30px;
			background-color: #fff;
			left:50%;
			transform:translateX(-50%);
		}

		@media (max-width: 1200px) {
			width:100%;
		}
	}

	&__wrapper2 {
		display: block;
		position: relative;
		width:1200px;
		margin:0 auto;
	}

	&__wrapper-lg {
		display: block;
		position: relative;
		width:1200px;
		margin:0 auto;
	}

	&__extra-info {
		display: block;
		max-width: 600px;
		margin:0 auto;

		.main-title {
			color: #221f1f;
			font-size: 30px;
			font-weight: 400;
			line-height: 40px;
			letter-spacing: -0.25px;
			text-align: center;
			position: relative;
			padding-bottom: 30px;
			&::after {
				content: '';
				position: absolute;
				bottom:0;
				left:50%;
				transform:translateX(-50%);
				width:52px;
				height:13px;
				background-image: url('../img/waves.png');
				background-size: 50px 13px;
				background-repeat: no-repeat;
			}
			@media (max-width: 768px) {
				font-size: 20px;
				line-height: 30px;
			}
		}
	}

	&__fw-carousel {
		&.is-full-width-carousel .swiper-container {
			padding-top: 1px;
			padding-bottom: 35px;
		}
	}

	&__watch-video {
		@include is-grid(2,40);
		padding-top: 40px;
		padding-bottom: 35px;
		@include fontGillSans;

		.title {
			color: #ffffff;
			font-size: 40px;
			font-weight: 700;
			line-height: 40px;
			text-align: center;
			-webkit-font-smoothing:auto;
			margin-bottom: 40px;
			margin-top: 30px;
		}

		.is-grid__item {
			.image-wrap {
				position: relative;
			}

			.button {
				position: absolute;
				left:50%;
				transform:translateX(-50%);
				bottom:-30px;
			}

			.event-name {
				@include fontGillSans;
				color: #ffffff;
				font-size: 24px;
				line-height: 28px;
				letter-spacing: -0.4px;
				text-align: center;
				margin: 40px 0 10px 0;
				transition: all .45s cubic-bezier(.39,.575,.565,1);
			}
		}

		.info-text {
			text-align: center;
			color: #ffffff;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: -0.5px;
			margin:0 auto;
			max-width: 475px;

			@media (max-width: 577px) {
				max-width: none;
				padding:0 15px;
				font-size: 16px;
		    	line-height: 18px;
			}
		}

		.button-center {
			display: block;
			text-align: center;
			margin-top: 30px;
			margin-bottom: 35px;
		}

		@media (max-width: 1300px) {
			padding:40px 20px 35px;
		}
		@media (max-width: 768px) {
			.is-grid {
			    margin-left: 0;
    			margin-right: 0;
    			flex-flow:column nowrap;
    			justify-content: center;
    			align-items: center;
			}

			.is-grid__item {
				width: 100%;
			    margin-left: 15px;
			    margin-right: 15px;

			    .event-name {
			    	font-size: 18px;
			    	line-height: 20px;
			    }
			}
		}
	}

	&__watch-video_min {
		@include is-grid(3,10);
		padding-top: 40px;
    	padding-bottom: 35px;
    	@include fontGillSans;
    	@media (max-width: 768px) {
    		padding: 40px 20px 35px;
    		@include is-grid(2,20);
    	}

    	@media (max-width: 577px) {
			@include is-grid(1,20);
    	}

		.title {
			color: #ffffff;
			font-size: 40px;
			font-weight: 700;
			line-height: 40px;
			text-align: center;
			-webkit-font-smoothing:auto;
			margin-bottom: 40px;
			margin-top: 30px;
		}

		.is-grid__item {
			.image-wrap {
				position: relative;
			}

			.button {
				position: absolute;
				left:50%;
				transform:translateX(-50%);
				bottom:-30px;
			}

			.event-name {
				@include fontGillSans;
				color: #ffffff;
				font-size: 24px;
				line-height: 28px;
				letter-spacing: -0.4px;
				text-align: center;
				margin: 40px 0 10px 0;
				transition: all .45s cubic-bezier(.39,.575,.565,1);
			}
		}

		.info-text {
			text-align: center;
			color: #ffffff;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: -0.5px;
			margin:0 auto;
			max-width: 475px;

			@media (max-width: 577px) {
				max-width: none;
				padding:0 15px;
				font-size: 16px;
		    	line-height: 18px;
			}
		}

		.button-center {
			display: block;
			text-align: center;
			margin-top: 30px;
			margin-bottom: 35px;
		}
	}

	&__btn-group {
		display: flex;

		.btn + .btn {
			margin-left: 20px;
		}

		@media (max-width: 600px) {
			.btn + .btn {
				margin-top: 20px;
			}
		}
	}

	&__promo-banner {
		display: block;
		padding:80px 20px 80px 100px;
		margin-top: 25px;
		margin-bottom: 35px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center bottom;

		@media (max-width: 991px) {
			padding:40px 15px 40px 50px;
		}
		@media (max-width: 480px) {
			padding:10px 15px 10px 10px;
		}

		.small-title {
			@include fontGillSans;
			color:#ffffff;
			font-size: 13px;
			font-weight: bold;
			line-height: 20px;
			text-transform: uppercase;
			margin-bottom: 15px;
		}

		.text {
			display: block;
			max-width: 500px;
			@include fontGillSans;
			color:#ffffff;
			font-size: 18px;
			font-weight: 400;
			line-height: 25px;
			letter-spacing: -0.5px;
			margin:15px 0;
		}

		.title {
			display: block;
			max-width: 400px;
			color:#ffffff;
			font-size: 30px;
			font-weight: 700;
			line-height: 30px;
			letter-spacing: -1.5px;
			margin-top: 0;
			margin-bottom: 20px;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			@media (max-width: 480px) {
				font-size: 24px;
				line-height: 28px;
			}
		}

		.introtext {
			display: block;
			max-width: 500px;
			@include fontGillSans;
			color:#ffffff;
			font-size: 24px;
			font-weight: 400;
			line-height: 28px;
			letter-spacing: -0.4px;

			@media (max-width: 480px) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		.btn-wrap {
			margin-top: 40px;
			margin-bottom: 30px;

			@media (max-width: 600px) {
				.btn {
					margin-left: 0;
				}
			}
		}

		.smalltext {
			font-family: 'HelveticaNeueCyr';
			color:#ffffff;
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}

}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .page {
    &__extra-info {
      .main-title {
        font-size: 20px;
        line-height: 30px;
      }
    }
    &__watch-video {
      padding: 20px;
      @include is-grid(1, 20);
      .title {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 20px;
        margin-top: 15px;
      }
      .is-grid {
        margin-bottom: 50px;
        justify-content: center;
      }
      .is-grid__item {
        display: flex;
        flex-direction: column-reverse;
        .event-name {
          margin-top: 0;
          margin-bottom: 25px;
        }
        +.is-grid__item {
          margin-top: 25px;
        }
        .image-wrap {
        	margin-bottom: 50px;
        }
      }
    }
  }
}

.page__watch-video .is-grid__item:hover {
	.btn--primary {
		background-image: radial-gradient(circle 100px at 51.05% 50%,rgba(255,255,255,0) 0,rgba(0,0,0,0) 100%);
	}

	.event-name {
		color: rgba(#ffffff, .6);
	}
}










