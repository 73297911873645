.f-layout {
	display: flex;

	&--wrap {
		flex-flow:row wrap;
	}
	
	&--justify {
		justify-content: space-between;
	}
}