.is-modal {
	background-color: #fff;
	position: fixed;
	top:50%;
	min-width: 320px;
	min-height: 180px;
	border-radius: 5px;
	@include fontGillSans;
	z-index: 1000;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.35);
	opacity:0;
	visibility: hidden;
	transition:all .4s ease-in-out;

	&.is-active {
		opacity:1;
		visibility: visible;
	}

	&__wrap {
		text-align: center;
		position: relative;
	}

	&__inner {
		padding:55px 15px;

	}

	&__title {
		margin-bottom: 20px;
		font-size: 24px;
	}

	&__text {
		font-size: 18px;
	}

	&__close-btn {
	    background: 0 0;
	    border: 0;
	    -webkit-border-radius: 0;
	    border-radius: 0;
	    color: #555;
	    cursor: pointer;
	    height: 44px;
	    margin: 0;
	    padding: 6px;
	    position: absolute;
	    right: 0;
	    top: 0;
	    width: 44px;
	    z-index: 10;

	    svg {
	    	fill: transparent;
		    opacity: .8;
		    stroke: currentColor;
		    stroke-width: 1.5;
		    -webkit-transition: stroke .1s;
		    -o-transition: stroke .1s;
		    transition: stroke .1s;
	    }
	}
}