.snippet2 {

	@media (max-width: 1469px) {
		font-size: 10px;
	}

	@media (max-width: 1199px) {
		font-size: 8.8px;
	}

	&__outer {
		display: inline-block;
		vertical-align: top;
		width:em(400);
		margin:0 15px;
		@media (max-width: 577px) {
			width:auto;
			margin:0 auto;
			max-width: 280px;
		}
	}

	&__title-sm {
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 400;
		line-height: 1.66;
		color:#ffffff;
		margin-top: 20px;
		margin-bottom: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transition: all .45s cubic-bezier(.39,.575,.565,1);

		@media (max-width: 991px) {
			margin-top: 30px;
		}

		@media (max-width: 577px) {
			font-size: 7px;
			margin-top: 15px;
		}
	}

	&__title-lg {
		margin-top: 5px;
		margin-bottom: 0;
		font-size: em(40);
		line-height: 1;
		font-weight: 700;
		color:#ffffff;
		letter-spacing: -1px;
		text-align: center;
		padding: 0 em(22,40);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transition: all .45s cubic-bezier(.39,.575,.565,1);

		@media (max-width: 991px) {
			font-size: 40px;
		}

		@media (max-width: 577px) {
			font-size: 20px;
		}
	}

	&__img-wrap {
		box-shadow: 0 60px 60px rgba(0, 0, 0, 0.2);
		width: em(400);
		height: em(400);
		display: block;
		overflow:hidden;

		@media (max-width: 577px) {
			width:100%;
			height:auto;
		}
	}

	&__img {
		border-radius: 4px;

		@media (max-width: 577px) {
			width:100%;
		}
	}
}

.snippet2 {
	&:hover, &:focus {
		.snippet2__title-lg, .snippet2__title-sm {
			color: rgba(#ffffff, .6);
		}
	}
}