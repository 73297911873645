.watch {
	
	&__outer {
		position: relative;
		display: block;
		width: 80px;
		height: 80px;
	}

	&__dashes {
		width:100%;
		height:100%;
		border-radius: 50%;
		border: 1px dashed rgba(#ffffff, .5);
		background-color: rgba(29, 128, 208, 0);
		position: absolute;
		transition:all .4s ease-in-out;

		&:hover {
		     -webkit-animation: rotate 3s linear infinite;
		     border: 1px dashed rgba(#ffffff, 1);
		}

		@-webkit-keyframes rotate {
		    from{
		        transform: rotate(0deg);
		    }
		    to{
		        transform: rotate(180deg);
		    }
		}
	}

	&__inner {
		display: block;
		width:68px;
		height:68px;
		border-radius: 50%;
		@include centerIt(absolute);
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 -2px 9px rgba(255, 255, 255, 0.5);
		background-color: #1d80d0;
		background-image: radial-gradient(circle 33px at 55.42% 25.53%,rgba(241, 230, 230, 0.15) 0,rgba(95, 95, 95, 0.15) 100%),radial-gradient(24px 12px at 50.41% 20.12%,rgba(255, 255, 255, 0.2) 0,rgba(250, 250, 250, 0) 100%);
	}

	&__bg-icon {
		position: absolute;
		display: block;
		width:32px;
		height:29px;
		@include centerIt(absolute);
		background-image: url('../img/watch.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
}