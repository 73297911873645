.is-pager {
	.paginationjs-pages {
		text-align: center;
	}

	&__container {
		margin:0 auto;
		display: inline-flex;
		align-items: center;
	}

	.paginationjs-prev, .paginationjs-next {
		display: inline-block;
		height: 40px;
	}

	&__item, .paginationjs-page {
		display: block;
		position: relative;
		width:40px;
		height:40px;
		border-radius: 20px;
		background-color: #fff;
		@include fontGillSans;
		text-align: center;
		line-height: 40px;
		color: #221f1f;
		font-size: 18px;
		letter-spacing: -0.5px;
		margin:0 8px;

		& > a {
			line-height: 40px;
		}

		&:hover {
			color:#0060ad;
		}

		&.state-active {
			background-color: #f5a623;
			color:#ffffff;
			&:hover {
				color:#ffffff;
			}

			a {
				color:#ffffff;
				&:hover {
					color:#ffffff;
				}
			}
		}

		&--prev {
			background-image: url(../img/sp-arrow.png);
			transform:scale(-1);
			margin-right: auto;
			margin-left: 0;
		}

		&--next {
			background-image: url(../img/sp-arrow.png);
			margin-left: auto;
			margin-right: 0;
		}
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-pager {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin-left: 20px;
    margin-right: 20px;
    &__container {
      width: auto;
      justify-content: flex-start;
    }
    &__item {
      flex-shrink: 0;
    }
  }
}
