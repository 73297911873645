.is-p-nav {
	display: flex;
	align-items: center;
	
	&__item {
		position: relative;
		height: 50px;
		text-align: center;
		padding: 0 20px;
		border-radius: 3px;
		background-color: #fff;
		border:none;
		box-shadow: none;
		transition: background-color .2s ease-in-out;
		@media (max-width: 767px) {
			height: 40px;
		}
		@media (max-width: 550px) {
			height: 30px;
			padding: 0 10px;
		}

		& + .is-p-nav__item {
			margin-left: 5px;
		}

		&:hover, &.is-active {
			background-color: #eef2f5;
		}
		&:nth-child(1){
			&:hover, &.is-active {
				background-color: #cbcfcb;
			}
		}
		&:nth-child(2){
			&:hover, &.is-active {
				background-color: #f8f1a0;
			}
		}
		&:nth-child(3){
			&:hover, &.is-active {
				background-color: #f7d3ca;
			}
		}
		&:nth-child(4){
			&:hover, &.is-active {
				background-color: #f4cd77;
			}
		}
		&:nth-child(5){
			&:hover, &.is-active {
				background-color: #81bac2;
			}
		}

		&.is-filtered {
			.is-p-nav__item-counter {
				transform: scale(1);
			}
		}
	}

	&__item-counter {
		position: absolute;
		display: block;
		width: 22px;
		border-radius: 12px;
		height: 22px;
		border-radius: 12px;
		background-color: #0060ad;
		color:#ffffff;
		text-align: center;
		line-height: 22px;
		font-style: normal;
		top:-8px;
		right:-4px;
		transform: scale(0);
		transition: transform .2s ease-in-out;
	}

	&__item-text {
		@include fontGillSans;
		line-height: 50px;
		color: #221f1f;
		font-size: 36px;
		letter-spacing: -1px;
		white-space: nowrap;
		@media (max-width: 767px) {
			font-size: 24px;
			line-height: 40px;
		}
		@media (max-width: 550px) {
			font-size: 16px;
			line-height: 30px;
		}
	}
}