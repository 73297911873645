.is-item {
	@include fontGillSans;

	&__outer {
		position: relative;
		display: block;

		&:hover, &:focus {
			.is-item__title.link {
				color: #7fafd6;
			}
		}
	}

	&__img {
		min-height: 180px;
		margin-bottom: 15px;
		display: block;
		max-width: 100%;

		@media (max-width: 991px) {
			min-height: 0;
		}
	}

	&__title {
		color: #293036;
		font-size: 24px;
		font-weight: 400;
		line-height: 28px;
		letter-spacing: -0.4px;
		margin-bottom: 10px;
		display: block;

		&.link {
			&:hover, &:focus {
				color: #0060ad;
			}
		}
		@media (max-width: 991px) {
			font-size: 18px;
			line-height: 20px;
		}
		@media (max-width: 768px) {
      /*
			font-size: 14px;
			line-height: 16px;
      */
		}
	}

	&__place {
		text-transform: uppercase;
		color: #0060ad;
		font-size: 13px;
		font-weight: 700;
		line-height: 20px;
		margin-bottom: 5px;
	}

	&__text {
		display: block;
		color: rgba(#221f1f, .6);
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.5px;

		&--l3 {

		}
		@media (max-width: 480px) {
      /*
			font-size: 14px;
			line-height: 18px;
      */
		}
	}
}

.b-section--blue-gradient {

	.is-item {

		&__title {
			color: #ffffff;

			&.link {
				&:hover, &:focus {
					color: #f5a623;
				}
			}
		}

		&__text {
			color: #99bfde;
		}

		&__d-date {
			color: #ffffff;
		}
	}
}










