.is-schedule-page {
	display: block;
	position: relative;
	
	&__head {
		margin: 30px 0 40px;
	}

	&__page-nav {
		margin: 35px 0 45px;
	}

	.is-page-nav {
		@media (max-width: 767px) {
			overflow-x: auto;
		}
	}

	&__promo-banner {
		padding: 60px 20px 20px 100px;
	}

	&__faq {
		margin: 35px 0 95px;
	}

	&__body {
		margin: 150px 0 0;
		min-height: 600px;
		&--small{
			min-height: 0;
			margin-top: 85px;
		}
	}
	.mCSB_horizontal.mCSB_inside>.mCSB_container {
		padding-top: 40px;
		padding-bottom: 30px;
	}
	.mCSB_scrollTools.mCSB_scrollTools_horizontal {
		bottom:40px;
		z-index: -10;
		opacity: 0;
		visibility: hidden;
	}

	.page__extra-info {
		max-width: 640px;
		margin-top: 35px;
	}
	.page__promo-banner {
		margin-top: 0;
	}
}