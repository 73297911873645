.social2 {

	&__item-wrap {
		margin: 0 10px;
		display: inline-block;
		vertical-align: top;

		&:hover {
			.social2__item {
				top:-5px;
			}
		}
	}
	
	&__item {
		display: inline-block;
		width: 32px;
		height: 32px;
		border-radius: 2px;
		background-color: #0060ad;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		top: 0;
		transition: all .3s ease;

		svg {
			@include centerIt(absolute);
		}

		@media (max-width: 577px) {
			width:40px;
			height:40px;
		}
	}
}

.is-social-nets {
	display: flex;
	justify-content: center;

	.share-button {
		margin:0 3px;
		display: inline-block;
		vertical-align: middle;
	}
}