.hidden-1199-down {@media (max-width: 1199px) {display: none!important;}}

.hidden-1200-up {@media (min-width: 1200px) {display: none!important;}}

.hidden-992-up {@media (min-width: 992px) {display: none!important;}}

.hidden-991-down {@media (max-width: 991px) {display: none!important;}}

.hidden-767-down {@media (max-width: 767px) {display: none!important;}}

.hidden-768-up {@media (min-width: 768px) {display: none!important;}}

.hidden-577-down {@media (max-width: 577px) {display: none!important;}}

.hidden-578-up {@media (min-width: 578px) {display: none!important;}}

.overflowHidden {overflow: hidden;}

.underline {text-decoration: underline;}

.responsive-img {
	display: block;
	width:auto;
	max-width: 100%;
}

.margin-top-reset {margin-top: 0!important;}

.margin-bottom-reset {margin-bottom: 0!important;}

.padding-top-reset {padding-top: 0!important;}

.padding-bottom-reset {padding-bottom: 0!important;}

.p-r {
	position: relative!important;
	overflow: visible!important;
}

.mb-40 { margin-bottom: 40px!important; }
.mb-50 { margin-bottom: 50px!important; }
.mb-60 { margin-bottom: 60px!important; }
.mb-70 { 
	margin-bottom: 70px!important; 
	@media (max-width: 768px) {
		margin-bottom: 50px!important;
	}
}

.mt-50 { margin-top: 50px!important; }
.mt-70 { 
	margin-top: 70px!important; 
	@media (max-width: 768px) {
		margin-top: 50px!important;
	}
}

.h-8-rem {height: 8rem;}
.h-3-rem {height: 3rem;}
.h-4-rem {height: 4rem;}
.h-15-rem {height: 15rem;}

.sfx-hidden {opacity: 0;}

.scale-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    -webkit-transition: transform .45s cubic-bezier(.39,.575,.565,1);
    -o-transition: transform .45s cubic-bezier(.39,.575,.565,1);
    transition: transform .45s cubic-bezier(.39,.575,.565,1);
    transform: translateZ(0);
    &:hover {
    	transform: scale(1.1);
    }
}
.t-center {
	text-align: center;
}