
.is-radio {
	display: inline-block;
	vertical-align: top;

	&__box {
		position: relative;
	}

	&__control {
		position: absolute;
		left:0;
		top:0;
		opacity: 0;
		visibility: hidden;

		& + .is-radio__label {
			position: relative;
		    cursor: pointer;
		    display: inline-block;
		    padding-left: 24px;
		    margin-right: 20px;
		    font-size: 16px;
			line-height: 20px;
			@include fontGillSans;
		}

		&:checked + .is-radio__label:before,
		&:not(:checked) + .is-radio__label:before {
		    content: '';
		    position: absolute;
		    left: 0;
		    top: 2px;
		    width: 16px;
		    height: 16px;
		    border: 2px solid #0060ad;
		    border-radius: 100%;
		    background: #fff;
		}

		&:checked + .is-radio__label:after,
		&:not(:checked) + .is-radio__label:after {
			content: '';
		    width: 6px;
		    height: 6px;
		    background: #0060ad;
		    position: absolute;
		    top: 7px;
		    left: 5px;
		    border-radius: 100%;
		    -webkit-transition: all 0.2s ease;
		    transition: all 0.2s ease;
		}

		&:not(:checked) + .is-radio__label:after {
		    opacity: 0;
		    transform: scale(0);
		}
		&:checked + .is-radio__label:after {
		    opacity: 1;
		    transform: scale(1);
		}
	}
}