.delimiter {
	display: block;
	width: 100%;
	height:1px;
	box-shadow:0 -1px 0 rgba(155, 155, 155, 0.24);

	&--70 {
		margin: 70px 0;
	}

	&--mb70 {
		margin-bottom: 70px;
	}

	&--mt70 {
		margin-top: 70px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .delimiter--70 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}
