.main-fw-carousel {
  background: #ffffff;
  overflow: hidden;
  .is-fw-carousel {
    .snippet3__title-sm {
      color:#0060ad;
    }
    .snippet3__img {
      transition: transform 0.3s ease-out !important;
    }
    .snippet3__title-lg--thin {
      color:#221f1f;
      margin-top: 5px;
      transition: color 0.3s;
    } 
    .snippet3__img-wrap {
      box-shadow: none;
    }
    .snippet3__outer {
      &:hover {
        .snippet3__img {
          transform: scale(1.2);
        }
        .snippet3__title-lg--thin {
          color: #0060ad;
        }
      }
    }
  }
}
.is-fw-carousel {
	&__inner {
		padding: 70px 0;

		@media (max-width: 768px) {
			padding:50px 0;
		}

		&--small-padd {
			padding:30px 0;

			@media (max-width: 768px) {
				padding:10px 0;
			}
		}
	}

	&__title {
		display: block;
		margin: 0 auto;
		max-width: 475px;
		text-align: center;
		color:#ffffff;
		font-size: 40px;
		font-weight: 700;
		line-height: 40px;
		letter-spacing: -1px;
		@media (max-width: 768px) {
			font-size: 20px;
		}
	}

	&__text {
		display: block;
		margin:0 auto;
		padding:0;
		width:600px;
		text-align: center;
		@include fontGillSans;
		color: #acbecb;
		font-size: 18px;
		font-weight: 400;
		line-height: 25px;
		letter-spacing: -0.5px;

		@media (max-width: 768px) {
			width:auto;
			padding:0 15px;
		}
	}

	&__button-wrap {
		display: block;
		width:100%;
		margin: 30px 0 0;
		text-align: center;
	}

	.swiper-container {
		padding:40px 0;
	}

	.swiper-slide {
		width:auto;
	}

	.swiper-button-next, .swiper-button-prev {
		top:110px;
		margin:0;
		width: 40px;
		height: 40px;
		background-image: url("../img/arrow.png");
	    background-size: 8px 12px;
	    background-repeat: no-repeat;
	    background-position: left 17px center;
		border-radius: 50%;
	    background-color: rgba(#ffffff, .5);
	    transition:background-color .3s ease;

		@media (max-width: 768px) {
			top: 100px;
		}

		@media (max-width: 577px) {
			top:75px;
			display: none;
		}

		&:hover {
			background-color: rgba(#ffffff, 1);
		}
	}

	.swiper-button-next {
		right: 20px;
	}

	.swiper-button-prev {
		transform:rotate(180deg);
		left:20px;
	}
}
@media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3),
only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
  .is-fw-carousel {
    .swiper-container {
      padding: 20px 0;
    }
  }
}
