.is-gallery {

	@media (max-width: 1400px) {
		.page__wrapper {
			padding: 0 20px;
		}
	}

	&__container {
		position: relative;
		.loader {
			position: absolute;
			
		}
	}

	&__slider {
		.slick-prev {
			left:20px;
			transform: rotate(180deg);
		}

		.slick-next {
			right: 20px;
		}

		.slick-prev, .slick-next {
			width: 40px;
    		height: 40px;
    		background-image: url(../img/sp-arrow.png);
    		z-index: 10;
    		&::before {
    			content: none;
    		}
		}
	}

	&__main-screen {
		position: relative;

		.responsive-img {
			width: 100%;
			height: calc(100vh - 90px);
			max-height: 750px;
			border-radius: 0;
			object-fit:cover;
		}
	}

	&__thumbs {
		position: relative;
		margin-top: 3rem;

		.slider-thumb {
			width: 200px;
			height: 200px;
			margin: 0 5px;
			position: relative;
			overflow: hidden;
			&::before {
				content: '';
				position: absolute;
				display: block;
				top:0;
				left:0;
				right:0;
				bottom:0;
				border: 10px solid #0060ad;
				z-index: -1;
			}

			img {
				object-fit: cover;
				height: 100%;
				border-radius: 0;
			}

			@media (max-width: 1400px) {
				width: 100px;
				height: 100px;
			}
		}

		@media (max-width: 768px) {
			.is-sl-nav {
				display: none;
			}
		}
	}

	&__desc-text {
		display: block;
		max-width: 600px;
		text-align: center;
		margin: 0 auto;
		padding: 20px 0 30px;

		.text {
			@include fontGillSans;
			color: #221f1f;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.5px;
			min-height: 66px;
		}

		@media (max-width: 480px) {
			padding: 10px 20px 15px;
			.text {
				font-size: 12px;
				line-height: 1.3;
			}
		}
	}

	&__grid {
		margin-bottom: 35px;
		@include is-grid(5,50);
		@media (max-width: 1199px) {
			@include is-grid(3,30);
			.responsive-img {
				height: auto;
			}
		}
		@media (max-width: 767px) {
			@include is-grid(3,10);
		}
		@media (max-width: 600px) {
			@include is-grid(2,10);
		}

		.is-grid__item {
			overflow: hidden;
		}

		.responsive-img {
			height: 200px;
		}
	}
}
