.is-page-nav {
	display: block;
	overflow: hidden;
	font-size: 12px;
	position: relative;

	@media (max-width: 1410px) and (min-width: 1200px) {
		font-size: 11px;
	}

	@media (max-width: 1400px) {
		padding-bottom: 12px;
	}
	@media (max-width: 1199px) {
		padding: 0 20px;
		overflow-x: auto;
	}
	@media (max-width: 550px) {
		padding: 0 10px;
	}
	
	&__wrapper {
		display: flex;
		justify-content: flex-start;
	}
	
	&__item {
		display: inline-block;
		vertical-align: top;
		padding: em(9) em(10) em(8);
		border: 1px solid #ebebeb;
		border-radius: em(18);

		& + .is-page-nav__item {
			margin-left: em(7);
		}

		&:hover {
			border-color:#e5e5e5;
			.is-page-nav__item-text {
				color: #221f1f;
			}
		}

		&._active {
			border-color:#0060ad;
			.is-page-nav__item-text {
				color: #221f1f;
			}
		}
	}

	&__item-text {
		@include fontGillSans;
		font-size: em(13);
		line-height: em(15);
		color: #7a7979;
	    transition: color .25s ease-out;
		white-space:nowrap;
	}
}

// mods

.is-page-nav--fill {
	.is-page-nav__item {
		background-color: #ffffff;
		&._active {
			background-color: #0060ad;
			.is-page-nav__item-text {
				color: #e5e5e5;
			}
		}
	}
	.is-page-nav__item-text {
		color: #7a7979;
	}
}

