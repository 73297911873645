.is-textarea {
	touch-action: manipulation;
    position: relative;
    display: block;
    -webkit-tap-highlight-color: transparent;
    width:100%;
    color: #221f1f;
	border-radius: 3px;
	border:2px solid #eef2f5;
	font-size: 16px;
	line-height: 20px;
	color: rgba(#7a7979, .7);
	resize:none;
	@include fontGillSans;
	padding: 15px 20px;
	transition: all .2s ease-in-out;

	&:hover {
		border-color: #dbdbdb;
	}

	&:focus {
		border-color:#f5a623;
	}
}