.list {
	padding: 0;
	margin: 0;
	
	&--hint-descr {
		margin-top: 20px;
	}

	&__item {
		padding:0;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}