// grid

@mixin is-grid($cols:4,$gap:40,$debug:0) {
  $halfGap: $gap/2;
  .is-grid {
    position: relative;
    width: auto;
    margin-left: #{($gap / -2)}px;
    margin-right: #{($gap / -2)}px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    @if $debug == 1 {
      &:before {
        pointer-events: none;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: #{($gap / 2)}px;
        right: #{($gap / 2)}px;
        background: rgba(255,0,0,.2);
      }
    }
  }
  .is-grid__item {
    position: relative;
    display: block;
    width: calc((100% - #{$gap * $cols}px) / #{$cols});
    margin-left: #{$halfGap}px;
    margin-right: #{$halfGap}px;
    margin-bottom: #{$gap}px;
    @if $debug == 1 { box-shadow: inset 0 0 0 1px red; }
  }
  @media only screen and (min-width: 320px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-width: 320px) and (max-width: 667px) and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-width: 320px) and (max-width: 667px) and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-width: 320px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-width: 320px) and (max-width: 736px) and (min--moz-device-pixel-ratio: 3),
  only screen and (min-width: 320px) and (max-width: 812px) and (-webkit-device-pixel-ratio : 3),
  only screen and (min-width: 320px) and (max-width: 736px) and (-o-min-device-pixel-ratio: 6/2) {
    .is-grid__item {
      margin-bottom: #{$halfGap}px;
    }
  }

}

