.is-calendar {
	@include fontGillSans;
	color: #221f1f;

	&__wrap {
		width:730px;
		margin:45px auto 0;
		position: relative;
		@media (max-width: 1199px) {
			width:480px;
		}
		@media (max-width: 768px) {
			margin:85px auto 0;
		}
		@media (max-width: 600px) {
			width:300px;
		}
		@media (max-width: 577px) {
			width:220px;
		}
	}

	&__nav {
		position: absolute;
		z-index: 10;
		cursor:pointer;
		width:40px;
		height:40px;
		top:0;
		background-image: url('../img/nav-arrow.png');

		&--prev {
			left:-75px;
			transform:scale(-1);
			@media (max-width: 1199px) {
				left:-40px;
			}
			@media (max-width: 577px){
				left:-35px;
			}
		}

		&--next {
			right:-75px;
			@media (max-width: 1199px) {
				right:-40px;				
			}
			@media (max-width: 577px){
				right:-35px;
			}
		}
	}

	&__tooltip {
		margin-top: -10px;
		&.is-tooltip {
			top:0;
		}
	}

	.swiper-container {
		padding-bottom: 105px;
	}

	.swiper-slide {
		width:auto;
		text-align: center;
	}

	&__item {
		display: inline-block;
		position: relative;
		text-align: center;
		padding:0 1px 15px;
		cursor: pointer;
		&::after {
			content:'';
			display: block;
			position: absolute;
			left: 0;
			right:0;
			bottom:0;
			height:3px;
			background-color: #f5a623;
			z-index: 2;
			transition: all .3s ease;
			transform:scale(0);

		}
		&:hover {
			.is-tooltip {
				top:100%;
				opacity: 1;
				visibility: visible;
			}

			&::after {
				transform:scale(1);
			}
		}

		&.active {
			&::after {
				transform:scale(1);
			}
		}

		&.disable {
			color: #acbecb!important;

			&:hover {
				&::after {
					transform:scale(0)!important;
				}
			}
		}
	}
	
	&__text1 {
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.5px;
		margin-bottom: 2px;
	}

	&__text2 {
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 400;
		line-height: 20px;
	}

	&__info {
		@include fontGillSans;
		height:55px;
		padding:5px 15px;
		margin-top: -105px;
		text-align: center;
		background-color: rgba(#acbecb, .1);
		box-shadow: inset 0 1px 0 rgba(#9b9b9b, .2);

		.ttl {
			color: #221f1f;
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			margin-bottom: 5px;
		}
		.time {
			color: rgba(#221f1f, .6);
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
		}
	}

	&__icon {
		position: absolute;
		display: block;
		width:40px;
		height:40px;
		background-image: url('../img/calendar.svg');
		background-size: 20px 22px;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 10;
		top:0;
		right:-135px;
		border-radius: 50%;
		border: 1px solid rgba(172, 190, 203, 0.5);
		background-color: #ffffff;

		@media (max-width: 1199px) {
			right:-100px;
		}

		@media (max-width: 768px) {
			top:-65px;
			right:-40px;
		}
		@media (max-width: 577px) {
			right:-35px;
		}
	}

	&__picker {
		cursor: pointer;
		input {
			position: absolute;	
		    opacity: 0;
		    visibility: hidden;
		    right: 0;
		    bottom: 0;
		    display: block;
		    z-index: -10;
		}
	}
}
