
html, body, textarea {
	font-family: 'Open Sans';
	font-size: 12px;
	color: #{map-get($palette, accent-black)};
	font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
	font-style: normal;
	font-weight: 500;
	// -webkit-font-smoothing: antialiased;
  	// -moz-osx-font-smoothing: grayscale;

  	@media (max-width: 1199px) {
  		font-size: 12px;
  	}
}

*::selection {
  background: #0060ad;
  color: #fff;
}

*::-moz-selection {
  background: #0060ad;
  color: #fff;
}

input, select, textarea, div, button, a {

	&:focus {
		outline:none;
	}
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

a {
	text-decoration: none;
	font-size: inherit;
	font-family: inherit;
	color: #0060ad;
	cursor: pointer;
	transition: color .25s ease-out;

	&:hover, &:focus {
		text-decoration: none;
		color: #7fafd6;
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

th {
	font-weight: normal;
}












































