.is-schedule-filter	{
	color: #221f1f;
	@include fontGillSans;
	font-size: 16px;
	
	&__container {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		flex-flow: row nowrap;
		height: 40px;

		& > * {
			&:first-child {
				border-radius: 3px 0 0 3px;
			}
			&:last-child {
				border-radius: 0 3px 3px 0;
			}
		}
	}

	&__button {
		transition: color .25s ease-out;
		background: #fff;
		&:hover {
			color: #7fafd6;
		}
		&.is-active {
			color: #ffffff;
			background-color: #0060ad;
			border-color: #0060ad;
		}
	}

	&__date-button {
		padding-top: 14px;
		border-radius: 0 3px 3px 0;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: -2px;
			top: -2px;
			bottom: -2px;
			width: 2px;
			background: rgba(#ffffff, 0);
		}
	}

	&__helper-wrap {
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;
		&.is-schedule-filter__item {
			height: auto;
			border: none;
			padding: 0;
			display: flex;
		}
		&.is-active {
			color: #ffffff;
			background-color: #0060ad;
			.is-schedule-filter__item, .is-schedule-filter__date-button {
				border-color: #0060ad;
			}
			.is-schedule-filter__date-placeholder.is-schedule-filter__item {
				background-color: #0060ad;
				border-color: #0060ad;
				color: #ffffff;
				text-transform: lowercase;
			}
			.is-schedule-filter__date-button {
				&::before {
					background: rgba(#ffffff, 1);
				}
				svg g {
				  stroke: #ffffff;
				}
			}
		}
	}

	&__item {
		height: 54px;
		border: 2px solid #eef2f5;
		padding-left: 20px;
		padding-right: 20px;
		display: inline-block;
		cursor: pointer;
		& + .is-schedule-filter__item {
			margin-left: -2px;
		}
		input {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			right: 0;
			bottom: 0;
			display: block;
			z-index: -10;
		}
	}

	&__date-placeholder {
		width: 125px;
		overflow: hidden;
		white-space:nowrap;
		cursor: pointer;
		border-left: 0;
		display: flex;
	    justify-content: center;
	    flex-flow: column nowrap;
	    align-items: center;
	}
}